import React from "react";
import { Table } from "antd";

const AdminProductTable = ({props, columns, dataSource }) => {
 
    return (
        <Table
        key={props}
        className="table datanew dataTable no-footer"
        columns={columns}
        dataSource={dataSource}
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    );
  };

export default AdminProductTable
