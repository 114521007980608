import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addNewProduct, getGroupEntryByGuid, getProductTypeguid, getUnitguid } from '../../../../services/MasterApiServices';
import { ProductClass } from '../../../../core/json/ProductClass';
import SectionWiseProductDetail from '../../../../core/json/SectionWiseProductDetail';
import { SectionClass } from '../../../../core/json/SectionClass';
import { setproductData } from '../../../../redux/productData';
import toast from 'react-hot-toast';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { ComboProductDetail } from '../../../../core/json/ComboProductDetail';
import {
  Trash2
} from "feather-icons-react/build/IconComponents";
 
const AddProductProfile = ({ register,watch, control,errors}) => {
 
 
  const dispatch = useDispatch()
  const animatedComponents = makeAnimated();
  const { branch } = useSelector((state) => state.branchlist);
  const [products, setproducts] = useState([])
  const { deliveyTypes, sectionList, unitList, groupList ,productList,categoryList} = useSelector((state) => state.usersetting);
  // Get user from redux
  const { userId } =useSelector((state)=>state.userauth)
 
 
  const comboType = deliveyTypes?.find(type => type.name === 'Combo'); // Find Combo type
  const COMBO_TYPE_ID = comboType?.guid; // Get Combo type GUID
  
  useEffect(() => {
    const newlist = productList?.map(e => ({
        label: e.name,
        value: e.guid
    }))
    setproducts(newlist)
}, [])
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });
 
  const [selectedItem, setSelectedItem] = useState('');
 
 
  const handleAddMore = () => {
    append({ name: '', maxItems: '', comments: '' });
  };
 
  return (
    <div>
      <div className="modal-body custom-modal-body">
        <div className="branch-tabs col-12 align-items-center justify-content-between">
 
        </div>
       
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="input-blocks">
                <label>Name<span className="text-danger">*</span></label>
                <input type="text" placeholder="Name"  {...register("name",{ required: "Name is required"  })} />
                {errors?.name && (
                <span className="error text-danger text-sm">{errors.name.message}</span>
              )}
              </div>
            </div>
 
            <div className="col-md-6 col-sm-12">
              <div className="input-blocks">
                <label>Short Name<span className="text-danger">*</span></label>
                <input type="text" placeholder="Short Name"  {...register("shortName",{ required: "Short Name is required" })} />
                {errors?.shortName && (
                <span className="error text-danger text-sm">{errors.shortName.message}</span>
              )}
              </div>
            </div>
 
 
            <div className="col-md-6 col-sm-12">
              <div className="input-blocks">
                <label>Code<span className="text-danger">*</span></label>
                <input type="text" placeholder="Code"  {...register("code",{ required: "Code is required" })}  />
                {errors?.code && (
                <span className="error text-danger text-sm">{errors.code.message}</span>
              )}
              </div>
            </div>
 
            <div className="col-md-6 col-sm-12">
              <div className="input-blocks">
                <label>Barcode<span className="text-danger">*</span></label>
                <input
                  type="text"
                  placeholder="Barcode"  {...register("barCode",{ required: "Code is required" })} 
                />
                {errors?.barCode && (
                <span className="error text-danger text-sm">{errors.barCode.message}</span>
              )}
              </div>
            </div>
 
 
            <div className="col-md-6 col-sm-12">
              <div className="input-blocks">
                <label>Category<span className="text-danger">*</span></label>
                <select
                  {...register("category",{ required: "Category is required" })}
 
                  className=" form-control"
                  
                >
                  <option value="" disabled>
                    Select Category
                  </option>
                  {categoryList?.map((category) => (
                    <option key={category.guid} value={category.guid}>
                      {category.name}
                    </option>
                  ))}
                </select>
                {errors?.category && (
                <span className="error text-danger text-sm">{errors.category.message}</span>
              )}
              </div>
            </div>
 
            <div className="col-md-6 col-sm-12">
              <div className="input-blocks">
                <label>Item Type<span className="text-danger">*</span></label>
                <select
                  {...register("type",{ required: "Item Type is required" })}
 
                  className=" form-control"
                 
                >
                  <option value="" disabled>
                    Select Type
                  </option>
                  {deliveyTypes?.map((type) => (
                    <option key={type.guid} value={type.guid}>
                      {type.name}
                    </option>
                  ))}
                </select>
                {errors?.type && (
                <span className="error text-danger text-sm">{errors.type.message}</span>
              )}
              </div>
              </div>
              {watch().type === COMBO_TYPE_ID &&
                <>
                 {fields?.map((item, index) => (
                   <div className="row border border-warning p-3" key={index}>
                 
                   <div className="col-12">
                     <div className="input-blocks">
                     <label  className="form-label">Select Combo Products</label>
                 <Controller
                                    {...register(`items[${index}].name`,
                                      { required: watch().type === COMBO_TYPE_ID }
                                    )}
                                    name={`items[${index}].name`}
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                      closeMenuOnSelect={false}
                                      {...field}
                                      isMulti
                                      components={animatedComponents}
                                      placeholder="Select Combo Products"
                                     
                                      options={products}
                                    />
                                    )}
                                  />
                                   </div>
                                   </div>
                   <div className="col-md-6 col-sm-12">
                     <div className="input-blocks">
                     <label className="form-label">Enter maximum number of items</label>
                     <input
                  type="number"
                  min={0}
                  id={`items[${index}].maxItems`}
                  {...register(`items[${index}].maxItems`, { required: watch().type === COMBO_TYPE_ID })}
                  className="form-control"
                  placeholder='Enter maximum number of items'
                />
                                   </div>
                                   </div>
                                   {index>0 &&
                   <div className="col-md-3 col-sm-6">
                   <button
                type="button"
                onClick={() => remove(index)}
                className="btn btn-danger"
                style={{height:"38px"}}
              >
               <Trash2/>
              </button>
             
                                   </div>
                                   }
                                   
                                   </div>
                                ))}
                    <div className="text-end mt-3">
        <button type="button" onClick={handleAddMore} className="btn btn-primary">
          Add More Side Choice
        </button>
      </div>
                               
   
                </>
              } {/* Conditionally render AddCombo */}
 
           
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="input-blocks">
                <label>Group<span className="text-danger">*</span></label>
                <select
                  {...register("group",{ required: "Group is required" })}
 
                  className=" form-control"
                  
                >
                  <option value="" disabled>
                    Select Group
                  </option>
                  {groupList?.map((group) => (
                    <option key={group.guid} value={group.guid}>
                      {group.name}
                    </option>
                  ))}
                </select>
                {errors?.group && (
                <span className="error text-danger text-sm">{errors.group.message}</span>
              )}
              </div>
            </div>
           <div className="col-md-6 col-sm-12">
              <div className="input-blocks">
                <label>Base Unit<span className="text-danger">*</span></label>
                <select
                  {...register("unit",{ required: "Base Unit is required" })}
 
                  className=" form-control"
               >
                  <option value="" disabled>
                    Select Unit
                  </option>
                  {unitList && unitList?.map((unit) => (
                    <option key={unit.guid} value={unit.guid}>
                      {unit.name}
                    </option>
                  ))} 
                </select>
                {errors?.unit && (
                <span className="error text-danger text-sm">{errors.unit.message}</span>
              )}
              </div>
            </div>
           <div className="col-md-6 col-sm-12">
              <div className="input-blocks">
                <label>Cost<span className="text-danger">*</span></label>
                <input
                  type="text"
                  placeholder="Cost" {...register("cost",{ required: "Cost is required" })}
                />
                {errors?.cost && (
                <span className="error text-danger text-sm">{errors.cost.message}</span>
              )}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="input-blocks">
                <label>Base Price<span className="text-danger">*</span></label>
                <input
                  type="text"
                  placeholder="Base Price"  {...register("basePrice",{ required: "Base Price is required" })}
                />
                {errors?.basePrice && (
                <span className="error text-danger text-sm">{errors?.basePrice?.message}</span>
              )}
              </div>
            </div>
              <div className="col-md-6 col-sm-12">
               <div className="input-blocks">
                 <label>Other Description</label>
                <input
                  type="text"
                  placeholder="Other Description"  {...register("otherDescription")}
                />
              </div>
            </div>
              <div className="col-md-6 col-sm-12">
               <div className="input-blocks mt-4 d-flex">
                
                <input
                  type="checkbox"
                  
                  defaultChecked
                  placeholder="Other Description"  {...register("isActive")}
                />
                <label>Is Active</label>
                 
              </div>
            </div>
          </div>
       </div>
    </div>
  )
}
 export default AddProductProfile 
 