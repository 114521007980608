export class DomainBase {
    constructor() {
        this.id = 0;
        this.guid = '';
        this.version = 0;
        this.isDeleted = false;
        this.createdUser = '';
        this.createdDate = new Date();
        this.updatedUser = '';
        this.updatedDate = new Date();
    }}