import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSupplierList } from "../services/MasterApiServices";

export const fetchSuppliers = createAsyncThunk(
  "suppliers/fetchSuppliers",
  async () => {
    try {
      console.log("Fetching suppliers..."); // Debugging log before fetch
      const data = await getSupplierList();
      console.log("Fetched Suppliers Data:", data); // Debugging log after fetch
      return data;
    } catch (error) {
      console.error("Error fetching suppliers:", error); // Debugging log for errors
      throw error; // This will be caught by the rejected case
    }
  }
);

const supplierSlice = createSlice({
  name: "supplier",
  initialState: {
    supplierList: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuppliers.pending, (state) => {
        console.log("Fetching suppliers started..."); // Log when fetching starts
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSuppliers.fulfilled, (state, action) => {
        console.log("Fetching suppliers succeeded."); // Log on successful fetch
        state.supplierList = action.payload;
        state.loading = false;
      })
      .addCase(fetchSuppliers.rejected, (state, action) => {
        console.log("Fetching suppliers failed:", action.error.message); // Log on fetch failure
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default supplierSlice.reducer;
