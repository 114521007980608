import { DomainBase } from "./DomainBase";

export class InvProductionDetail extends DomainBase {
    constructor() {
        super();
        this.Product = null;
        this.ProductQty = 0;
        this.Cost = 0;
        this.Unit = null;
    }
}