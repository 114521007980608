import { DomainBase } from "./DomainBase";

export class CustomerReceiptType extends DomainBase {
    constructor() {
        super();
        this.name = "";
        this.Name = "";
    }
    
}
