import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSettingsByBranch } from "../services/AdminApiServices";


export const fetchBranchSettings = createAsyncThunk(
  "filters/fetchBranchSettings",
  async (id) => {
 
  
    const data = await getSettingsByBranch(id);
        return data;
      }
    );
const settings = createSlice({
  name: "settings",
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBranchSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBranchSettings.fulfilled, (state, action) => {
        state.branchsettings=action.payload;
        state.loading = false;
      })
      .addCase(fetchBranchSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export default settings.reducer;