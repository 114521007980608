import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import SettingsGeneral from "./SettingsGeneral";
import SettingSales from "./SettingSales";
import SettingPrint from "./SettingPrint";
import SetttingDayClose from "./SetttingDayClose";
import SettingOther from "./SettingOther";

const BranchSetting = () => {


  return (
    <div>
      <div className="modal fade" id="branch-setting">
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Branch Settings</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="branch-tabs col-12 align-items-center justify-content-between">
              <Tabs>
                <TabList>
                  <Tab>General</Tab>
                  <Tab>Sales</Tab>
                  <Tab>Print</Tab>
                  <Tab>Day Close</Tab>
                  <Tab>Other</Tab>
                  
                </TabList>
             
                <TabPanel>
                   <SettingsGeneral/>
                  
                </TabPanel>
                
                <TabPanel>
                  <SettingSales/>
                </TabPanel>
            
                <TabPanel >
                   <SettingPrint/>
                </TabPanel>
                <TabPanel >
                   <SetttingDayClose/>
                </TabPanel>
                <TabPanel >
                   <SettingOther/>
                </TabPanel>
               
            
              </Tabs>
             
            </div>
              
              
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default BranchSetting;

