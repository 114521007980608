import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMenuService } from "../services/CoreApiServices";


export const fetchMenuServices = createAsyncThunk(
  "filters/fetchMenuServices",
  async (id) => {
    console.log(id);
    
    const data = await getMenuService({id});
        return data;
      }
    );
const listMenuService = createSlice({
  name: "MenuService",
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenuServices.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMenuServices.fulfilled, (state, action) => {
        state.MenuServicelist=action.payload;
        state.loading = false;
      })
      .addCase(fetchMenuServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export default listMenuService.reducer;