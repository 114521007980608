import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CounterClass } from '../../../core/json/Counter';
import { CounterSetting }from '../../../core/json/CounterSetting '
import { addNewCounterSettings, getCounterSettings } from '../../../services/MasterApiServices';
import toast from 'react-hot-toast';
function CounterSettings({ data }) {
  const { BranchByUser } = useSelector((state) => state.userBranch);
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const { register, handleSubmit, setValue } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  console.log(BranchByUser);

  useEffect(() => {
    // resetmodal();
    if (data) {

      handlecounterdetail()
    }
  }, [data, setValue]);

  const handlecounterdetail = async () => {
    const objCounter = new CounterClass();

    objCounter.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}"; // userId
    objCounter.branch = branch;
    objCounter.guid = data?.guid;
    try {

      const response = await getCounterSettings(objCounter)
      setValue('PreviewInvoice',response.previewInvoice)
      setValue('PreviewReport',response.previewReport)
      setValue('PrintSalesInvoiceInDotMatrixPrinter',response.printSalesInvoiceInDotMatrixPrinter)
      setValue('PrintReportsInDotMatrixPrinter',response.printReportsInDotMatrixPrinter)
      setValue('ReceiptPrinter',response.receiptPrinter)
      setValue('BarcodePrinter',response.barcodePrinter)
      setValue('NonImpactPrinter',response.nonImpactPrinter)
      setValue('DotMatrixPrinter',response.dotMatrixPrinter)
      setValue('branch',response.branch.guid)
    }
    catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error handling category:", error?.response?.data?.Message);
    }
  }

  // console.log(sectionList);


  const handleFormSubmit = async (formData) => {
    const objcountersettings = new CounterSetting();
    objcountersettings.Counter = data;
    objcountersettings.ReceiptPrinter = formData.ReceiptPrinter
    objcountersettings.BarcodePrinter = formData.BarcodePrinter
    objcountersettings.NonImpactPrinter = formData.NonImpactPrinter
    objcountersettings.DotMatrixPrinter = formData.DotMatrixPrinter
    objcountersettings.PreviewReport = formData.PreviewReport
    objcountersettings.PreviewInvoice = formData.PreviewInvoice
    objcountersettings.PrintSalesInvoiceInDotMatrixPrinter = formData.PrintSalesInvoiceInDotMatrixPrinter
    objcountersettings.PrintReportsInDotMatrixPrinter = formData.PrintReportsInDotMatrixPrinter
    objcountersettings.branch = branch;
    objcountersettings.updatedUser = `{${userId?.id}}`;

    try {
      const response = await addNewCounterSettings(objcountersettings);
      console.log(response);
      
      if (response.data == true) {
        // fields.forEach((_, index) => remove(index))
        toast.success("success")
        closemodal()
      }
    

    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error handling category:", error?.response?.data?.Message);
    }
  };

  const closeButtonRef = useRef();
  const closemodal = () => {
    if (closeButtonRef.current) {
        closeButtonRef.current.click();
    }
};
  return (

    <div className="modal fade" tabIndex="-1"  id="add-countersettings">
      <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
        <div className="modal-content">
          <div className="page-wrapper-new p-0">
            <div className="content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h3>settings({data?.name})</h3>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeButtonRef}

                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>


              <form className='col-12 align-items-center justify-content-between  p-5' onSubmit={handleSubmit(handleFormSubmit)} >

                <div className="row">

                  <div className="col-lg-3 col-sm-12">
                    <input type="checkbox" className="" {...register("PreviewInvoice")} />Preview sales invoice
                  </div>
                  <div className="col-lg-9 col-md-6 col-sm-12 general_decription">
                    To preview sales invoice, set this setting as true
                  </div>


                </div>
                <div className="row">

                  <div className="col-lg-3 col-sm-12">
                    <input type="checkbox" className="" {...register("PreviewReport")} />Preview report
                  </div>
                  <div className="col-lg-9 col-md-6 col-sm-12 general_decription">
                    To preview reports, set this setting as true
                  </div>


                </div>
                <div className="row">

                  <div className="col-lg-3 col-sm-12">
                    <input type="checkbox" className=""  {...register("PrintSalesInvoiceInDotMatrixPrinter")} />Print sale invoice in dot matrix printer
                  </div>
                  <div className="col-lg-9 col-md-6 col-sm-12 general_decription">
                    Enabling this setting will allow to print sales invoice in dot matrix printer
                  </div>


                </div>
                <div className="row">

                  <div className="col-lg-3 col-sm-12">
                    <input type="checkbox" className=""  {...register("PrintReportsInDotMatrixPrinter")} />Print reports in dot matrix printer
                  </div>
                  <div className="col-lg-9 col-md-6 col-sm-12 general_decription">
                    Enabling this setting will allow to print reports in dot matrix printer
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="input-blocks">
                      <label>
                        Receipt printer
                      </label>
                      <input
                        type="text"
                        id="name"
                        {...register("ReceiptPrinter")}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="input-blocks">
                      <label>
                        Barcode printer
                      </label>
                      <input
                        type="text"
                        id="name"
                        {...register("BarcodePrinter")}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="input-blocks">
                      <label>
                        Dot matrix printer
                      </label>
                      <input
                        type="text"
                        id="name"
                        {...register("DotMatrixPrinter")}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="input-blocks">
                      <label>
                        Non impact printer
                      </label>
                      <input
                        type="text"
                        id="name"
                        {...register("NonImpactPrinter")}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="input-blocks">
                      <label>
                        Branch
                      </label>
                      <select
                        {...register("branch", { required: "section is required" })}

                        className=" form-control"
                      >
                        <option value="" >
                          select branch
                        </option>
                        {BranchByUser?.map((unit) => (
                          <option key={unit.branch.guid} value={unit.branch.guid}>
                            {unit.branch.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="modal-footer-btn">
                    <button

                      type="button"
                      className="btn btn-cancel me-2"
                    // onClick={() => resetmodal()}

                    >
                      Reset
                    </button>
                    <button type="submit" className="btn btn-save" >
                      save
                    </button>

                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>




  )
}

export default CounterSettings


