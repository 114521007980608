import axios from "axios";
import User from "../core/json/User";
import AMC from "../core/json/Amc";
const base_path = process.env.REACT_APP_API_URL;

export const getBranchList = async () => {
  const response = await axios.get(`${base_path}/api/Branch/GetAll`);
  return response.data;
};
export const getBranchByGuid = async (id) => {
  const response = await axios.get(
    `${base_path}/Api/Branch/GetByGuid?guid=${id}`
  );
  return response.data;
};
export const AuthenticateUser = async (objUser) => {
  var criteriaJsonString = JSON.stringify(objUser);
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/User/AuthenticateUser?criteria=${criteriaJsonString}`
  );
  return response.data;
};
export const userService = async ({ id }) => {
  const response = await axios.get(
    `${base_path}/Api/User/GetByGuid?guid=${id?.id}`
  );
  return response.data;
};
export const getUserbyBranch = async ({ id }) => {
  const response = await axios.get(
    `${base_path}/Api/User/GetAllByBranch?globalBranchID=${id}`
  );
  return response.data;
};
export const getCategorybyBranch = async ({ id }) => {
  const response = await axios.get(
    `${base_path}/Api/Category/GetAllByBranch?globalBranchID=${id}`
  );
  return response.data;
};
export const getGroupEntrybyBranch = async ({ id }) => {
  const response = await axios.get(
    `${base_path}/Api/GroupEntry/GetAllByBranch?globalBranchID=${id}`
  );
  return response.data;
};
export const getSectionbyBranch = async ({ id }) => {
  const response = await axios.get(
    `${base_path}/Api/Section/GetAllByBranch?globalBranchID=${id}`
  );
  return response.data;
};
export const getCounterbyBranch = async ({ id }) => {
  const response = await axios.get(
    `${base_path}/Api/Counter/GetAllByBranch?globalBranchID=${id}`
  );
  return response.data;
};
export const getProductbyBranch = async ({ id }) => {
  const response = await axios.get(
    `${base_path}/Api/Product/GetAllByBranch?globalBranchID=${id}`
  );
  return response.data;
};
export const getUser = async () => {
  const response = await axios.get(`${base_path}/Api/User/GetAll`);
  return response.data;
};
export const companyService = async () => {
  const response = await axios.get(`${base_path}/Api/Company/GetAll`);
  return response.data;
};
export const getAllBranchByUser = async ({ id }) => {
  const response = await axios.get(
    `${base_path}/Api/Branch/GetAllByUser?userGuid=${id?.id}`
  );
  return response.data;
};
export const getAMCstatus = async (branch) => {
  const response = await axios.post(`${base_path}/Api/AMC/GetAMCStatus`, {
    branch,
  });
  return response.data;
};
export const getMenuService = async ({ id }) => {
  console.log(id);
  const response = await axios.get(
    `${base_path}/Api/Menu/GetMenuByUser?userID=${id?.id}`
  );
  return response.data;
};
//get settings by branch
export const getSettings = async (branch) => {
  const response = await axios.get(
    `${base_path}/Api/Setting/GetAllByBranch?branchID=${branch?.guid}`
  );
  return response.data;
};
