import { DomainBase } from "./DomainBase";

export class PromoProductsClass extends DomainBase{
    constructor() {
        super();
        this.promotion = null;
        this.branch = null;
        this.section = null;
        this.promoProductsDetail = [];
    }
}