import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  PlusCircle,
  RotateCcw,
  ChevronUp,
} from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Table from "../../core/pagination/datatable";
import {
  getSectionList,
  deleteSection,
} from "../../services/MasterApiServices";

import { SectionClass } from "../../core/json/SectionClass";
import SectionModal from "../../components/master/modal/SectionModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";
import UserBranchTransfer from "../../components/adminsitration/UserBranchTransfer";
import SectionBranchTransfer from "./MastersBranchTransfer/SectionBranchTransfer";
import { fetchSection } from "../../redux/userSetting";
import SectionSettings from "../../components/master/modal/SectionSettings";
import SectionAsignTable from "../../components/master/SectionAsignTable";

const Section = () => {
  const dispatch = useDispatch();
  const[selectedSection,setSelectedSection] = useState();
  const [sectionId, setSectionId] = useState();
  const [searchText, setSearchText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [updateData, setUpdateData] = useState([]);
  const {sectionList} = useSelector((state) => state.usersetting);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const {menuItems,specialpermission} = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  const [settingpermit, setsettingpermit] = useState();
  const [tablepermit, settablepermit] = useState();
  const [branchpermit, setbranchpermit] = useState();
  //get branch
  const { branch } = useSelector((state) => state.branchlist);

  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  useEffect(() => {
    setpermit(menuItems?.menuPermissions?.find(e=> e.menu.name =="Section"))
    setbranchpermit(specialpermission?.userSpecialPermissionList?.find(e=> e.specialPermission.name =="Section Branch Transfer"))
    setsettingpermit(specialpermission?.userSpecialPermissionList?.find(e=> e.specialPermission.name =="Section Settings"))
    settablepermit(specialpermission?.userSpecialPermissionList?.find(e=> e.specialPermission.name =="Section Tables"))
  }, []);
  // Fetch the section list when the component mounts
  useEffect(() => {
    dispatch(fetchSection(branch.guid));

  }, [branch]);
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const modifiedList = Array.isArray(sectionList)
    ? sectionList?.filter((value) =>
        value.name?.toLowerCase().includes(searchText?.toLowerCase())
      )
    : [];
  // Fetch the section list


  const handleEditClick = async (guid) => {
    try {
      console.log("inside edit");
      
      // const sectionList =response
      const Singlesection = sectionList?.find(e=> e.guid == guid)

      setUpdateData(Singlesection); // Set the data for editing
      setModalMode("edit"); // Set the modal mode to 'edit'
      setModalOpen(true); // Open the modal
    } catch (error) {
      console.error(
        "Error fetching section by GUID:",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  //function to get single object from filtering list of section objects
  function getSectionguid(guid, sectionList) {
    const filteredData = Array.isArray(sectionList)
      ? sectionList?.filter((section) => section.guid === guid)
      : [];
    return filteredData[0];
  }

  // Initialize SweetAlert with ReactContent
  const MySwal = withReactContent(Swal);

  // Handle the click event to delete a section
  const handleDeleteClick = (obj) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objsection = new SectionClass();
          objsection.id = obj.id;
          objsection.guid = obj.guid;
          objsection.Branch = branch;
          console.log(branch, "branch is proper");
          objsection.name = obj.name;
          objsection.shortName = obj.shortName;
          objsection.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}";
          const response = await deleteSection(objsection);
          console.log(objsection);

          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The section has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              dispatch(fetchSection(branch.guid)); // Refresh the list
              // setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(
            "Error deleting section:",
            error?.response?.data?.Message || "something went wrong"
          );
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message,
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };


  const handleSelectSection=(id)=>{
    if(sectionId==id){
      setSectionId()
    }else{
  
      setSectionId(id)
    }
   } 


  const addSectionSettings=()=>{
       
    const selected = sectionList?.find(e=>e.guid==sectionId)
    setSelectedSection(selected)
  }



  const columns = [
    {
      title: "",
      dataIndex: "",
      render: (_, record) => (
        <div key={record.id}>
          <input type="checkbox" onChange={()=>handleSelectSection(record.guid)} checked={record.guid==sectionId} />
        </div>
      )
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name?.length - b.name?.length,
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      sorter: (a, b) => a.shortName?.length - b.shortName?.length,
    },

    ...(permit?.edit || permit?.deletion
      ? [
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
          {permit?.edit && 
            <Link
              className="me-2 p-2"
              to="#"
              onClick={() => handleEditClick(obj.guid)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>}
            {permit?.deletion && 
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => handleDeleteClick(obj)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>}
          </div>
        </div>
      ),
    },
  ]
  : [])
  ];

  // Filter the categories based on the search text
 return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Section</h4>
                <h6>Manage section here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link onClick={() => exportPDF(sectionList, "Sections")}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link onClick={() => exportExcel(sectionList, "Sections")}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link onClick={()=>HandlePrint(sectionList, "Sections")}>
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={()=>dispatch(fetchSection())}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            {branchpermit?.userRole.id !==0 &&
            <div className="page-btn">
                <Link
                  to="#"
                  className="btn btn-added"
                  data-bs-target="#section-branch-trans"
                  data-bs-toggle="modal"
                >
                 
                  Branch Transfer
                </Link>
              </div>}
              {settingpermit?.userRole.id !==0 &&
              <div className="page-btn">
                <button
                 disabled={!sectionId}
                  to="#"
                  className="btn btn-added"
                  data-bs-target="#sectionsettings"
                  data-bs-toggle="modal"
                  onClick={addSectionSettings}
                >
                 
                  Section Settings
                </button>
              </div>}
              {tablepermit?.userRole.id !==0 &&
              <div className="page-btn">
                <button
                 disabled={!sectionId}
                  to="#"
                  className="btn btn-added"
                  data-bs-target="#sectionasign"
                  data-bs-toggle="modal"
                  onClick={addSectionSettings}
                >
                 
                  Add/Asign Table
                </button>
              </div>}
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add"); // Set the modal mode to 'add'
                  setModalOpen(true); // Open the modal
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={modifiedList} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* section Modal */}
      <SectionSettings   data={selectedSection}/>
      <SectionAsignTable data={selectedSection}/>
      <SectionBranchTransfer />
      {modalOpen && (
        <SectionModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
        
            setSearchText(""); // Clear the search text
          }}
        />
      )}
    </div>
  );
};

export default Section;
