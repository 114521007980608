import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSectionDateWiseSale, getSectionWiseSale } from "../services/DashBoardApiService";


export const fetchSectionWiseSale = createAsyncThunk(
  "filters/fetchSectionWiseSale",
  async ({branch,StartingDate, EndingDate}) => {
    const data = await getSectionWiseSale(branch,StartingDate, EndingDate);
        return data;
      }
    );
export const fetchSectionDateWiseSale = createAsyncThunk(
  "filters/fetchSectionDateWiseSale",
  async ({branch,StartingDate, EndingDate}) => {
    const data = await getSectionDateWiseSale(branch,StartingDate, EndingDate);
        return data;
      }
    );
const listsectionSale = createSlice({
  name: "sectionSale",
  initialState: {
    sectionSale:[],
    sectiondateSale:[],
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSectionWiseSale.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSectionWiseSale.fulfilled, (state, action) => {
        state.sectionSale=action.payload;
        state.loading = false;
      })
      .addCase(fetchSectionWiseSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSectionDateWiseSale.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSectionDateWiseSale.fulfilled, (state, action) => {
        state.sectiondateSale=action.payload;
        state.loading = false;
      })
      .addCase(fetchSectionDateWiseSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export default listsectionSale.reducer;