import { createSlice } from "@reduxjs/toolkit";
export const ProductData = createSlice({
    name: "product",
    initialState: {

        product:[],
        productsection:[],
        pending: false,
        error: null,
        errorMessage: null,
    },
    reducers: {
        setproductData: (state, action) => {
            state.product = action.payload;
            state.pending = false;
            state.error = null;
            state.errorMessage = null;
        },
        setproductDatasection: (state, action) => {
            state.productsection = action.payload;
            state.pending = false;
            state.error = null;
            state.errorMessage = null;
        },

    },
});

export const {
    setproductData,
    setproductDatasection
 } =
    ProductData.actions;
export default ProductData.reducer;
