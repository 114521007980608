import { DomainBase } from "./DomainBase";

export class PromotionClass extends DomainBase {
    constructor() {
        super();
        this.name = '';
        this.fromDate = new Date();
        this.toDate = new Date();
        this.isHappyHour = false;
        this.fromTime = new Date();
        this.toTime = new Date();
        this.branch = null;
    }
}