import React from 'react'
import expire from '../img/expire.png'
const Expired = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' ,paddingTop:"100px" }}>
  <img src={expire} alt="" style={{ height: '450px' }} className='col-lg-4 col-md-6 col-12' />
</div>
  )
}

export default Expired
