import { DomainBase } from "./DomainBase";

  export class CategoryClass extends DomainBase {
    constructor() {
        super();
        this.name = '';
        this.shortName = '';
        this.course = 0;
        this.isActive = false;
        this.branch = null;
    }
}
          