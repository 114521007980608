import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {GetTop5CategoryWiseSale, GetTop5ItemWiseSale, GetTop5WaiterWiseSale } from "../services/DashBoardApiService";

export const fetchTop5WaiterWiseSale = createAsyncThunk(
  "filters/fetchTop5WaiterWiseSale",
  async ({branch, StartingDate, EndingDate}) => {
    const data = await GetTop5WaiterWiseSale(branch, StartingDate, EndingDate);
    return data;
  }
);


export const fetchTop5ItemWiseSale = createAsyncThunk(
  "filters/fetchTop5ItemWiseSale",
  async ({ branch, StartingDate, EndingDate}) => {
    const data = await GetTop5ItemWiseSale( branch, StartingDate, EndingDate);
    return data;
  }
);
export const fetchTop5CategoryWiseSale = createAsyncThunk(
  "filters/fetchTop5CategoryWiseSale",
  async ({  branch, StartingDate, EndingDate}) => {
    const data = await GetTop5CategoryWiseSale( branch, StartingDate, EndingDate);
    return data;
  }
);

const listTop5 = createSlice({
  name: "Top5sale",
  initialState: {
    Top5WaiterWiseSale: [],
    Top5ItemWiseSale: [], 
    Top5CategoryWiseSale: [], 
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTop5WaiterWiseSale.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTop5WaiterWiseSale.fulfilled, (state, action) => {
        state.Top5WaiterWiseSale = action.payload;
        state.loading = false;
      })
      .addCase(fetchTop5WaiterWiseSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchTop5ItemWiseSale.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTop5ItemWiseSale.fulfilled, (state, action) => {
        state.Top5ItemWiseSale = action.payload;
        state.loading = false;
      })
      .addCase(fetchTop5ItemWiseSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchTop5CategoryWiseSale.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTop5CategoryWiseSale.fulfilled, (state, action) => {
        state.Top5CategoryWiseSale = action.payload;
        state.loading = false;
      })
      .addCase(fetchTop5CategoryWiseSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export default listTop5.reducer;
