import { DomainBase } from "./DomainBase";

export class CustomerClass extends DomainBase{
    constructor() {
        super();
        this.name = "";//ff*
        this.fullName = "";//ff
        this.mobile = ""; // ff*
        this.email = "";//ff
        this.address1 = "";//ff
        this.address2 = "";//ff
        this.address3 = ""; //ff      
        // this.discountPercentage = 0;//ff
        this.city = "";//ff
        this.pin = ""; //ff       
        this.phone = "";//ff
        this.fax = ""; //ff       
        this.landMark = "";//ff
        this.gstNo = "";//ff
       //class
        this.state = null;//name,code strings//ff//*
        this.customerReceiptType = null;//name     
        this.country = null; //name 
        this.ledger = null;//name
        this.branch = null;
       //not in form
        this.creditLimit = 0;       
        this.date = new Date();    
    }
}
