import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAMCstatus } from "../services/CoreApiServices";
import { getwebmenupermission, getwebmenuspecialpermission } from "../services/AdminApiServices";


export const fetchAMCstatus = createAsyncThunk(
  "filters/fetchAMCstatus",
  async (branch) => {
    const data = await getAMCstatus(branch);
        return data;
      }
    );
export const fetchmenuPermissions = createAsyncThunk(
  "filters/fetchmenuPermissions",
  async (data) => {
    const response = await getwebmenupermission(data);
        return response;
      }
    );
export const fetchspecialpermission = createAsyncThunk(
  "filters/fetchspecialpermission",
  async (data) => {
    const response = await getwebmenuspecialpermission(data);
        return response;
      }
    );
const listamc = createSlice({
  name: "amc",
  initialState: {
    amcdetails:{},
    isBetweenAMCPeriod: false,
    isTodayOrAfterAMCToDate: false,
    remainingDaysToExtension: 0,
    menuItems: [],
    specialpermission: [],
  },
  reducers: {
    setisBetweenAMCPeriod: (state, action) => {
        state.isBetweenAMCPeriod = action.payload;
    },
    setisTodayOrAfterAMCToDate: (state, action) => {
        state.isTodayOrAfterAMCToDate = action.payload;
    },
    setremainingDaysToExtension: (state, action) => {
        state.remainingDaysToExtension = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAMCstatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAMCstatus.fulfilled, (state, action) => {
        state.amcdetails=action.payload;
        state.loading = false;
      })
      .addCase(fetchAMCstatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchmenuPermissions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchmenuPermissions.fulfilled, (state, action) => {
        state.menuItems=action.payload;
        state.loading = false;
      })
      .addCase(fetchmenuPermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchspecialpermission.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchspecialpermission.fulfilled, (state, action) => {
        state.specialpermission=action.payload;
        state.loading = false;
      })
      .addCase(fetchspecialpermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
    setremainingDaysToExtension,setisBetweenAMCPeriod,setisTodayOrAfterAMCToDate} = listamc.actions;
export default listamc.reducer;