import { DomainBase } from "./DomainBase";

export class CounterClass extends DomainBase {
    constructor() {
        super();
        this.name = '';
        this.shortName = '';
        this.assignedSections = [];
        this.isActive = true;
        this.branch = null;
        this.counterSection = [];
    }
}
