import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { CouponCodeClass } from "../../../core/json/CouponCode";
import {
  editCoupencode,
  addCouponCode,
} from "../../../services/MasterApiServices";
import { useSelector } from "react-redux";

const CouponCodeModal = ({ mode, data, handleClose, handleRefresh }) => {
  // Get branch from redux
  const { branch } = useSelector((state) => state.branchlist);

  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  const { register, handleSubmit, setValue, reset } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  function resetmodal(){
    if (mode === "edit" && data) {
      setValue("code", data?.code);
      setValue("couponName", data?.couponName);
      setValue("discountPercent", data?.discountPercent);
    }else{reset();}

  }

  useEffect(() => {
    resetmodal();
  }, [data, mode, setValue]);

  // Handle form submission for adding or editing a Couponcode
  const handleFormSubmit = async (formData) => {
    const objCouponcode = new CouponCodeClass();
    objCouponcode.code = formData?.code;
    objCouponcode.couponName = formData?.couponName;
    objCouponcode.discountPercent = Number(formData?.discountPercent);
    objCouponcode.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}"; // userId
    // objCouponcode.branch = branch;
    if (mode === "edit") {
      objCouponcode.guid = data?.guid;
      objCouponcode.id = data?.id;
    }

    try {
      const response =
        mode === "edit"
          ? await editCoupencode(objCouponcode)
          : await addCouponCode(objCouponcode);

      if (response) {
        toast.success("Success");
        handleRefresh(); // Refresh the list
        handleClose(); // Close the modal
      }else if(response==false){
        toast.error("false")
       }
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error handling Couponcode:", error);
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {mode === "edit" ? "Edit Coupon code" : "Add Coupon code"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Code<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Code"
                            {...register("code")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Coupon Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Coupon Name"
                            {...register("couponName")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Discount Percent
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Discount Percent"
                            {...register("discountPercent")}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={() => resetmodal()}
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-submit">
                          {mode === "edit" ? "Save Changes" : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponCodeModal;
