import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllBranchByUser } from "../services/CoreApiServices";


export const fetchBranchByUser = createAsyncThunk(
  "filters/fetchBranchByUser",
  async (id) => {
  
    const data = await getAllBranchByUser({id});
        return data;
      }
    );
const UserBranch = createSlice({
  name: "UserBranch",
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBranchByUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBranchByUser.fulfilled, (state, action) => {
        state.BranchByUser=action.payload;
        state.loading = false;
      })
      .addCase(fetchBranchByUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export default UserBranch.reducer;