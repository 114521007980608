import { DomainBase } from "./DomainBase";

export class PurchaseClass extends DomainBase {
  constructor() {
    super();
    this.branch = null;
    this.date = new Date();
    this.invoiceDate = new Date();
    this.invoiceNo = "";
    this.purchasePaymentType = 0;
    this.purchaseTransType = 0;
    this.supplier = null;
    this.purchaseDetails = [];
    this.refNo = "";
    this.currency = null;
    this.shipmentNo = "";
    this.shipmentTerms = "";
    this.remarks = "";
    this.addition = 0.0;
    this.additionPercentage = 0.0;
    this.discount = 0.0;
    this.discountPercentage = 0;
    this.roundOff = 0;
    this.advancePaid = 0.0;
    this.tax = null;
    this.cessAmount = 0.0;
    this.deliveryDate = new Date();
    this.company = null;
    this.financialYear = null;
    this.currencyRate = 0;
    this.total = 0;
    this.user = null;
    this.grossTotal = 0.0;
    this.taxableAmount = 0;
    this.taxTotal = 0.0;
    this.discountTotal = 0.0;
    this.netTotal = 0.0;
    this.referenceType = 0;
    this.entrynumber = "";
    this.duedate = new Date();
  }
}
