import { DomainBase } from "./DomainBase";

export class InvStockAdjustment extends DomainBase {
  constructor() {
    super();
    this.date = new Date();
    this.no = "";
    this.type = null;
    this.branch = null;
    this.remarks = "";
    this.stockAdjustmentDetails = [];
  }
}
