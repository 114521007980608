import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronUp, Filter, RotateCcw } from "feather-icons-react";
import Table from "../../../core/pagination/datatableReports";
import { useEffect } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import LoadingUtils from "../../../core/utils/LoadingUtils";
import { setheadertoggle } from "../../../redux/toggle";
import { renderTooltip } from "../../../redux/helper";
import {
  printReport,
  exportExcel,
  exportPDF,
} from "../../../core/utils/reportUtils";

import { DatePicker, Select, Tabs } from "antd";
import {
  getConsolidatedReportItemwise,
  getTimeBasedReport,
} from "../../../services/ReportApiService";
import {
  DatatablePagingParameter,
  Filterclass,
} from "../../../core/json/DatatablePagingParameters";
import dayjs from "dayjs";
import { useMemo } from "react";
import Branch from "../../../core/json/Branch";
import { Calendar } from "feather-icons-react/build/IconComponents";

const HourlySalesReport = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [searchText, setSearchText] = useState("");
  const [groupedData, setGroupedData] = useState([]);
  const [grandTotal, setgrandTotal] = useState([]);
  const [reportData, setreportData] = useState([]);
  const [modifiedList, setModifiedList] = useState();
  const [loadingDes, setLoadingDes] = useState(false);
  const [modifiedFooterTotals, setModifiedFooterTotals] = useState({});
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const { branch } = useSelector((state) => state.branchlist);
  const dispatch = useDispatch();
  const { TabPane } = Tabs;

  const { togglehead } = useSelector((state) => state.headerToggle);
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
    handleFilter(e.target.value);
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const handleFromDateChange = (date) => {
    setFromDate(date);

    setToDate(null);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };
  useEffect(() => {
    handleReportList();
    if (searchText) {
      handleFilter(searchText);
    }
  }, [branch, fromDate, toDate]);
  // useEffect(() => {
  //   handleFilter();
  // }, [searchText]);
  const handleFilter = (text) => {
    console.log("inside handle filter", text);
    const lowerCasedSearchText = text?.toLowerCase() || "";

    const filterData = (data) =>
      data.filter((item) =>
        Object.values(item)?.some((val) => {
          // Check if val is a string and contains the search text
          const stringMatch =
            typeof val === "string" &&
            val.toLowerCase().includes(lowerCasedSearchText);

          // Check if val is a number and matches the search text
          const numberMatch =
            typeof val === "number" &&
            val.toString().includes(lowerCasedSearchText);

          return stringMatch || numberMatch;
        })
      );

    const filteredGroupedData = filterData(groupedData);
    const filteredReportData = filterData(reportData);

    setGroupedData(filteredGroupedData);
    setreportData(filteredReportData);
    if (lowerCasedSearchText === "") {
      handleReportList();
    }
  };
  const handleReset = () => {
    setFromDate(null);
    setToDate(null);
    setSearchText("");
    setGroupedData([]);
    setreportData([]);
    setgrandTotal({});
  };
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const getActiveTabData = () => {
    let fieldMapping = {};

    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex;
      });
      return mappings;
    };
    switch (activeTab) {
      case "1":
        fieldMapping = createfieldMapping(columns);

        return {
          data: groupedData,
          columns: columns,
          fieldMapping,
        };
      case "2":
        fieldMapping = createfieldMapping(detailedColumns);

        return {
          data: reportData,
          columns: detailedColumns,
          fieldMapping,
        };
      default:
        return { data: [], columns: [] };
    }
  };

  const handleReportList = async () => {
    setLoadingDes(true);
    if (!fromDate || !toDate) {
      setLoadingDes(false);
      setGroupedData([]);
      setgrandTotal([]);
      setreportData([]);
      return;
    }
    const DatatablePagingParameters = new DatatablePagingParameter();
    // const branchobj = new Branch();
    const formattedfromDate = dayjs(fromDate).add(1, "day");
    const formattedtoDate = dayjs(toDate).add(1, "day");
    const Filters = new Filterclass();
    DatatablePagingParameters.PageNumber = 1;
    DatatablePagingParameters.PageSize = -1;
    Filters.Branch = branch;
    Filters.FromDate = formattedfromDate;
    Filters.ToDate = formattedtoDate;
    Filters.PaymentType = "";
    Filters.Waiter = "";
    Filters.Counter = "";
    Filters.Section = "";
    try {
      const response = await getTimeBasedReport({
        DatatablePagingParameters,
        Filters,
      });
      setLoadingDes(false);
      const data = response?.data?.salesReportTime || [];
      setreportData(data);
      console.log(data, "data");
      const { groupedArray, footerTotals } = groupBillsByHour(data);
      setGroupedData(groupedArray);
      setgrandTotal(footerTotals);
      setLoadingDes(false);
    } catch (error) {
      console.error(error?.response?.data?.Message || "Something went wrong");
      setreportData([]);
    }
  };

  // Group bills by hour and calculate totals
  function groupBillsByHour(data) {
    const result = {};

    data.forEach((bill) => {
      const hour = new Date(bill.billTime).getHours(); // Extract hour
      const hourRange = `${String(hour).padStart(2, "0")}:00 - ${String(
        hour
      ).padStart(2, "0")}:59`; // 24-hour format

      if (!result[hourRange]) {
        result[hourRange] = { hour: hourRange, billCount: 0, netTotal: 0 };
      }

      result[hourRange].billCount += 1;
      result[hourRange].netTotal += bill.netTotal;
    });

    // Convert to array and sort by hour in ascending order
    const groupedArray = Object.values(result).sort(
      (a, b) => parseInt(a.hour.split(":")[0]) - parseInt(b.hour.split(":")[0])
    );

    // Calculate grand total
    const grandTotal = groupedArray.reduce(
      (acc, curr) => acc + curr.netTotal,
      0
    );

    //calculate grand bill count
    const grandBillCount = groupedArray.reduce(
      (acc, curr) => acc + curr.billCount,
      0
    );
    const footerTotals = {
      billCount: grandBillCount,
      netTotal: grandTotal,
    };
    // Add netTotal percentage
    groupedArray.forEach((item) => {
      item.netTotalPercent = ((item.netTotal / grandTotal) * 100).toFixed(2); // Format to 2 decimals
    });

    return { groupedArray, footerTotals };
  }

  const columns = [
    {
      title: "Hour Range",
      dataIndex: "hour",
      key: "hour",
      sorter: (a, b) =>
        parseInt(a.hour.split(":")[0]) - parseInt(b.hour.split(":")[0]),
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Bill Count",
      dataIndex: "billCount",
      key: "billCount",
      // align: "center",
      // render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Sales Amount",
      dataIndex: "netTotal",
      key: "netTotal",
      // align: "right",
      render: (value) => value.toFixed(2),
    },
    {
      title: "Sales %",
      dataIndex: "netTotalPercent",
      key: "netTotalPercent",
      //align: "right",
      render: (value) => `${value}%`,
    },
  ];
  const detailedColumns = [
    // {
    //   title: "SLNo",
    //   dataIndex: "hour",
    //   key: "hour",

    // },
    {
      title: "Bill No.",
      dataIndex: "billNo",
      key: "billNo",
    },
    {
      title: "Date and Time",
      dataIndex: "billTime",
      key: "billTime",

      render: (text) => {
        const date = new Date(text);

        const formattedDate = date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });

        return <strong>{formattedDate}</strong>;
      },
    },
    {
      title: "User",
      dataIndex: "userName",
      key: "userNam",
    },
    {
      title: "Counter",
      dataIndex: "counterName",
      key: "counterName",
    },
    {
      title: "Section",
      dataIndex: "sectionName",
      key: "sectionName",
    },
    {
      title: "Amount",
      dataIndex: "netTotal",
      key: "netTotal",
    },
  ];

  return (
    <div>
      <LoadingUtils loading={loadingDes} />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Hourly Reports</h4>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link onClick={() => exportPDF(getActiveTabData, grandTotal)}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() => exportExcel(getActiveTabData, grandTotal)}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() => printReport(getActiveTabData, grandTotal)}
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handleReportList()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <div className="input-blocks me-2">
                    <button
                      className="btn btn-filters rounded-2 h-100 btn-sm"
                      // onClick={(e) => handleFilter(e.target.value)}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      Search
                    </button>
                  </div>

                  <div className="input-blocks">
                    <button
                      className="btn btn-reset  rounded-2 btn-sm"
                      onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks ms-4">
                      <label>From Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                          className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          selected={fromDate}
                          value={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <label>To Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                  <TabPane tab="Summary View" key="1">
                    <Table
                      columns={columns}
                      dataSource={groupedData}
                      footerTotals={grandTotal}
                    />
                  </TabPane>
                  <TabPane tab="Detailed View" key="2">
                    <Table
                      columns={detailedColumns}
                      dataSource={reportData}
                      footerTotals={grandTotal}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HourlySalesReport;
