import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {GetLeast5CategoryWiseSale, GetLeast5ItemWiseSale, GetLeast5WaiterWiseSale } from "../services/DashBoardApiService";

export const fetchLeast5WaiterWiseSale = createAsyncThunk(
  "filters/fetchLeast5WaiterWiseSale",
  async (branch ) => {
    const data = await GetLeast5WaiterWiseSale(branch);
    return data;
  }
);


export const fetchLeast5ItemWiseSale = createAsyncThunk(
  "filters/fetchLeast5ItemWiseSale",
  async ({ branch }) => {
    const data = await GetLeast5ItemWiseSale( branch);
    return data;
  }
);
export const fetchLeast5CategoryWiseSale = createAsyncThunk(
  "filters/fetchLeast5CategoryWiseSale",
  async ({  branch }) => {
    const data = await GetLeast5CategoryWiseSale( branch);
    return data;
  }
);

const listLeast5 = createSlice({
  name: "Least5sale",
  initialState: {
    Least5WaiterWiseSale: [],
    Least5ItemWiseSale: [], 
    Least5CategoryWiseSale: [], 
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeast5WaiterWiseSale.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLeast5WaiterWiseSale.fulfilled, (state, action) => {
        state.Least5WaiterWiseSale = action.payload;
        state.loading = false;
      })
      .addCase(fetchLeast5WaiterWiseSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchLeast5ItemWiseSale.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLeast5ItemWiseSale.fulfilled, (state, action) => {
        state.Least5ItemWiseSale = action.payload;
        state.loading = false;
      })
      .addCase(fetchLeast5ItemWiseSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchLeast5CategoryWiseSale.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLeast5CategoryWiseSale.fulfilled, (state, action) => {
        state.Least5CategoryWiseSale = action.payload;
        state.loading = false;
      })
      .addCase(fetchLeast5CategoryWiseSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export default listLeast5.reducer;
