
//  export class Section extends DomainBase{
//     constructor() {
//         super();
//         this.name = '';

import { DomainBase } from "./DomainBase";

//         this.shortName = '';
 export class SectionClass extends DomainBase{
    constructor() {
        super();
        this.name = '';
        this.shortName = '';
        // this.PrintTempFile = '';
        // this.department = null;
        // this.NoOfPrints = 0;
        // this.InvoicePrefix = '';
        // this.StartNo = 0;
        // this.IsActive = false;
        this.Branch = null;
    }
}