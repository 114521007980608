
import { DomainBase } from "./DomainBase";

export class LedgerClass extends DomainBase {
    constructor() {
        super();
        this.name = "";
    }
    
}
