import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { companyService } from "../services/CoreApiServices";


export const fetchCompanyDetail = createAsyncThunk(
  "filters/fetchCompanyDetail",
  async () => {
    const data = await companyService();
        return data;
      }
    );
const listcompanyInfo = createSlice({
  name: "companyInfo",
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCompanyDetail.fulfilled, (state, action) => {
        state.companyInfolist=action.payload;
        state.loading = false;
      })
      .addCase(fetchCompanyDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export default listcompanyInfo.reducer;