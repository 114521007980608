import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from "react-select";
import { savesettings } from '../../services/AdminApiServices';
import toast from 'react-hot-toast';

const SettingPrint = () => {
  const { register, handleSubmit, setValue } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
 
  const { branchsettings } = useSelector((state) => state.settings);
  const { branch } = useSelector((state) => state.branchlist);


  useEffect(() => {
    setValue("general", {
      timeToClearPoleDisplay: branchsettings?.timeToClearPoleDisplay,
      timeToRefreshPayScreen: branchsettings?.timeToRefreshPayScreen,
      totalAmountForDiscount: branchsettings?.totalAmountForDiscount,
      enableBillSearchByTable: branchsettings?.enableBillSearchByTable,
      tableAlignmentRowNumber: branchsettings?.tableAlignmentRowNumber,
      tableAlignmentColumnNumber: branchsettings?.tableAlignmentColumnNumber,
      showSaleAmountInPoleDisplay: branchsettings?.showSaleAmountInPoleDisplay,
      enableLoyalty: branchsettings?.enableLoyalty,
      enableChairInSale: branchsettings?.enableChairInSale,
      orderProductByReferenceCode: branchsettings?.orderProductByReferenceCode,
      showChangeSectionButtonInPay: branchsettings?.showChangeSectionButtonInPay,
      showSplitButtonInPay: branchsettings?.showSplitButtonInPay,
      showMergeButtonInPay: branchsettings?.showMergeButtonInPay,
      showPaxPopupInSale: branchsettings?.showPaxPopupInSale,
      numberOfKOTPrints: branchsettings?.numberOfKOTPrints,
      applicationBackgroundImageNumber: branchsettings?.applicationBackgroundImageNumber,
      changeApplicationDesign: branchsettings?.changeApplicationDesign,
      hideModifierDetailsInKOTPrint: branchsettings?.hideModifierDetailsInKOTPrint,
      enableDataAutoSyncWithServer: branchsettings?.enableDataAutoSyncWithServer,
      enableVirtualKeyboard: branchsettings?.enableVirtualKeyboard,
      enableCess: branchsettings?.enableCess,
      enableCounterSection: branchsettings?.enableCounterSection,
      isStockProduct: branchsettings?.isStockProduct,
      showUnitPriceOfProductInSale: branchsettings?.showUnitPriceOfProductInSale,
      enablePrintInKitchenManager: branchsettings?.enablePrintInKitchenManager,
      kitchenManagerPrinter: branchsettings?.kitchenManagerPrinter,
      disableMultipleOrderInTable: branchsettings?.disableMultipleOrderInTable,
      viewSalesOrderFromTable: branchsettings?.viewSalesOrderFromTable,
      showCategoryAsCoursewiseInKOT: branchsettings?.showCategoryAsCoursewiseInKOT,
      enableWaiterwiseLogin: branchsettings?.enableWaiterwiseLogin,
      generateQrCodeInBillPrint: branchsettings?.generateQrCodeInBillPrint,
      sendMailWhileDayClose: branchsettings?.sendMailWhileDayClose,
      emailToSendDailyCloseReport: branchsettings?.emailToSendDailyCloseReport,
      arrangePhoneNumberAboveNameInCustomerMenu: branchsettings?.arrangePhoneNumberAboveNameInCustomerMenu,
      disableTaxEditInCreditBill: branchsettings?.disableTaxEditInCreditBill,
      currencyFormat: branchsettings?.currencyFormat,
      dateFormat: branchsettings?.dateFormat,
      defaultUserType: branchsettings?.defaultUserType,
      enableGeneralKeypad: branchsettings?.enableGeneralKeypad,
      generateSaleBillPrint: branchsettings?.generateSaleBillPrint,
      numberOfDaysExtendForBillingAfterDailyClose: branchsettings?.numberOfDaysExtendForBillingAfterDailyClose,
      numberOfDaysForEditBill: branchsettings?.numberOfDaysForEditBill,
      generateSalesInvoiceInDotMatrixPrinter: branchsettings?.generateSalesInvoiceInDotMatrixPrinter,
      hideItemwiseSummaryInDailyClose: branchsettings?.hideItemwiseSummaryInDailyClose,
      hideSectionSummaryInDailyClose: branchsettings?.hideSectionSummaryInDailyClose,
      showDateInDailyClose: branchsettings?.showDateInDailyClose,
      billNumberSeriesType: branchsettings?.billNumberSeriesType,
      enableArabicMenuDescription: branchsettings?.enableArabicMenuDescription,
      defaultState: branchsettings?.defaultState,
      enableProductNameBilling: branchsettings?.enableProductNameBilling,
      generateKOTOnCashInsale: branchsettings?.generateKOTOnCashInsale,
      showAllProductsInKOTPrint: branchsettings?.showAllProductsInKOTPrint,
      generateKOTPrint: branchsettings?.generateKOTPrint,
      showCancelReasonPopupInPay: branchsettings?.showCancelReasonPopupInPay,
      disableTaxInSale: branchsettings?.disableTaxInSale,
      disableLastSaleBillPrintOnEnter: branchsettings?.disableLastSaleBillPrintOnEnter,
      hideInvoiceRePrintMenu: branchsettings?.hideInvoiceRePrintMenu,
      showWaiterPopupOnSaveSale: branchsettings?.showWaiterPopupOnSaveSale,
      enableContinuousBillNumberInSale: branchsettings?.enableContinuousBillNumberInSale,
      enableVAT: branchsettings?.enableVAT,
      generateDailyClosePrint: branchsettings?.generateDailyClosePrint,
      showCreditButtonInSales: branchsettings?.showCreditButtonInSales,
      showTaxButtonInSaleForSpecialCustomer: branchsettings?.showTaxButtonInSaleForSpecialCustomer,
      changeProductDetailsWhileBilling: branchsettings?.changeProductDetailsWhileBilling,
      enableTokenNumber: branchsettings?.enableTokenNumber,
      enableSectionWiseTokenNumber: branchsettings?.enableSectionWiseTokenNumber,
      enableOtherLanguage: branchsettings?.enableOtherLanguage,
      showSaleBillInAllCounters: branchsettings?.showSaleBillInAllCounters,
      showSaleBillInAllCounterAndSections: branchsettings?.showSaleBillInAllCounterAndSections,
      showSaleBillInAllSections: branchsettings?.showSaleBillInAllSections,
      deliverySection: branchsettings?.deliverySection,
      showTablePopupInSection: branchsettings?.showTablePopupInSection,
      enableDepartmentInSection: branchsettings?.enableDepartmentInSection,
      disableSalePrintInPayScreen: branchsettings?.disableSalePrintInPayScreen,
      enableShiftClose: branchsettings?.enableShiftClose,
      generatePrintInCashButton: branchsettings?.generatePrintInCashButton,
      enableDailyCloseSummaryPrint: branchsettings?.enableDailyCloseSummaryPrint,
      showWaiterPopupInSection: branchsettings?.showWaiterPopupInSection,
      showCustomerPopupInSection: branchsettings?.showCustomerPopupInSection,
      dailyClosePrintFormat: branchsettings?.dailyClosePrintFormat,
      showFieldToEnterArabicDescriptionInProduct: branchsettings?.showFieldToEnterArabicDescriptionInProduct,
      roundOffInSale: branchsettings?.roundOffInSale,
      comboType: branchsettings?.comboType,
      showKeyBoardForEnterQuantityManually: branchsettings?.showKeyBoardForEnterQuantityManually,
      ledDisplayPort: branchsettings?.ledDisplayPort,
      enablePoleDisplayAutoClear: branchsettings?.enablePoleDisplayAutoClear,
      defaultCurrency: branchsettings?.defaultCurrency
    })
  }, [branchsettings,branch])
  const closeButtonRef = useRef();

  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  const handleGeneralSettings=async(data)=>{
    
    try {
      const response = await savesettings({setting: data.general, branch: branch});
      if(response==true){
        toast.success("success")
        closemodal()
      }
   
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  }
  return (
    <div className="modal-body custom-modal-body">
      <form onSubmit={handleSubmit(handleGeneralSettings)}>
        <div className='form_body'>
          <div className="row">
            <label><b>Kitchen manager print : </b> </label>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input type="checkbox" {...register("general.enablePrintInKitchenManager")} />
                <label className='m-3'>Enable print in kitchen manager</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              Enabling this setting will generate print in kitchen manager</div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-blocks add-product">
                <label>Kitchen Manager Printer</label>

                <input type="text" {...register("general.kitchenManagerPrinter")} />

              </div>


            </div>
            <hr />
          </div>
          <div className="row">
            <label><b>KOT print : </b> </label>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-blocks add-product">
                <label>Number Of KOT Prints</label>

                <select name="" id="" className="form-control" {...register("general.numberOfKOTPrints")}>
                                <option value="" disabled >Select</option>
                                <option value="1" >1</option>
                                <option value="2" >2</option>
                                <option value="3" >3</option>
                                <option value="4" >4</option>
                                <option value="5" >5</option>
                                <option value="6" >6</option>
                                <option value="7" >7</option>
                                <option value="8" >8</option>
                                <option value="9" >9</option>
                                <option value="10" >10</option>
                                
                              </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12"></div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input type="checkbox" {...register("general.generateKOTPrint")} />
                <label className='m-3'>Generate KOT print</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              When this setting enabled, kot print will generate when doing a sale </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input type="checkbox" {...register("general.generateKOTOnCashInsale")} />
                <label className='m-3'>Generate KOT on cash in sale</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              Enabling this setting will generate kot print while clicking cash button on Sales screen </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input type="checkbox" {...register("general.showAllProductsInKOTPrint")} />
                <label className='m-3'>Show all products in KOT print</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              When this setting enabled, kot print will show all items in each kot regardless of kitchen </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input type="checkbox" {...register("general.showCategoryAsCoursewiseInKOT")} />
                <label className='m-3'>Show category as course wise in KOT</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              When this setting enabled, kot items will show as in the order of category in kot print, (order we set in category form) </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input type="checkbox" {...register("general.hideModifierDetailsInKOTPrint")} />
                <label className='m-3'>Hide modifier details in KOT print</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              When this setting enabled, product modifier details will not show in KOT print </div>
            <hr />
          </div>
          <div className="row">
            <label><b>Sale bill print: </b> </label>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input type="checkbox" {...register("general.generateSalesInvoiceInDotMatrixPrinter")} />
                <label className='m-3'>Generate sales invoice in dot matrix printer</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              When this setting enabled, sale bill print will generate in dot matrix printer </div>
            <hr />

          </div>
          <div className="row">
            <label><b>Application design: </b> </label>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input type="checkbox" {...register("general.changeApplicationDesign")} />
                <label className='m-3'>Change application design</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              This setting is to change application design </div>
            <hr />

          </div>
        </div>
        <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                          ref={closeButtonRef}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-submit">
                          Submit
                        </button>
                      </div>
                    </div>
      </form>

    </div>
  )
}

export default SettingPrint

