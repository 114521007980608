// import { DomainBase } from "./DomainBase";

// export class PaymentCard extends DomainBase {
//     constructor() {
//         super();
//         this.cardName = '';
//     }
// }
import { DomainBase } from "./DomainBase";
 
export class PaymentCardCass extends DomainBase{
    constructor(){
        super();
        this.cardName = '';
    }
}