export class Filters {
  constructor() {
    this.FromDate = null;
    this.ToDate = null;
    this.Section = "";
    this.Counter = "";
    this.PaymentType = "";
    this.PaymentCardType = "";
    this.DayClosed = false;
    this.User = "";
    this.SaleStatus = "";
    this.ItemName = "";
    this.ShiftNumber = "";
    this.Waiter = "";
    this.IsLiveSale = false;
    this.Product = "";
    this.Category = "";
    this.Group = "";
    this.Customer = "";
    this.AccountType = "";
    this.Type = "";
    this.SalesAuditStatus = "";
    this.EditType = "";
    this.Branch = null;
  }
}
