import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import { useSelector } from 'react-redux';

const LeastFiveSale = () => {
  const [xaxiswaiter, setXaxiswaiter] = useState([]);
  const [yaxiswaiter, setYaxiswaiter] = useState([]);
  const [xaxisitem, setXaxisitem] = useState([]);
  const [yaxisitem, setYaxisitem] = useState([]);
  const [xaxiscategory, setXaxiscategory] = useState([]);
  const [yaxiscategory, setYaxiscategory] = useState([]);
  const {initial}= useSelector((state) => state.initailslice);
  const { Least5WaiterWiseSale, Least5ItemWiseSale,Least5CategoryWiseSale} = useSelector((state) => state.least5sale);

  useEffect(() => {
    if (initial) {
      setXaxisitem(Least5ItemWiseSale?.map((e) => e?.product?.name));
      setYaxisitem(Least5ItemWiseSale?.map((e) => Math.floor(Number(e.billTotal?.toFixed(2)))));
      setXaxiswaiter(Least5WaiterWiseSale?.map((e) => e?.user?.name));
      setYaxiswaiter(Least5WaiterWiseSale?.map((e) => Math.floor(Number(e.billTotal?.toFixed(2)))));
      setXaxiscategory(Least5CategoryWiseSale?.map((e) => e?.category?.name));
      setYaxiscategory(Least5CategoryWiseSale?.map((e) => Math.floor(Number(e.billTotal?.toFixed(2)))));
    }
  }, [initial,Least5WaiterWiseSale, Least5ItemWiseSale,Least5CategoryWiseSale]);
  console.log(xaxisitem,"dddd");
  const optionsitem = {
    series: [
      {
        name: "Item wise Sales",
        data: yaxisitem,
      },
    ],
    chart: {
      height: 273,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#FF9F43"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "",
      align: "left",
    },
    xaxis: {
      categories: xaxisitem,
      label:"Item"
    },
    yaxis: {
      label:"Bill Total"
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  };
  const optionswaiter = {
    series: [
      {
        name: "Waiter wise Sale",
        data: yaxiswaiter,
      },
    ],
    chart: {
      height: 273,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#FF9F43"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "",
      align: "left",
    },
    xaxis: {
      categories: xaxiswaiter,
      label:"Waiter"
    },
    yaxis: {
      label:"Bill Total"
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  };
  const optionscategory = {
    series: [
      {
        name: "Category wise Salea",
        data: yaxiscategory,
      },
    ],
    chart: {
      height: 273,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#FF9F43"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "",
      align: "left",
    },
    xaxis: {
      categories: xaxiscategory,
      label:"Category"
    },
    yaxis: {
      label:"Bill Total"
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  };
  return (
    <div className='d-block'>
    <h5>Item Wise Sale</h5>
     <Chart
                  options={optionsitem}
                  series={optionsitem.series}
                  type="area"
                  height={273}
                />
                  <h5>Waiter Wise Sale</h5>
       <Chart
                  options={optionswaiter}
                  series={optionswaiter.series}
                  type="area"
                  height={273}
                />
                <h5>Category Wise Sale</h5>
       <Chart
                  options={optionscategory}
                  series={optionscategory.series}
                  type="area"
                  height={273}
                />
  </div>
  )
}

export default LeastFiveSale
