import { DomainBase } from "./DomainBase";

export class InvSales extends DomainBase {
  constructor() {
    super();
    this.branch = null;
    this.invdate = new Date();
    this.financialYear = null;
    this.salesPaymentType = 0;
    this.salesTransType = 0;
    this.salesMode = 0;
    this.customer = null;
    this.customerName = ""; //nullable: true
    this.customerContactno = ""; //nullable: true
    this.refNo = ""; //nullable: true
    this.currency = null; //string($uuid)
    this.dispatch = ""; //nullable: true
    this.dispatchTrough = ""; //nullable: true
    this.terms = ""; //nullable: true
    this.deliveryDate = new Date();
    this.additionalAmt = 0.0;
    this.discAmt = 0.0;
    this.roundOff = 0.0;
    this.paid = 0.0;
    this.change = 0.0;
    this.cash = 0.0;
    this.card = 0.0;
    this.bank = 0.0;
    this.upi = 0.0;
    this.reftype = 0;
    this.igstTotal = 0.0;
    this.cgstTotal = 0.0;
    this.sgstTotal = 0.0;
    this.cessAmount = 0.0;
    this.grossTotal = 0.0;
    this.discountTotal = 0.0;
    this.taxTotal = 0.0;
    this.netTotal = 0.0;
    this.entrynumber = "";
    this.invSalesDetails = []; //nullable: true
  }
}
