import React from "react";
import { Table } from "antd";

const AdminTable = ({props, columns, dataSource,handleSelect }) => {
 
    return (
        <Table
        key={props}
        className="table datanew dataTable no-footer"
        columns={columns}
        dataSource={dataSource}
        onRow={(record) => ({
          onClick: () => handleSelect(record.guid), 
          style: { cursor: "pointer" },
        })}
      />
    );
  };

export default AdminTable
