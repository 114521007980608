import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { PulseLoader } from "react-spinners";


export default function LoadingUtils({ loading }) {
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
         <PulseLoader
                      color="#FF9F43"
                      size={15}
                      speedMultiplier={1}
                    />
      </Backdrop>
    </div>
  );
}