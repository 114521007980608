import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { editCurrency, addCurrency } from "../../../services/MasterApiServices";
import { fetchCustomer } from "../../../redux/userSetting";
import { useSelector } from "react-redux";

const CurrencyModal = ({ mode, data, handleClose, handleRefresh }) => {
  const { register, handleSubmit, setValue, reset ,formState: { errors } } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { branch } = useSelector((state) => state.branchlist);
  function resetmodal(){
    if (mode === "edit" && data) {
      setValue("name", data.name);
      setValue("rate", data.rate);
      setValue("unit", data.unit);
      setValue("subUnit", data.subUnit);
      setValue("active", data.active);
    }else{reset();}

  }

  useEffect(() => {
    resetmodal();
  }, [data, mode, setValue]);

  // Handle form submission for adding or editing a Currency
  const handleFormSubmit = async (formData) => {
    const objCurrency = {
      name: formData.name,
      rate: parseFloat(formData.rate),
      unit: formData.unit,
      subUnit: formData.subUnit,
      active: formData.active,
      updatedUser: "{9b57619d-63c8-4e27-9a59-639f0c80e4a1}",
    };
    if (mode === "edit") {
      objCurrency.guid = data.guid;
      objCurrency.id = data.id;
    }

    try {
      const response =
        mode === "edit"
          ? await editCurrency(objCurrency)
          : await addCurrency(objCurrency);

      if (response) {
        toast.success("Success");
       
        handleRefresh(); // Refresh the list
        handleClose(); // Close the modal
      }else if(response==false){
        toast.error("false")
       }
    } catch (error) {
      toast.error(error?.response?.data?.Message || "something went wrong");
      console.error("Error handling :", error);
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {mode === "edit" ? "Edit Currency" : "Add Currency"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Name"
                            {...register("name")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Rate<span className="text-danger">*</span>
                          </label>
                          
                           <input
      className="form-control"
      type="number"
      step="any"
      placeholder="Rate"
      {...register("rate", {
        required: "Rate is required",
        min: {
          value: 0,
          message: "Rate cannot be negative",
        },
      })}
    />
     {errors.rate && <p className="text-danger">{errors.rate.message}</p>}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Unit</label>
                          <input
                            type="text"
                            placeholder="Unit"
                            {...register("unit")}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>SubUnit</label>
                          <input
                            type="text"
                            placeholder="SubUnit"
                            {...register("subUnit")}
                          />
                        </div>
                      </div>
                      <div className="mb-0">
                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <span className="status-label">Status</span>
                          <input
                            type="checkbox"
                            id="user2"
                            className="check"
                            defaultChecked="true"
                            {...register("active")}
                          />
                          <label htmlFor="user2" className="checktoggle" />
                        </div>
                      </div>
                    </div>
                   <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={() => resetmodal()}
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-submit">
                          {mode === "edit" ? "Save Changes" : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyModal;
