import { DomainBase } from "./DomainBase";

  export class CounterSetting extends DomainBase{
    constructor() {
        super();
        this.Counter = null;
        this.RememberUser = false;
        this.Username = '';
        this.Theme = false;
        this.ReceiptPrinter = '';
        this.BarcodePrinter = '';
        this.NonImpactPrinter = '';
        this.DotMatrixPrinter = '';
        this.Branch = null;
        this.PreviewReport = false;
        this.PreviewInvoice = false;
        this.PrintSalesInvoiceInDotMatrixPrinter = false;
        this.PrintReportsInDotMatrixPrinter = false;
    }
}