import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getProductWiseSale } from "../services/DashBoardApiService";


export const fetchProductWiseSale = createAsyncThunk(
  "filters/fetchProductWiseSale",
  async ({branch, StartingDate, EndingDate}) => {
  console.log(branch);
    const data = await getProductWiseSale(branch, StartingDate, EndingDate);
        return data;
      }
    );
const listProductSale = createSlice({
  name: "ProductSale",
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductWiseSale.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductWiseSale.fulfilled, (state, action) => {
        state.ProductSale=action.payload;
        state.loading = false;
      })
      .addCase(fetchProductWiseSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export default listProductSale.reducer;