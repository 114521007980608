
import { Link } from "react-router-dom";
import React from "react";

import Select from "react-select";


// import Addunits from "../../core/modals/inventory/addunits";
// import AddCategory from "../../core/modals/inventory/addcategory";
// import AddBrand from "../../core/modals/addbrand";
import {
  ArrowLeft,
  
  ChevronDown,
  ChevronUp,
  Info,
  
 
  PlusCircle,
  
 
  
} from "feather-icons-react/build/IconComponents";


import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { all_routes } from "../../../Router/all_routes";
import Addunits from "../../../core/modals/inventory/addunits";
import AddCategory from "../../../core/modals/inventory/addcategory";
import AddBrand from "../../../core/modals/addbrand";

function Selectbranch() {
  const route = all_routes;
  

  

  // const [selectedDate, setSelectedDate] = useState(new Date());
  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };
  // const [selectedDate1, setSelectedDate1] = useState(new Date());
  // const handleDateChange1 = (date) => {
  //   setSelectedDate1(date);
  // };
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  
  const warehouse = [
    { value: "Products", label: "Products" },
    { value: "legendary", label: "Legendary" },
    { value: "determined", label: "Determined" },
    { value: "sincere", label: "Sincere" },
  ];
  const category = [
    { value: "choose", label: "Choose" },
    { value: "lenovo", label: "Lenovo" },
    { value: "electronics", label: "Electronics" },
  ];
  const subcategory = [
    { value: "choose", label: "Choose" },
    { value: "lenovo", label: "Lenovo" },
    { value: "electronics", label: "Electronics" },
  ];
 
 
 
return (
    <div>
          {/* Add Purchase */}
          <div className="modal fade" id="add-branchtransfer">
          <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>New Product</h4>
              <h6>Create new product</h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <div className="page-btn">
                <Link to={route.productlist} className="btn btn-secondary">
                  <ArrowLeft className="me-2" />
                  Back to Product
                </Link>
              </div>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                <Link
                  data-bs-toggle="tooltip"
                  data-bs-placement="top" 
                  title="Collapse"
                  id="collapse-header"
                 
                >
                  <ChevronUp className="feather-chevron-up" />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
        </div>
        {/* /add */}
        <form>
          <div className="card">
            <div className="card-body add-product pb-0">
              <div
                className="accordion-card-one accordion"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-controls="collapseOne"
                    >
                      <div className="addproduct-icon">
                        <h5>
                          <Info className="add-info" />

                          <span>Branch Transfer</span>
                        </h5>
                        <Link to="#">
                          <ChevronDown className="chevron-down-add" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                       <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Entity</label>
                            <Select
                              classNamePrefix="react-select"
                              options={warehouse}
                              placeholder="Products"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="addservice-info">
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <div className="add-newplus">
                                <label className="form-label">Source</label>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add-units-category"
                                >
                                  <PlusCircle className="plus-down-add" />
                                  <span>Add New</span>
                                </Link>
                              </div>
                              <Select
                                classNamePrefix="react-select"
                                options={category}
                                placeholder="Choose"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <label className="form-label">Destination</label>
                              <Select
                                classNamePrefix="react-select"
                                options={subcategory}
                                placeholder="Choose"
                              />
                            </div>
                            <div className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                     <th className="bg-warning">Product</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        hai
                      </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div className="table-responsive">
                <table className="table datanew">
                  <thead>
                    <tr>
                     <th className="bg-warning">Product</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        hai
                      </td>
                      </tr>
                    </tbody>
                </table>
              </div>
             </div>
          </div>
            </div>
            </div>
           </div>
                </div>
              </div>
             </div>
          </div>
          <div className="col-lg-12">
            <div className="btn-addproduct mb-4">
             <Link to={route.addproduct} className="btn btn-submit">
                Save Product
              </Link>
            </div>
          </div>
        </form>
        {/* /add */}
      </div>
      <Addunits />
      <AddCategory />
      <AddBrand />
    </div>
            </div>
    </div>
  )
}

export default Selectbranch