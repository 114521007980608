import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  RotateCcw,
  PlusCircle,
  ChevronUp,
} from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Table from "../../core/pagination/datatable";
import {
  getCounterList,
  deleteCounter,
} from "../../services/MasterApiServices";
import CounterModal from "../../components/master/modal/CounterModal";
import { CounterClass } from "../../core/json/Counter";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../core/img/imagewithbasebath";

import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";
import UserBranchTransfer from "../../components/adminsitration/UserBranchTransfer";
import CounterBranchTransfer from "./MastersBranchTransfer/CounterBranchTransfer";
import { fetchCounter } from "../../redux/userSetting";
import AddCounterSection from "../../components/master/modal/AddCounterSection";
import CounterSettings from "../../components/master/modal/CounterSettings";

const Counter = () => {
  const dispatch = useDispatch();
  const [sectionId, setSectionId] = useState();
  const[selectedSection,setSelectedSection] = useState();
  const [searchText, setSearchText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [updateData, setUpdateData] = useState([]);
  const {menuItems,specialpermission} = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  const [specialpermitsetting, setspecialpermitsetting] = useState();
  const [specialpermit, setspecialpermit] = useState();
  const [branchpermit, setbranchpermit] = useState();
  const data = useSelector((state) => state.toggle_header);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const {  counterList } = useSelector(
    (state) => state.usersetting
  );
  // Get branch from redux
  const { branch } = useSelector((state) => state.branchlist);

  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  // Fetch the counter list when the component mounts
  useEffect(() => {
    dispatch(fetchCounter(branch.guid));
    
  }, [branch]);
  useEffect(() => {
    setpermit(menuItems?.menuPermissions?.find(e=> e.menu.name =="Counter"))
    setspecialpermitsetting(specialpermission?.userSpecialPermissionList?.find(e=> e.specialPermission.name =="Counter Settings"))
    setspecialpermit(specialpermission?.userSpecialPermissionList?.find(e=> e.specialPermission.name =="Counter Section"))
    setbranchpermit(specialpermission?.userSpecialPermissionList?.find(e=> e.specialPermission.name =="Counter Branch Transfer"))
  }, []);

  // Handle the search
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const modifiedList = Array.isArray(counterList)
    ? counterList?.filter((value) =>
        value.name?.toLowerCase().includes(searchText?.toLowerCase())
      )
    : [];
  // Handle the click event to edit a counter
  const handleEditClick = async (guid) => {
    try {
      const SingleCounter = counterList?.find(e=>e.guid == guid)

      setUpdateData(SingleCounter); // Set the data for editing
      setModalMode("edit"); // Set the modal mode to 'edit'
      setModalOpen(true); // Open the modal
    } catch (error) {
      console.error(
        "Error fetching counter by GUID:",
        error?.response?.data?.Message
      );
    }
  };
  const MySwal = withReactContent(Swal);
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };

  // Handle the click event to delete a counter
  const handleDeleteClick = (obj) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objCounter = new CounterClass();
          objCounter.id = obj.id;
          objCounter.guid = obj.guid;
          objCounter.branch = branch;
          objCounter.name = obj.name;
          objCounter.shortName = obj.shortName;
          objCounter.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}";

          const response = await deleteCounter(objCounter);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The counter has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              dispatch(fetchCounter(branch.guid)); // Refresh the list
              setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting counter:", error?.response?.data?.Message);
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message,
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };
   
  const handleSelectSection=(id)=>{
    if(sectionId==id){
      setSectionId()
    }else{
  
      setSectionId(id)
    }
   } 
   
   const addCounterSection=()=>{
       
    const selected = counterList?.find(e=>e.guid==sectionId)
    setSelectedSection(selected)
  }

  const columns = [
    {
      title: "",
      dataIndex: "",
      render: (_, record) => (
        <div key={record.id}>
          <input type="checkbox" onChange={()=>handleSelectSection(record.guid)} checked={record.guid==sectionId} />
        </div>
      )
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name?.length - b.name?.length,
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      sorter: (a, b) => a.shortName?.length - b.shortName?.length,
    },
    ...(permit?.edit || permit?.deletion
      ? [
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
          {permit?.edit && 
            <Link
              className="me-2 p-2"
              to="#"
              onClick={() => handleEditClick(obj.guid)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>}
            {permit?.deletion && 
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => handleDeleteClick(obj)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>}
          </div>
        </div>
      ),
    },
  ]
  : [])
  ];


  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Counter</h4>
                <h6>Manage counters here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={() => renderTooltip("PDF")}
                >
                  <Link onClick={() => exportPDF(counterList, "Counter List")}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={() => renderTooltip("Excel")}
                >
                  <Link
                    onClick={() => exportExcel(counterList, "Counter List")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() => HandlePrint(counterList, "Counter List")}
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={() => renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => dispatch(fetchCounter(branch.guid))}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
              {branchpermit?.userRole.id !==0 &&
            <div className="page-btn">
                <Link
                  to="#"
                  className="btn btn-added"
                  data-bs-target="#counter-branch-trans"
                  data-bs-toggle="modal"
                >
                 
                  Branch Transfer
                </Link>
              </div>}
              {specialpermit?.userRole.id !==0 &&
              <div className="page-btn">
                <button
                  disabled={!sectionId}
                  className="btn btn-added"
                  data-bs-target="#add-countersection"
                  data-bs-toggle="modal"
                  onClick={addCounterSection}
                >
                 Counter Section
                </button>
              </div>}

              {specialpermitsetting?.userRole.id !==0 &&
              <div className="page-btn">
                <button
                disabled={!sectionId}
                  className="btn btn-added"
                  data-bs-target="#add-countersettings"
                  data-bs-toggle="modal"
                  onClick={addCounterSection}
                >                 
                   Settings
                </button>
              </div>}
              {permit?.add && 
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add"); // Set the modal mode to 'add'
                  setModalOpen(true); // Open the modal
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>}
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={modifiedList} />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <CounterBranchTransfer />
      <CounterSettings  data={selectedSection} />
      <AddCounterSection data={selectedSection} />
      
      {modalOpen && (
        <CounterModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => // Refresh the list
            setSearchText("") // Clear the search text
          }
        />
      )}
    </div>
  );
};

export default Counter;
