import React, { useEffect, useRef, useState } from "react";
import { fetchUserByBranch } from "../../../redux/userServices";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategorybyBranch,
  getUserbyBranch,
} from "../../../services/CoreApiServices";
import { Trash2 } from "feather-icons-react/build/IconComponents";
import toast from "react-hot-toast";
import { BranchMapper } from "../../../core/json/BranchMapper";
import { branchTransfer } from "../../../services/AdminApiServices";
import { fetchCategory } from "../../../redux/userSetting";
import { fetchAllCategory } from "../../../redux/getallmatser";

const CategoryBranchTransfer = () => {
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [desUsers, setdesUsers] = useState([]); // Initialize as an empty array
  const [desBranch, setdesBranch] = useState("");
  const { branch } = useSelector((state) => state.branchlist);
  const { BranchByUser } = useSelector((state) => state.userBranch);
  const { categorygetall } = useSelector((state) => state.getallmaster);
  const { userId } = useSelector((state) => state.userauth);

  useEffect(() => {
   
      dispatch(fetchAllCategory());
    
  }, []);

  const handleAddSelect = (id) => {
    if (selectedData.includes(id)) {
      setSelectedData(selectedData.filter((item) => item !== id));
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  const handleDestination = (e) => {
    setdesBranch(e.target.value);
    handleDesBranch(e.target.value);
  };

 
  const handleDesBranch = async (id) => {
    try {
      const response = await getCategorybyBranch({ id });
      // Check if response is an array before setting
      if (Array.isArray(response)) {
        setdesUsers(response);
      } else {
        console.error("Unexpected response format:", response);
        setdesUsers([]); // Reset to empty array on unexpected response
      }
      setSelectedList([]);
    } catch (error) {
      console.error("Error fetching categories by branch:", error);
      setdesUsers([]); // Reset to empty array on error
    }
  };

  const handleaddSelected = () => {
    if (!desBranch) {
      toast.error("Please select a destination");
    } else {
      const newSelected = selectedData?.filter((e) =>
        desUsers?.length > 0 ? !desUsers.some((obj) => obj.guid === e) : true
      );
      const newSelectedlist = categorygetall.filter((user) =>
        newSelected.includes(user.guid)
      );
      setSelectedList(newSelectedlist);
    }
  };

  const handleAddAll = () => {
    if (!desBranch) {
      toast.error("Please select a destination");
    } else {
      const newSelected = categorygetall.filter(
        (e) =>  desUsers?.length > 0 ? !desUsers.some((obj) => obj.guid === e.guid) : true
      );
      setSelectedList(newSelected);
    }
  };

  const handleDelete = (id) => {
    const updatedList = selectedList.filter((e) => e.guid !== id);
    setSelectedList(updatedList);
  };

  const handleBranchTranfer = async () => {
    const destinationBranch = BranchByUser?.find(
      (e) => e.branch?.guid == desBranch
    )?.branch;
    const SelectedEntity = selectedList?.map((e) => e.guid);

    const mapperobj = new BranchMapper();
    mapperobj.EntityID = 1;
    mapperobj.EntityType = "category";
    mapperobj.SelectedEntities = SelectedEntity;
    mapperobj.SourceBranch = branch;
    mapperobj.DestinationBranch = destinationBranch;
    mapperobj.updatedUser = `{${userId?.id}}`;
    mapperobj.createdUser = `{${userId?.id}}`;

    try {
      const response = await branchTransfer(mapperobj);
      if (response === true) {
        toast.success("Success");
        closemodal();
        dispatch(fetchCategory(branch.guid))
      }
    } catch (error) {
      console.error("Error during branch transfer:", error);
    }
  };

  const closeButtonRef = useRef();

  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };

  return (
    <div>
      <div className="modal fade" id="catagory-branch-trans">
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Category Branch Transfer</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeButtonRef}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="col-12 align-items-center justify-content-between">
                  <div className="modal-body custom-modal-body d-flex">
                    <div className="col-md-6 col-sm-12 col-lg-4">
                    
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Category</th>
                          </tr>
                        </thead>
                        <tbody
                        >
                          <div style={{maxHeight:"200px", overflow:"scroll"}}>
                          {categorygetall?.map((obj) => (
                            <tr key={obj?.guid}>
                              <td
                                className={
                                  selectedData.includes(obj?.guid)
                                    ? "branch-transfer-active"
                                    : "branch-transfer"
                                }
                                onClick={() => handleAddSelect(obj?.guid)}
                              >
                                {obj.name}
                              </td>
                            </tr>
                          ))}
                          </div>
                        </tbody>
                      </table>
                    </div>

                    <div className="col-md-6 col-sm-12 col-lg-4">
                      <div>
                        <button
                          className="btn btn-submit"
                          onClick={handleaddSelected}
                        >
                          Add Selected
                        </button>
                      </div>
                      <div className="mt-3">
                        <button
                          className="btn btn-submit"
                          onClick={handleAddAll}
                        >
                          Add All
                        </button>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12 col-lg-4">
                      <label htmlFor="">Destination</label>
                      <select
                        name=""
                        id=""
                        className="form-control"
                        onChange={handleDestination}
                        value={desBranch}
                      >
                        <option value="" disabled key="">
                          ---Select Branch---
                        </option>
                        {BranchByUser?.map((obj) => (
                          <option value={obj.branch.guid} key={obj.id}>
                            {obj?.branch.name}
                          </option>
                        ))}
                      </select>

                      <table className="table">
                        <thead>
                          <tr>
                            <th>Category</th>
                          </tr>
                        </thead>

                        <tbody
                          
                        >
                            <div style={{maxHeight:"200px", overflow:"scroll"}}>
                          {selectedList?.map((obj) => (
                            <tr key={obj?.guid} className="branch-transfer">
                              <td>{obj.name}</td>
                              <td
                                style={{ fontSize: "10px", color: "red" }}
                                onClick={() => handleDelete(obj?.guid)}
                              >
                                <Trash2
                                  style={{
                                    fontSize: "inherit",
                                    color: "inherit",
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                          {Array.isArray(desUsers) &&
                            desUsers?.map((obj) => (
                              <tr key={obj?.guid} className="branch-transfer">
                                <td>{obj.name}</td>
                                <td></td>
                              </tr>
                            ))}
                            </div>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="modal-footer-btn mb-4">
                      <button
                        type="submit"
                        className="btn btn-submit"
                        onClick={handleBranchTranfer}
                        disabled={!desBranch || branch?.guid === desBranch}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryBranchTransfer;
