import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  Download,
  File,
  Filter,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  User,
} from "feather-icons-react/build/IconComponents";
// import { setToogleHeader } from "../../core/redux/action";
// import { useDispatch, useSelector } from "react-redux";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../../core/pagination/datatable";
import { useSelector } from "react-redux";
import { getSalesByTransType } from "../../../services/SalesApiServices";
import * as XLSX from 'xlsx'; // for excel export
import jsPDF from 'jspdf'; // for pdf generation
import moment from "moment";



const SalesSupplier = ({data,supplierColumns}) => {
  
  const [searchText, setSearchText] = useState("");
  const[salesData,setsalesData] = useState("")
  const { branch } = useSelector((state) => state.branchlist);
 
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  
  useEffect(() => {
    
    handlesalesData();
  }, [branch]);

const handlesalesData = async () => {
  try {
    const response = await getSalesByTransType(branch, 0);

    setsalesData(Array.isArray(response.data) ? response.data : []);
    console.log(response.data,"g");
    
  } catch (error) {
    console.error(
      "Failed to fetch sales invoices",
      error?.response?.data?.Message || "something went wrong"
    );
  }
};
console.log(salesData,"ffffff");

// const filteredSalesData = salesData?.filter(item => {
//   const customerName = item.customerName.toLowerCase();
//   return customerName.includes(searchText.toLowerCase());
// });

  const columns = [
    {
      title: "Sl No",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Date",
      dataIndex: "invdate",
      sorter: (a, b) => new Date(a.invdate) - new Date(b.invdate),
      render: (date) => moment(date).format('MM/DD/YYYY'), // Custom format
    },
    {
      title: "Bill NO",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.reference?.length - b.reference?.length,
    },
    {
      title: "Customer Name",
      dataIndex: ["customer","name"],
      sorter: (a, b) => a.customer?.name.localeCompare(b.customer?.name)
    },
    {
      title: "Total",
      dataIndex: "grossTotal",
      sorter: (a, b) => a.grandTotal?.length - b.grandTotal?.length,
    },
    // {
    //   title: "Discount",
    //   dataIndex: "discountTotal",
    //   sorter: (a, b) => a.paid?.length - b.paid?.length,
    // },
    
    {
      title: "Tax",
      dataIndex: "taxTotal",
      
      sorter: (a, b) => a.createdBy?.length - b.createdBy?.length,
    },
    {
        title: "Net Total",
        dataIndex: "netTotal",
        
        sorter: (a, b) => a.createdBy?.length - b.createdBy?.length,
      },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: () => (
        <div className="action-table-data">
          <div className="edit-delete-action">
           
            {/* <Link
              className="me-2 p-2"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={showConfirmationAlert}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link> */}
          </div>
        </div>
      ),
    },
  ];
  // const dispatch = useDispatch();
  // const data = useSelector((state) => state.toggle_header);

  
  

 
  
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };



  return (
    
    <>
    

    <div id="salesTable">
      <Table columns={supplierColumns} dataSource={data} />
    </div>
  
  </>
           
  );
};

export default SalesSupplier;