import { DomainBase } from "./DomainBase";
export class BranchMapper extends DomainBase {
    constructor() {
        super();
        this.EntityID = 0;
        this.EntityType = "";
        this.SelectedEntities = [];
        this.SourceBranch = null;  //Branch Class to extend
        this.DestinationBranch = null; //Branch Class to extend
    }
}