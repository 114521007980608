import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBranchByGuid, getBranchList } from "../services/CoreApiServices";

// Fetch list of branches
export const fetchBranchList = createAsyncThunk(
  "branch/fetchBranchList", 
  async () => {
    const data = await getBranchList();
    
    return data;
  }
);

// Fetch branch by ID
export const fetchBranchById = createAsyncThunk(
  "branch/fetchBranchById", 
  async (guid) => { 
    const data = await getBranchByGuid(guid); 
    return data;
  }
);

const listbranch = createSlice({
  name: "branch",
  initialState: {
    BranchId: null,
    BranchName: null,
    branchlist: [],
    branch: [],
    branchById: [],
    defaultbranch: {},
    loading: false,
    error: null,
  },
  reducers: {
    setBranchId: (state, action) => {
      state.BranchId = action.payload;
    },
    setBranchName: (state, action) => {
      state.BranchName = action.payload;
    },
    setBranch: (state, action) => {
      state.branch = action.payload;
    },
    setdefaultBranch: (state, action) => {
      state.defaultbranch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBranchList.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(fetchBranchList.fulfilled, (state, action) => {
        state.branchlist = action.payload;
        state.loading = false;
      })
      .addCase(fetchBranchList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchBranchById.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(fetchBranchById.fulfilled, (state, action) => {
        state.branchById = action.payload;
        state.loading = false;
      });
  },
});

export const { setBranchId, setBranchName, setBranch,setdefaultBranch } = listbranch.actions;
export default listbranch.reducer;
