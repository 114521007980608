
import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  Download,
  File,
  Filter,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  User,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../../core/pagination/datatable";
import { useSelector } from "react-redux";
import { getSalesByTransType } from "../../../services/SalesApiServices";
import { saveAs } from 'file-saver'; // for saving files
import * as XLSX from 'xlsx'; // for excel export
import jsPDF from 'jspdf'; // for pdf generation
import moment from "moment";

const SalesItemWiseInvoice = ({Itemwisecolumns,itemwiseData}) => {
  const [searchText, setSearchText] = useState("");
  const [salesData, setSalesData] = useState([]);
  const { branch } = useSelector((state) => state.branchlist);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    handlesalesData();
  }, [branch]);

  const handlesalesData = async () => {
    try {
      const response = await getSalesByTransType(branch, 2);
      setSalesData(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error(
        "Failed to fetch sales invoices",
        error?.response?.data?.Message || "Something went wrong"
      );
    }
  };

  // const filteredSalesData = salesData?.filter(item => {
  //   const customerName = item.customerName.toLowerCase();
  //   return customerName.includes(searchText.toLowerCase());
  // });

  const columns = [
    {
      title: "Sl No",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    // {
    //   title: "Date",
    //   dataIndex: "invdate",
    //   sorter: (a, b) => new Date(a.invdate) - new Date(b.invdate),
    // },
    {
      title: "Date",
      dataIndex: "invdate",
      sorter: (a, b) => new Date(a.invdate) - new Date(b.invdate),
      render: (date) => moment(date).format('MM/DD/YYYY'), // Custom format
    },
    {
      title: "Bill NO",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entrynumber.localeCompare(b.entrynumber),
    },
    {
      title: "Customer Name",
      dataIndex: ["customer","name"],
      sorter: (a, b) => a.customer?.name.localeCompare(b.customer?.name)
    },
    {
      title: "Item Name",
      dataIndex: ["invSalesDetails", "0", "product", "name"],
      sorter: (a, b) => a.invSalesDetails[0]?.product.name.localeCompare(b.invSalesDetails[0]?.product.name),
    },
    {
      title: "Qty",
      dataIndex: ["invSalesDetails", "0", "quantity"],
      sorter: (a, b) => a.invSalesDetails[0]?.quantity - b.invSalesDetails[0]?.quantity,
    },
    {
      title: "Rate",
      dataIndex: ["invSalesDetails", "0", "price"],
      sorter: (a, b) => a.invSalesDetails[0]?.price - b.invSalesDetails[0]?.price,
    },
    {
      title: "Total",
      dataIndex: ["invSalesDetails", "0", "gross"],
      sorter: (a, b) => a.invSalesDetails[0]?.gross - b.invSalesDetails[0]?.gross,
    },
    {
      title: "Discount",
      dataIndex: ["invSalesDetails", "0", "discAmt"],
      sorter: (a, b) => a.invSalesDetails[0]?.discAmt - b.invSalesDetails[0]?.discAmt,
    },
    {
      title: "Tax",
      dataIndex: ["invSalesDetails", "0", "taxamnt"],
      sorter: (a, b) => a.invSalesDetails[0]?.taxAmnt - b.invSalesDetails[0]?.taxAmnt,
    },
    {
      title: "Net Total",
      dataIndex: ["invSalesDetails", "0", "netAmt"],
      sorter: (a, b) => a.invSalesDetails[0]?.netAmt - b.invSalesDetails[0]?.netAmt,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: () => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {/* <Link className="me-2 p-2" data-bs-toggle="modal" data-bs-target="#edit-units">
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2" to="#" onClick={showConfirmationAlert}>
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link> */}
          </div>
        </div>
      ),
    },
  ];

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

 

  return (
    <>
     
      <div id="sales-table">
        <Table columns={Itemwisecolumns} dataSource={itemwiseData} />
      </div>
      {/* {filteredSalesData?.length === 0 && <p>No sales data available.</p>} */}
    </>
  );
};
export default SalesItemWiseInvoice;