import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultbranch } from '../../services/AdminApiServices';
import { fetchUserByBranch } from '../../redux/userServices';
import { BranchgDefault } from '../../core/json/BranchDefault';
import { fetchBranchByUser } from '../../redux/branchByUser';

const DefaultBranch = () => {
    const dispatch = useDispatch()
    const { register, formState: { errors, isValid, isDirty }, handleSubmit, setValue, getValues, watch, control } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",

    });
    const { UserListByBranch } = useSelector((state) => state.userservice);
    const { BranchByUser } = useSelector((state) => state.userBranch);
    const { userId } =useSelector((state)=>state.userauth)
    const {  branch } = useSelector((state) => state.branchlist);

    
    const handleFormSubmit = async (formData) => {
        const data = new BranchgDefault()
        data.user = UserListByBranch?.find(e=>e.guid==userId.id)
        data.branch= BranchByUser?.find(e => e.branch.guid == formData.branch).branch
        try {
            const response = await setDefaultbranch(data);

            if (response == true) {
                toast.success("Success");
                closemodal();
                dispatch(fetchBranchByUser(userId)) //list branch
            } else if (response == false) {
                toast.error("false")
            }
        } catch (error) {
            toast.error(error?.response?.data?.Message);

        }
    };
    useEffect(() => {
        dispatch(fetchUserByBranch(branch.guid)) // list users
        const defaultBranch = BranchByUser?.find(e => e.isDefaultBranch === true);
        setValue('branch', defaultBranch ? defaultBranch?.branch?.guid : '')
    }, [BranchByUser])
    const closeButtonRef = useRef();
    const closemodal = () => {
        if (closeButtonRef.current) {
            closeButtonRef.current.click();
        }
    };
 
    return (
        <div className="modal fade" id="default-branch">
            <div className="modal-dialog modal-dialog-centered custom-modal-two">
                <div className="modal-content">
                    <div className="page-wrapper-new p-0">
                        <div className="content">
                            <div className="modal-header border-0 custom-modal-header">
                                <div className="page-title">
                                    <h4>
                                        Default Branch
                                    </h4>
                                </div>
                                <button
                                    type="button"
                                    className="close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    ref={closeButtonRef}

                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body custom-modal-body">
                                <form onSubmit={handleSubmit(handleFormSubmit)}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="input-blocks">
                                                <label>
                                                    Name <span className="text-danger">*</span>
                                                </label>
                                                <select name="" id="" className='form-control' {...register('branch')}>
                                                    <option value="" disabled>---Select Source Branch---</option>
                                                    {BranchByUser?.map((obj) => (
                                                        <option value={obj.branch.guid} key={obj.id}>{obj?.branch.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="modal-footer-btn">

                                            <button type="submit" className="btn btn-submit">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DefaultBranch
