import React from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ProductClass } from '../../../../core/json/ProductClass';
import { addNewProduct, getGroupEntryByGuid, getProductTypeguid, getUnitguid } from '../../../../services/MasterApiServices';
import toast from 'react-hot-toast';

function AddOtherDescription({ register}) {

  const { branch } = useSelector((state) => state.branchlist);
  const { product, productsection } = useSelector((state) => state.productdata);

  // Get user from redux
  const { userId } = useSelector((state) => state.userauth)

  console.log(productsection);

  // const handleotherDetails = async (formData) => {
  //   console.log(formData);
  //   const objproduct = new ProductClass();
  //   objproduct.code = product.code;
  //   objproduct.name = product.name;
  //   objproduct.shortName = product.shortName;
  //   objproduct.barCode = product.barCode;
  //   objproduct.category = await getCategorybyGuid(product.category);
  //   objproduct.type = await getProductTypeguid(product.type);
  //   objproduct.group = await getGroupEntryByGuid(product.group);
  //   objproduct.unit = await getUnitguid(product.unit);
  //   objproduct.cost = product.cost;
  //   objproduct.basePrice = product.basePrice;
  //   objproduct.otherDescription = product.otherDescription;
  //   objproduct.sectionWiseProductDetailList = productsection;
  //   objproduct.arabicDescription = formData.arabicDescription;
  //   objproduct.isRateVaryProduct = formData.isRateVaryProduct;
  //   objproduct.isDailyStockItem = formData.isDailyStockItem;
  //   objproduct.hsnCode = formData.hsnCode;
  //   objproduct.isMenuItem = formData.isMenuItem;
  //   objproduct.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}"; //userId


  //   try {
  //     const response = await addNewProduct(objproduct);
  //     console.log(objproduct);
  //     if (response) {
  //       toast.success("Success");
  //       //  handleRefresh(); // Refresh the list
  //       //  handleClose(); // Close the modal
  //     }
  //   } catch (error) {
  //     toast.error("Something went wrong");
  //     console.error("Error handling :", error);
  //   }
  // }
  return (
    <div>
      <div>
        <div className="modal-body custom-modal-body">
          <div className="branch-tabs col-12 align-items-center justify-content-between">

          </div>
          
            <div className="row">

              <div className="col-md-6 col-sm-12">
                <div className="col-auto input-blocks">
                  <label className="col-form-label">Arabic Description</label>
                </div>
                <div className="col input-blocks mb-3">
                  <input type="text" id="" className="form-control"  {...register("arabicDescription")} />
                </div>
              </div>
              <div className=" col-md-6 col-sm-12">
                <div className="col-auto input-blocks ">
                  <label className="col-form-label">HSN code</label>
                </div>
                <div className="col input-blocks mb-3">
                  <input type="text" id="" className="form-control" {...register("hsnCode")} />
                </div>
              </div>
            </div>
            <div className="row">

              <div className="col-lg-3 col-sm-12">
                <input type="checkbox" className="" {...register("isRateVaryProduct")} /> Is product price variable?
              </div>
              <div className="col-lg-9  col-sm-12 general_decription">
                When this setting enabled, we can change the price of product while billing, a popup box will show in sales screen
              </div>
            </div>
            <div className="row">

              <div className="col-lg-3 col-sm-12">
                <input type="checkbox" className="" {...register("isDailyStockItem")} />Daily Stock Item
              </div>
              <div className="col-lg-9  col-sm-12 general_decription">
                When this setting enabled, this product is considered as a stock product which will be shown in stock product
              </div>
            </div>
            <div className="row">

              <div className="col-lg-3 col-sm-12">
                <input type="checkbox" className="" {...register("isMenuItem")} />Menu Item
              </div>
              <div className="col-lg-9  col-sm-12 general_decription">
                When this setting enabled, this product is considered as a Menu Item that shows in BOQ
              </div>
            </div>
            <div className="col-lg-12">
             
            </div>
        
        </div>

      </div>
    </div>
  )
}

export default AddOtherDescription