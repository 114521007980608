import { DomainBase } from "./DomainBase";

export class ModifierClass extends DomainBase {
  constructor() {
    super();
    this.name = "";
    this.Rate = 0;
    this.ModifierCategory = null;
  }
}


