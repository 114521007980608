import { createSlice } from "@reduxjs/toolkit";
export const DataSlice = createSlice({
  name: "Data",
  initialState: {
    userId: "",
    userName:"",
    userRole:[],
    pending: false,
    error: null,
    errorMessage: null,
  },
  reducers: {

    setuserId: (state, action) => {
        state.userId = action.payload;
      },
    setuserName: (state, action) => {
        state.userName = action.payload;
      },
    setuserRole: (state, action) => {
        state.userRole = action.payload;
      }
     

  },
});

export const { setuserId, setuserName, setuserRole} =DataSlice.actions;
export default DataSlice.reducer;