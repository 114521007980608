import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  RotateCcw,
  ChevronUp,
  PlusCircle,
} from "feather-icons-react/build/IconComponents";

import Table from "../../core/pagination/datatable";
import { Pagination } from "antd";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";	
import { exportExcel, exportPDF, HandlePrint, renderTooltip } from "../../redux/helper";
import ProductBOQmodal from "../../components/master/modal/ProductBOQmodal";
import { getProductBOQlist, getProductBoqListView } from "../../services/MasterApiServices";
import { fetchProduct, fetchUnit } from "../../redux/userSetting";
import { exportreportExcel, exportreportPDF, printreportReport } from "../../core/utils/MainReportUtils";


function Productboq() {
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branchlist);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const {menuItems} = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  const [ProductBOQData, setProductBOQData] = useState([]);
  const [Productboq, setProductboq] = useState([]);
  const [productId, setProductId] = useState();
  const [selectedProduct, setselectedProduct] = useState();

console.log(ProductBOQData,'BOQQQ');


    
// Get user from redux


  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead))
  }
   useEffect(() => {
    handleProductBoqList()
   
    dispatch(fetchUnit())
    dispatch(fetchProduct(branch))
  }, [branch])
  useEffect(() => {
    setpermit(menuItems?.menuPermissions?.find(e=> e.menu.name =="Product BOQ"))
    
  }, []);
  const handleProductBoqList = async () => {
    try {
      const response = await getProductBOQlist(branch);
      console.log(response.data)

      if (Array.isArray(response)) {
        setProductBOQData(response);
      } else {
        setProductBOQData([]);
      }
    } catch (error) {
      console.log(error?.response?.data?.Message);
      setProductBOQData([]);
    }
  };
  const handleinvProductBoqList = async (id) => {
    try {
      const response = await getProductBoqListView(branch);
      console.log(response)
      if (Array.isArray(response)) {
        const boq = response?.find((e)=> e.product.guid ==id )
        console.log(boq)
        setProductboq(boq)
        
      } else {
        setProductboq([]);
      }
    } catch (error) {
      console.log(error?.response?.data?.Message);
      setProductboq([]);
    }
  };
  
   
  const handleSelectProduct=(id)=>{
    if(productId==id){
      setProductId()
    }else{
      handleinvProductBoqList(id)
      setProductId(id)
    }
   }  
 
   const addProductBOQ=()=>{
       
     const selected = ProductBOQData?.find(e=>e.guid==productId)
     setselectedProduct(selected)
   }
  const columns = [
    {
      title: "",
      dataIndex: "",
      render: (_, record) => (
        <div key={record.id}>
          <input type="checkbox" onChange={()=>handleSelectProduct(record.guid)} checked={record.guid==productId} />
        </div>
      )
    },
    {
      title: "Code",
      dataIndex: "code",
      sorter: (a, b) => a.code?.length - b.code?.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name?.length - b.name?.length,
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      sorter: (a, b) => a.shortName?.length - b.shortName?.length,
    },
   
    {
    title: "Description",
    dataIndex: "otherDescription",
    sorter: (a, b) => a.otherDescription?.length - b.otherDescription?.length,
    },
  
  ];

  const getActiveTabData = () => {
    let fieldMapping = {};
  
    // Function to create the field mapping between column titles and dataIndex values
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map title to dataIndex
      });
      return mappings;
    };
  
    // Generate the field mapping for the current columns
    fieldMapping = createfieldMapping(columns);
         
  
    // Return the filtered data, columns, and field mapping
    return { data: ProductBOQData, columns: columns, fieldMapping };
  };
  return (
    <div>
        <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Product BOQ</h4>
              </div>
            </div>
            <ul className="table-top-head">
            <li>
              <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link onClick={() => exportreportPDF(getActiveTabData)} >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link onClick={() => exportreportExcel(getActiveTabData)}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="excel-icon"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link onClick={() => printreportReport(getActiveTabData)}>
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip('Refresh')}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleProductBoqList}>
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip('Collapse')}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <ul className="table-top-head">

            </ul>
            {permit?.add &&
           <div className="d-flex purchase-pg-btn">
              <div className="page-btn">
                <button
                disabled={!productId}
                  to="#"
                  className="btn btn-added"
                  data-bs-toggle="modal"
                  data-bs-target="#add-boq"
                  onClick={addProductBOQ}
                  
                >
                  BOQ
                </button>
              </div>
            </div>
            }
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
             
              {/* /Filter */}
              
              {/* /Filter */}
              <div className="table-responsive product-list">
                <Table columns={columns} dataSource={ProductBOQData} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <Pagination />
      <ProductBOQmodal data={selectedProduct} productboq={Productboq} />
      
    
    </div>
  )
}

export default Productboq