import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AuthenticateUser } from "../../services/CoreApiServices";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyDetail} from "../../redux/companyDetail";
import { fetchMenuServices } from "../../redux/menuServices";
import { fetchBranchByUser } from "../../redux/branchByUser";
import { fetchUserServices } from "../../redux/userServices";
import { useNavigate } from "react-router-dom";
import logo from '../../core/img/dine-out-logo.png'
import { setuserId } from "../../redux/userdetails";
import { setBranch, setBranchId, setBranchName, setdefaultBranch } from "../../redux/branchlist";
import User from "../../core/json/User";

const SignIn = () => {
  useEffect(() => {
    sessionStorage.setItem("signin", "false");
  }, [])
  
    const {
        register,
        formState: { errors },
        handleSubmit,
      } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
      });
      const [isPasswordVisible, setPasswordVisible] = useState(false);
      const {BranchByUser} = useSelector((state) => state.userBranch);

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const togglePasswordVisibility = () => {
        setPasswordVisible((prevState) => !prevState);
      };
      const handleSetFlag = () => {
        sessionStorage.setItem("signin", "true");
      };
      const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
      const handleLogin = async (userData) => {
        const objUser = new User();
        objUser.UserName = userData.UserName;
        objUser.Password = userData.Password;
      
        try {
          // Call AuthenticateUser API
          const response = await AuthenticateUser(objUser);
          console.log('AuthenticateUser response:', response);
      
          if (response) {
            const userId = response;
      
            // Validate userId is in UUID format
            if (uuidRegex.test(userId)) {
              dispatch(setuserId({ id: userId }));
              toast.success("Login Success");
      
              // Set flag (if necessary)
              handleSetFlag();
      
              // Fetch branches by user ID and then call handleBranch after dispatch is resolved
              await dispatch(fetchBranchByUser({ id: userId }))
             
      
              // Navigate to the dashboard after setting branch
              navigate('/Home/BranchDashboard');
      
              // Fetch other necessary services/data for the user
              dispatch(fetchMenuServices({ id: userId }));
              dispatch(fetchCompanyDetail());
              dispatch(fetchUserServices({ id: userId }));
            } else {
              toast.error("Invalid Username or Password");
            }
          } else {
            toast.error("Authentication failed");
          }
        } catch (error) {
          console.log('Error:', error);
          toast.error(error?.message || "An unexpected error occurred");
        }
      };
      console.log(BranchByUser);
      useEffect(() => {
      
        
        if (BranchByUser && BranchByUser?.length > 0) {
          const defaultBranch = BranchByUser?.find(e => e.isDefaultBranch === true);
          const newbranch = defaultBranch ? defaultBranch.branch : BranchByUser[0].branch;
      
          if (newbranch) {
            // Dispatch the selected branch details to Redux store
            console.log(newbranch);
            dispatch(setBranch(newbranch));
            dispatch(setBranchId({ guid: newbranch.guid }));
            dispatch(setBranchName({ name: newbranch.name }));
            dispatch(setdefaultBranch(defaultBranch));
          } else {
            toast.error("Unable to set branch. No branch found.");
          }
        }
      }, [BranchByUser])
      
    
      
      
   
      return (
        <div className="main-wrapper">
          <div className="account-content">
            <div className="login-wrapper bg-img">
              <div className="login-content">
                <div className="login-userset">
                  <div className="login-logo logo-normal">
                    <img
                      src={logo}                 
                      alt="img"
                    />
                  </div>
    
                  <div className="login-userheading">
                    <h3>Sign In</h3>
                  </div>
                  <form onSubmit={handleSubmit(handleLogin)}>
                    <div className="form-login mb-3">
                      <label className="form-label">User Name</label>
                      <div className="form-addons">
                        <input
                          type="text"
                          className="form-control"
                          {...register("UserName", { required: true })}
                        />
                      </div>
                      {errors.UserName?.type === "required" && (
                        <span className="error text-danger">User name is required</span>
                      )}
                    </div>
                    <div className="form-login mb-3">
                      <label className="form-label">Password</label>
                      <div className="pass-group">
                        <input
                          type={isPasswordVisible ? "text" : "password"}
                          className="pass-input form-control"
                          {...register("Password", { required: true })}
                        />
                        <span
                          className={`fas toggle-password ${
                            isPasswordVisible ? "fa-eye" : "fa-eye-slash"
                          }`}
                          onClick={togglePasswordVisibility}
                        ></span>
                      </div>
                      {errors.Password?.type === "required" && (
                        <span className="error text-danger">Password is required</span>
                      )}
                    </div>
    
                    <div className="form-login">
                      <button type="submit" className="btn btn-login">
                        Sign In
                      </button>
                    </div>
                  </form>
    
                  <div className="form-sociallink">
                    <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                      <p>
                        Copyright © {new Date().getFullYear()} Axobis. All rights
                        reserved
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default SignIn
