import { DomainBase } from "./DomainBase";
export class Company extends DomainBase{
    constructor() {
        super();
        this.Name = '';
        this.CompanyType = null;
        this.FullName = '';
        this.Address1 = '';
        this.Address2 = '';
        this.Address3 = '';
        this.Phone = '';
        this.Email = '';
        this.Website = '';
        this.TIN = '';
        this.CST = '';
        this.LocationName = '';
        this.IsMultiBranchEnabled = false;
    }

}