import { DomainBase } from "./DomainBase";
 
export  class ProductClass extends DomainBase{
    constructor() {
        super();
        this.name = '';
        this.shortName = '';
        this.code = '';
        this.barcode = '';
        this.image = '';
        this.isRateVaryProduct = false;
        this.arabicDescription = '';
        this.isStockItem = false;
        this.colorID = '';
        this.vegTypeNo = 0;
        this.otherDescription = '';
        this.loyalty = 0;
        this.cost = 0;
        this.cessPercentage = 0;
        this.isActive = false;
        this.category = null;
        this.branch = null;
        this.group = null;
        this.type = null;
        this.unit = null;
        this.secondCategory = null;
        this.erpProduct = null;
        this.sectionWiseProductDetailList = [];
        this.comboProductDetail = null;
        this.isDailyStockItem = false;
        this.basePrice = 0;
        this.locationIsActive = false;
        this.department = null;
        this.hsnCode = '';
        this.isMenuItem = false;
        this.productionQuantity = 0;
    }
}