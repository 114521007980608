import { createSlice } from "@reduxjs/toolkit";
export const toggleslice = createSlice({
    name: "product",
    initialState: {

        togglehead:false,
       
    },
    reducers: {
        setheadertoggle: (state, action) => {
            state.togglehead = action.payload;
           
        },
       

    },
});

export const {
    setheadertoggle,
} =
    toggleslice.actions;
export default toggleslice.reducer;
