import { DomainBase } from "./DomainBase";

export class InvProduction extends DomainBase {
    constructor() {
        super();
        this.Date = new Date();
        this.Quanitity = 0;
        this.Product = null;
        this.Branch = null;
        this.Cost = 0;
        this.Unit = null;
        this.Entryno = null;
        this.invProductionDetails = [];
    }
}