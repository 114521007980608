
import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  Download,
  File,
  Filter,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  User,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../../core/pagination/datatable";
import { useSelector } from "react-redux";
import { getSalesByTransType } from "../../../services/SalesApiServices";
import { saveAs } from 'file-saver'; // for saving files
import * as XLSX from 'xlsx'; // for excel export
import jsPDF from 'jspdf'; // for pdf generation
import moment from "moment";

const SalesItemWise = ({Itemwisecolumns,itemwiseData}) => {
  const [searchText, setSearchText] = useState("");
  const [salesData, setSalesData] = useState([]);
  const { branch } = useSelector((state) => state.branchlist);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    handlesalesData();
  }, [branch]);

  const handlesalesData = async () => {
    try {
      const response = await getSalesByTransType(branch, 0);
      setSalesData(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error(
        "Failed to fetch sales invoices",
        error?.response?.data?.Message || "Something went wrong"
      );
    }
  };

 



  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  

  return (
    <>
    
      <div id="sales-table">
        <Table columns={Itemwisecolumns} dataSource={itemwiseData} />
      </div>
      {itemwiseData?.length === 0 && <p>No sales data available.</p>}
    </>
  );
};
export default SalesItemWise;
