import React, { useEffect, useRef, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Link } from "react-router-dom"; // Assuming you're using react-router
import { addSectionSettings, addSectionTable, getSectionTable } from "../../services/MasterApiServices";
import { TableAssignment } from '../../core/json/TableAssignment'
import { Table } from '../../core/json/Table'
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { SectionClass } from "../../core/json/SectionClass";
const SectionAsignTable = ({ data }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { fields, append, remove, } = useFieldArray({
    control,
    name: "tables",
  });
  useEffect(() => {
    if(data){
      handleSectionTable()
    }
  }, [data])
  
  const handleSectionTable = async () => {
    const objsection = new TableAssignment();
    const objsectionclass = new SectionClass();
    const objtable = new Table()
    objsectionclass.Branch = branch;
    objsectionclass.guid = data.guid;
    objsectionclass.name = data.name;
    objsectionclass.id = data.id;
    objsection.Section = objsectionclass;
    objsection.Tables = objtable;
  
    try {
  
      const response = await getSectionTable(objsection)
      console.log(response,"aaaaaaaaaaaaaaaaaaaa");
      
     
      // setValue('branch',response.branch.guid)
      // setValue('enableKOTPrinting',response.EnableKOTPrinting)
      // setValue('printKOTInSalesFormat',response.PrintKOTInSalesBillFormat)
      // setValue('printKOTInSalesBillPrinter',response.PrintKOTInSalesBillPrinter)
      // setValue('printBothSalesAndKOT',response.PrintBothSalesAndKOTPrint)
      // setValue('showpopuptocapturecustomerdetail',response.ShowPopupToCaptureCustomerDetail)
      // setValue('showpopuptoselectwaiter',response.ShowPopupToSelectWaiter)
      // setValue('showpopuptoselecttable',response.ShowPopupToSelectTable)
      // setValue('printfilename',response.PrintFileName)
      // setValue('numberOfInvoicePrints',response? response.NumberOfInvoicePrints : 0)
      // setValue('billstartnumber',response.BillStartNumber || 0)
      // setValue('invoiceprefix',response.InvoicePrefix) 
      // setValue('tax',response.tax.guid) 
      
    }
    catch (error) {
      console.error("Error handling category:", error?.response?.data?.Message);
    }
  }
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const [tableName, setTableName] = useState(""); // State to store table name
  const [capacity, setCapacity] = useState("");   // State to store capacity

  // Handle form submission
  const handleFormSubmit = async (formData) => {

    const objsection = new TableAssignment();
    const objsectionclass = new SectionClass();
    objsectionclass.Branch = branch;
    objsectionclass.guid = data.guid;
    objsectionclass.name = data.name;
    objsectionclass.id = data.id;
    objsection.Section = objsectionclass;

    objsection.createdUser = `{${userId?.id}}`;
    objsection.updatedUser = `{${userId?.id}}`;
    objsection.branch = branch;
    // objsection.guid= data?.guid ;
    // objsection.id= data?.id;
    for (const row of formData.tables) {
      const objtable = new Table()
      objtable.Name = row.name;
      objtable.Capacity = row.capacity;
      objtable.branch = branch;
      objsection.Tables.push(objtable);
    }
    try {
      console.log(formData);
      const response = await addSectionTable(objsection);
      if (response == true) {

        toast.success("success")
        closemodal()
      }
      else if(response== false){
        toast.error("false")
        console.log(response);
      } // Add logic for form submission here
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Form submission error:", error);
    }
  };

  // Add a new table row
  const handleAddSection = () => {
    if (tableName && capacity) {
      append({ name: tableName, capacity }); // Add the new table with name and capacity
      setTableName(""); // Clear the input after adding
      setCapacity("");  // Clear the input after adding
    }
  };

  const closeButtonRef = useRef();

  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();

    }
  };
  const resetmodal = () => {
   
  };
  console.log(fields);
  
  return (
    <div>
      <div
        className="modal fade"
        id="sectionasign"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>{data?.name || "Default Section"} Section Tables</h4> {/* Safe access of data.name */}
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeButtonRef}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-lg-5 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          {/* <label>
                            Table Name
                            <span className="text-danger">*</span>
                          </label> */}
                          <input
                            type="text"
                            placeholder="Table Name"
                            value={tableName}

                            onChange={(e) => setTableName(e.target.value)} // Update the local state

                          />
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          {/* <label>
                            Capacity
                            <span className="text-danger">*</span>
                          </label> */}
                          <input
                            type="number"
                            placeholder="Capacity"
                            className="form-control p-3"
                            value={capacity}
                            onChange={(e) => setCapacity(e.target.value)} // Update the local state

                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-6 col-sm-12">

                    <button
                      className="btn btn-success"
                      onClick={handleAddSection}
                      type="button"
                    >
                      Add
                    </button>
                      </div>
                    </div>
                    <div
                      className="row p-2"
                      style={{
                        maxHeight: "300px",
                        overflowY: "scroll",
                        position: "relative",
                      }}
                    >
                      <div className="row">
                        <div className="col-4">Name<span className="text-danger">*</span></div>
                        <div className="col-4">Capacity<span className="text-danger">*</span></div>
                        <div className="col-4">Action</div>
                      </div>
                      {fields.map((field, index) => (
                        <React.Fragment key={field.id}>
                          <hr />
                          <div className="row">
                            <div className="col-4">
                              <input
                                type="text"
                                className="form-control"
                                {...register(`tables.${index}.name`, { required: true })}
                                defaultValue={field.name} // Display added table name
                                readOnly
                              />
                            </div>
                            <div className="col-4">
                              <input
                                type="text"
                                className="form-control"
                                {...register(`tables.${index}.capacity`, { required: true })}
                                defaultValue={field.capacity} // Display added capacity
                                readOnly
                              />
                            </div>
                            <div className="col-4 promo-delete">
                              <div className="edit-delete-action">
                                <Link
                                  className="confirm-text p-2"
                                  to="#"
                                  onClick={() => remove(index)}
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  ></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                 
                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={resetmodal}
                         
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-submit" disabled={fields?.length==0} >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionAsignTable;

