import { createSlice } from "@reduxjs/toolkit";
export const headerslice = createSlice({
    name: "user",
    initialState: {

        togglehead:false,
      
    },
    reducers: {
        setToogleHeader: (state, action) => {
           alert("aaaaa")
            state.togglehead = action.payload;
           
        },

    },
});

export const {setToogleHeader} = headerslice.actions;
export default headerslice.reducer;
