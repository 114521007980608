import React, { useState, useEffect, useRef } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import Select from "react-select";
import { Calendar } from "feather-icons-react/build/IconComponents";
import {
  getTaxList,
  getUnitList,
  getCustomersList,
  getDiscountList,
} from "../../../services/MasterApiServices";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import { getProductList } from "../../../services/MasterApiServices";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";
import { InvSales } from "../../../core/json/invSales";
import { InvSalesDetail } from "../../../core/json/invSalesDetail";
import { useDispatch } from "react-redux";
import {
  fetchUnit,
  fetchProduct,
  fetchTax,
  fetchCategory,
  fetchCustomer,
  fetchSettings,
} from "../../../redux/userSetting";
import {
  addEditSales,
  getSalesByTransType,
} from "../../../services/SalesApiServices";

import { ProductClass } from "../../../core/json/Product";
import { DiscountClass } from "../../../core/json/Discount";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import { TaxClass } from "../../../core/json/TaxClass";
import toast from "react-hot-toast";
import CustomerModal from "../../master/modal/CustomerModal";

const SalesInvoiceModal = ({
  mode,
  data,
  handleClose,
  handleRefresh,
  // modalOpen,
}) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      deliveryDate: dayjs().format("YYYY-MM-DD"),
      invdate: dayjs().format("YYYY-MM-DD"),
      paymentType: 0,
      referencetype: 0,
      salesMode: 0,
      addition: 0,
      change: 0,
      customerContactno: "",
      customerName: "",
      refNo: "0",
      subtraction: 0,
      round: 0,
    },
    mode: "onBlur",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });
  const handleAddRow = () => {
    append({
      batch: "0",
      code: "0",
      discountAmount: "0.0",
      discountPercentage: "0.0",
      expiry: dayjs().format("YYYY-MM-DD"),
      foc: "0.0",
      gross: "0.0",
      hsnCode: 0,
      mrp: "0.0",
      netTotal: "0.0",
      price: "0.0",
      quantity: "0",
      taxAmount: "0.0",
      taxPercentage: "0.0",
      unit: "",
      product: null,
    });
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUnit());
    dispatch(fetchProduct(branch));
    dispatch(fetchTax());
    dispatch(fetchSettings(branch));
    const match = settingList?.currencyFormat?.toString()?.match(/\.0+/);
    const currfmt = match ? match[0]?.length - 1 : 0;
    setDecimalPoints(currfmt);
    dispatch(fetchCustomer(branch));
  }, []);

  const { customerList } = useSelector((state) => state.usersetting);

  const [discountList, setdiscountList] = useState([]);

  const [taxData, setTaxData] = useState([]);

  const [unitData, setunitData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [salesData, setsalesData] = useState([]);
  const [discountData, setdiscountData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isRowAdded, setIsRowAdded] = useState(false);
  const [decimalpoints, setDecimalPoints] = useState(2);
  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);
  // Get branch from redux
  const { branch } = useSelector((state) => state.branchlist);

  const { productList, unitList, taxList, settingList } = useSelector(
    (state) => state.usersetting
  );

  const paymentType = [
    { value: 0, label: "Cash" },
    { value: 1, label: "Credit" },
    { value: 2, label: "UPI" },
    { value: 3, label: "Card" },
  ];
  //   like reference type in purchase//transtype
  const referencetype = [
    { value: 0, label: "DIRECT" },
    { value: 1, label: "DN" },
    { value: 2, label: "ORDER" },
  ];

  const salesMode = [
    { value: 0, label: "NORMAL SERIES" },
    { value: 1, label: "QUOT SERIES" },
  ];
  useEffect(() => {}, [getValues, errors]);
  // const purchaseTransType = [
  //   { value: 0, label: "Sales Quotation" },
  //   { value: 1, label: "Delievery Note" },
  //   { value: 2, label: "Sales Invoice" },
  //   { value: 3, label: "Sales Return" },
  // ];

  //Calculations ---------------
  const safeNumber = (value, defaultValue = 0) => {
    // Check for null, undefined, or empty string and use the default value
    if (value === null || value === undefined || value === "") {
      return defaultValue;
    }

    // Convert to number
    const num = Number(value);

    // Return the number or the default value if num is NaN
    return isNaN(num) ? defaultValue : num;
  };
  const rowUseForm = getValues("row") || [];

  let totalGross = 0;
  let totalDiscount = 0;
  let totalTax = 0;
  let totalNet = 0;
  let changevalue = 0;

  fields.forEach((field, index) => {
    const quantity = watch(`items[${index}].quantity`, 0);
    const price = watch(`items[${index}].price`, 0);
    const discount = watch(`items[${index}].discountPercentage`, 0);
    const tax = watch(`items[${index}].taxPercentage`, 0);

    const grossTl = price * quantity || 0;
    const discountAmt = (grossTl * discount) / 100 || 0;
    const amountBeforeTax = grossTl - discountAmt || 0;
    const taxAmount = (amountBeforeTax * tax) / 100 || 0;
    const netTotal = amountBeforeTax + taxAmount || 0;

    setValue(`items[${index}].gross`, grossTl?.toFixed(decimalpoints));
    setValue(
      `items[${index}].discountAmount`,
      discountAmt?.toFixed(decimalpoints)
    );
    setValue(`items[${index}].taxAmount`, taxAmount?.toFixed(decimalpoints));
    setValue(`items[${index}].netTotal`, netTotal?.toFixed(decimalpoints));

    // Add to totalGross
    totalGross += grossTl;
    totalDiscount += discountAmt;
    totalTax += taxAmount;
    totalNet += netTotal;
  });
  //Final calculations on addition ,discount and round
  const addition = Number(watch(`addition`, 0));
  const subtraction = Number(watch(`subtraction`, 0));
  const round = Number(watch(`round`, 0));

  totalNet += safeNumber(addition);
  totalNet -= safeNumber(subtraction);
  totalNet -= safeNumber(round);
  totalDiscount = totalDiscount + safeNumber(subtraction);
  const paidvalue = Number(watch(`paid`), 0);
  if (paidvalue !== 0) {
    changevalue = Math.abs(totalNet - safeNumber(paidvalue));
    setValue("change", changevalue?.toFixed(decimalpoints));
  } else {
    setValue("change", 0);
  }
  //end-------------
  const handleDiscountList = async () => {
    try {
      const response = await getDiscountList();

      const formattedDiscount = response?.map((discount) => ({
        label: discount.percentage,
        value: discount.percentage,
        guid: discount.guid,
      }));
      setdiscountData(formattedDiscount);
      setdiscountList(response);
    } catch (error) {
      console.error(
        "Error fetching discount",
        error?.response?.data?.Message || "Unexpected error occured"
      );
    }
  };
  const handleUnitList = async () => {
    try {
      const response = await getUnitList();
      const formattedUnit = response?.map((unit) => ({
        label: unit.name,
        value: unit.guid,
      }));
      setunitData(formattedUnit);
    } catch (error) {
      console.error(
        "Error fetching",
        error?.response?.data?.Message || "Unexpected error occured"
      );
    }
  };

  const handleTaxList = async () => {
    try {
      const response = await getTaxList();

      const formattedTax = response?.map((tax) => ({
        label: tax.percentage,
        value: tax.percentage,
        guid: tax.guid,
      }));
      setTaxData(formattedTax);
    } catch (error) {
      console.error(
        "Error fetching tax",
        error?.response?.data?.Message || "Unexpected error occured"
      );
    }
  };

  const handleEachProductfetch = async (selectedOption, index) => {
    setValue(`items[${index}].product`, selectedOption.value);

    try {
      const productData = await getSingleProduct(selectedOption.value);

      setValue(`items[${index}].code`, productData?.code || 0);
      setValue(`items[${index}].hsnCode`, productData?.hsnCode || 0);
      setValue(`items[${index}].unit`, productData?.unit.guid || 0);
    } catch (error) {
      console.error(
        "Error fetching product by GUID:",
        error?.response?.data?.Message || "Unexpected error occured"
      );
    }
  };

  async function getSingleProduct(guid) {
    try {
      const filteredData = productList?.filter(
        (product) => product.guid === guid
      );
      return filteredData[0];
    } catch (error) {
      console.error(
        "Error fetching products",
        error?.response?.data?.Message || "Unexpected error occured"
      );
    }
  }

  const handleCustomerList = async () => {
    try {
      const formattedCustomers = customerList?.map((customer) => ({
        label: customer.name,
        value: customer.guid,
      }));
      setCustomerData(formattedCustomers);
    } catch (error) {
      console.error(
        "Error fetching customers",
        error?.response?.data?.Message || "Unexpected error occured"
      );
    }
  };

  const handlesalesData = async () => {
    try {
      const response = await getSalesByTransType(branch, 2);
      setsalesData(response.data);
    } catch (error) {
      console.error("Failed to fetch sales invoices", error);
    }
  };
  const handlecustomerDetailFetch = async (selectedOption) => {
    try {
      const customerData = customerList?.find(
        (e) => e.guid == selectedOption.value
      );

      setValue("customerContactno", customerData?.mobile || 0);
      setValue("customerName", customerData?.name || "");
    } catch (error) {
      console.error("Error fetching product by GUID:", error);
    }
  };

  // Fetch sales invoices data
  const handleEntryNo = async () => {
    try {
      const response = await getSalesByTransType(branch, 2);
      const salesData = response.data;

      // Group entry numbers by prefix
      const entryGroups = salesData.reduce((groups, data) => {
        const entrynumber = data.entrynumber;
        // Extract the prefix (non-numeric characters)
        const prefix = entrynumber.match(/^[A-Za-z]+/)[0];
        // Extract the numeric part and convert it to an integer
        const entryNum = parseInt(entrynumber.replace(/\D/g, ""), 10);

        if (!groups[prefix]) {
          groups[prefix] = [];
        }
        groups[prefix].push(entryNum);

        return groups;
      }, {});

      // Get the desired prefix (e.g., "SQ")
      const prefix = "SI";
      const maxEntryNumber =
        entryGroups[prefix]?.length > 0 ? Math.max(...entryGroups[prefix]) : 0;

      // Increment the counter for the prefix
      const counter = maxEntryNumber + 1;

      // Set the new entry number with the updated counter
      setValue(`entrynumber`, `${prefix}${counter}`);
    } catch (error) {
      console.error(
        "Failed to fetch sales invoices",
        error?.response?.data?.Message || "Unexpected error occured"
      );
    }
  };

  function resetmodal() {
    if (mode === "edit" && data) {
      setValue("terms", data.terms);
      setValue("entrynumber", data.entrynumber);
      setValue("salesMode", data.salesMode || 0);
      setValue("salesTransType", data.salesTransType || 2);
      setValue("paid", data.paid || 0);
      setValue("change", Number(data.change) || 0);
      setValue("refNo", data.refNo || 0);
      setValue("dispatch", data.dispatch || "");
      setValue("dispatchTrough", data.dispatchTrough || "");
      setValue("addition", data.additionalAmt || 0);
      setValue("subtraction", data.discAmt || 0);
      setValue("round", data.roundOff || 0);
      setValue("grossTotal", data.grossTotal || 0);
      setValue("discountTotal", data.discountTotal || 0);
      setValue("taxTotal", data.taxTotal || 0);
      setValue("netAmt", data.netAmt || 0);
      setValue("paymentType", data.salesPaymentType || 0);
      setValue("referencetype", data.reftype || 0);
      setValue("customer", data.customer.guid);
      setValue("customerContactno", data.customerContactno || "0");
      setValue("customerName", data.customerName || "");
      setValue("invdate", dayjs(data.invdate) || dayjs());
      setValue("deliveryDate", dayjs(data.deliveryDate) || dayjs());

      setValue(
        "items",
        data?.invSalesDetails?.map((detail, i) => {
          console.log("------------product?.guid", detail?.product?.guid);
          console.log("------------product?.guid", detail?.product?.code);
          console.log("------------product?.guid", detail?.product?.guid);
          return {
            product: detail?.product?.guid,
            code: detail?.product?.code,
            hsnCode: detail?.product?.hsnCode || 0,
            batch: detail.batch,
            expiry: dayjs(detail.expiryDate),
            unit: detail.unit?.guid,
            quantity: detail.quantity,
            price: detail.price,
            mrp: detail.mrp,
            foc: detail.foc,
            discountPercentage: detail.discPercentage,
            discountAmount: detail.discAmt,
            taxPercentage: detail.taxPercentage,
            gross: detail.gross,
            taxAmount: detail.taxamnt,
            netTotal: detail.netAmt,
          };
        })
      );
    }
  }

  useEffect(() => {
    resetmodal();
  }, [data, setValue]);

  useEffect(() => {
    handleCustomerList();
  }, [customerList]);

  useEffect(() => {
    const fetchData = async () => {
      if (mode === "add") {
        handleEntryNo();
        if (!isRowAdded && fields?.length === 0) {
          handleAddRow();
          setIsRowAdded(true);
        }
      }
      handleProductsList();

      handleTaxList();
      handleUnitList();
      handlesalesData();
      handleDiscountList();
    };

    fetchData();
  }, []);

  const [products, setProducts] = useState([]);

  const handleProductsList = async () => {
    try {
      const formattedProducts = productList?.map((product) => ({
        label: product.name,
        value: product.guid,
      }));
      setProducts(formattedProducts);
    } catch (error) {
      console.error(
        "Error fetching products",
        error?.response?.data?.Message || "Unexpected error occured"
      );
    }
  };
  async function getCustomerByGuid(guid) {
    const response = await getCustomersList(branch);
    const filteredData = response.data?.filter(
      (customer) => customer.guid === guid
    );
    return filteredData[0];
  }

  async function getUnitByGuid(guid) {
    const unitData = await getUnitList();
    const filteredData = unitData?.filter((unit) => unit.guid === guid);
    return filteredData[0];
  }

  async function getTaxByGuid(guid) {
    const taxData = await getTaxList();
    const filteredData = taxData?.filter((tax) => tax.guid === guid);
    return filteredData[0];
  }

  async function getProductByGuid(guid) {
    const productData = await getProductList(branch);

    const filteredData = productData?.filter(
      (product) => product.guid === guid
    );

    return filteredData[0];
  }

  // const handleFormSubmit = async (formdata) => {
  //   console.log(getValues().row);
  // };
  const handleFormSubmit = async (formdata) => {
    const objsales = new InvSales();
    if (mode === "edit") {
      objsales.guid = data?.guid;
      objsales.id = data?.id;
    }
    objsales.entrynumber = formdata.entrynumber || 0;
    objsales.branch = branch;
    // objsales.Entrynumber = formdata.invoiceNo || "";
    objsales.invdate = dayjs(formdata.invdate)?.format("YYYY-MM-DDTHH:mm:ss");
    objsales.deliveryDate = dayjs(formdata.deliveryDate)?.format(
      "YYYY-MM-DDTHH:mm:ss"
    );
    objsales.salesPaymentType = formdata.paymentType;
    objsales.salesTransType = 2;
    objsales.reftype = formdata.referencetype || 0;
    objsales.refNo = formdata.refNo || "";
    const customerData = customerList?.find((e) => e.guid == formdata.customer);
    objsales.updatedUser = `${userId?.id}`;
    objsales.createdUser = `${userId?.id}`;
    objsales.customer = customerData;
    objsales.currency = "00000000-0000-0000-0000-000000000000";

    objsales.dispatch = formdata.dispatch || "";
    objsales.dispatchTrough = formdata.dispatchTrough || "";
    objsales.salesMode = formdata.salesMode || 0;
    objsales.paid = Number(formdata.paid) || 0;
    objsales.change = Number(formdata.change) || 0.0;
    objsales.additionalAmt = Number(formdata.addition) || 0.0;
    objsales.discAmt = Number(formdata.subtraction) || 0;
    objsales.roundOff = Number(formdata.round) || 0;
    objsales.grossTotal = totalGross || 0.0;
    objsales.discountTotal = totalDiscount || 0.0;
    objsales.taxTotal = totalTax || 0.0;
    objsales.netTotal = totalNet || 0.0;
    objsales.customerName = formdata.customerName || "";
    objsales.customerContactno = formdata.customerContactno || "";
    objsales.terms = formdata.terms || "";

    if (formdata.paymentType === 0) {
      objsales.cash = totalNet || 0;
    } else if (formdata.paymentType === 1) {
      objsales.bank = totalNet || 0;
    } else if (formdata.paymentType === 2) {
      objsales.upi = totalNet || 0;
    } else if (formdata.paymentType === 3) {
      objsales.card = totalNet || 0;
    }
    const detailObj = formdata?.items?.map((e) => {
      const defaultDiscountobj = new DiscountClass();
      const defaultTaxobj = new TaxClass();

      let product;
      const productValue = e.product || e.product.value;

      product = productList?.find((item) => item.guid == productValue);
      const unit = unitList.find((item) => item.guid == e.unit);

      const expiry = e.expiry
        ? dayjs(e.expiry).format("YYYY-MM-DDTHH:mm:ss")
        : dayjs().format("YYYY-MM-DDTHH:mm:ss");

      const detailObj = {
        product,
        unit,
        batch: e.batch,
        expiryDate: expiry,
        quantity: e.quantity,
        price: e.price,
        mrp: e.mrp,
        foc: e.foc,
        taxamnt: e.taxAmount,
        grossAmount: e.gross,
        netAmt: e.netTotal,
        discAmt: e.discountAmount,
        discPercentage: e.discountPercentage,
        taxPercentage: e.taxPercentage,
      };

      return detailObj;
    });
    objsales.invSalesDetails = detailObj;
    try {
      const response = await addEditSales(objsales);
      if (response) {
        toast.success("Success");
        handleRefresh(); // Refresh the list
        handleClose(); // Close the modal
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message || "Unexpected error occured");
      console.error(
        "Error handling :",
        error?.response?.data?.Message || "Unexpected error occured"
      );
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog add-centered">
          <div className="modal-content">
            <div className="page-wrapper p-0 m-0">
              <div className="content p-0">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {" "}
                      {mode === "edit"
                        ? "Edit sales Invoice"
                        : "Add sales Invoice"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                      <div className="row">
                        {/* row1 */}
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Entry No.</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("entrynumber")}
                              required
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Date</label>
                            <div className="input-groupicon calender-input">
                              <Calendar className="info-img" />
                              <Controller
                                control={control}
                                name="invdate"
                                render={({ field: { onChange, value } }) => (
                                  <DatePicker
                                    className="datetimepicker"
                                    value={value ? dayjs(value) : dayjs()} // Set value or default to today
                                    format="DD-MM-YYYY"
                                    onChange={(date) =>
                                      onChange(
                                        date ? date.format("YYYY-MM-DD") : null
                                      )
                                    } // Update on change
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Delivery Date</label>
                            <div className="input-groupicon calender-input">
                              <Calendar className="info-img" />
                              <Controller
                                control={control}
                                name="deliveryDate"
                                render={({ field: { onChange, value } }) => (
                                  <DatePicker
                                    className="datetimepicker"
                                    value={value ? dayjs(value) : dayjs()}
                                    format="DD-MM-YYYY"
                                    onChange={(date) =>
                                      onChange(
                                        date ? date.format("YYYY-MM-DD") : null
                                      )
                                    } // Update on change
                                  />
                                )}
                              />
                              {errors.deliveryDate && (
                                <span className="text-danger small mt-1">
                                  {errors.deliveryDate.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">
                              Payment Type<span className="text-danger">*</span>
                            </label>
                            <div className="row">
                              <Controller
                                control={control}
                                name={`paymentType`}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    classNamePrefix="react-select"
                                    options={paymentType}
                                    value={paymentType?.find(
                                      (paymentType) =>
                                        paymentType?.value === value
                                    )}
                                    onChange={(selectedOption) =>
                                      onChange(selectedOption.value)
                                    }
                                    styles={{
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    required
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        {/* row 2 */}
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">
                              Reference Type
                              <span className="text-danger">*</span>
                            </label>
                            <div className="row">
                              <Controller
                                control={control}
                                name={`referencetype`}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    classNamePrefix="react-select"
                                    options={referencetype}
                                    value={referencetype?.find(
                                      (reference) => reference?.value === value
                                    )}
                                    onChange={(selectedOption) =>
                                      onChange(selectedOption.value)
                                    }
                                    styles={{
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    required
                                  />
                                )}
                              />
                              <div className="col-lg-2 col-sm-2 col-2 ps-0"></div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Reference No.</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("refNo")}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Sales Mode</label>
                            <div className="row">
                              <Controller
                                control={control}
                                name={`salesMode`}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    classNamePrefix="react-select"
                                    options={salesMode}
                                    value={salesMode?.find(
                                      (reference) => reference?.value === value
                                    )}
                                    onChange={(selectedOption) =>
                                      onChange(selectedOption.value)
                                    }
                                    styles={{
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    required
                                  />
                                )}
                              />
                              <div className="col-lg-2 col-sm-2 col-2 ps-0"></div>
                            </div>
                          </div>
                        </div>

                        {/* row 3 */}

                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Dispatch</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("dispatch")}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">
                              Dispatch Through
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("dispatchTrough")}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Remarks</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("terms")}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks add-product">
                            <label>
                              Customer<span className="text-danger">*</span>
                            </label>
                            <div className="row">
                              <div className="col-lg-10 col-sm-10 col-10">
                                <Controller
                                  control={control}
                                  name={`customer`}
                                  render={({ field: { onChange, value } }) => (
                                    <Select
                                      classNamePrefix="react-select"
                                      options={customerData}
                                      value={customerData?.find(
                                        (customer) => customer?.value === value
                                      )}
                                      onChange={(selectedOption) => {
                                        onChange(selectedOption.value);
                                        handlecustomerDetailFetch(
                                          selectedOption
                                        );
                                      }}
                                      styles={{
                                        menu: (provided) => ({
                                          ...provided,
                                          zIndex: 9999,
                                        }),
                                      }}
                                      required
                                    />
                                  )}
                                />
                              </div>
                              <div className="col-lg-2 col-sm-2 col-2 ps-0">
                                <div className="add-icon tab">
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setModalOpen(true);
                                    }}
                                  >
                                    <PlusCircle className="feather-plus-circles" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Contact Name</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("customerName")}
                            />
                            {errors.customerName && (
                              <span className="text-danger small mt-1">
                                {errors.customerName.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Contact Number</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("customerContactno")}
                            />
                            {errors.customerContactno && (
                              <span className="text-danger small mt-1">
                                {errors.customerContactno.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive  no-pagination table-container">
                        <table className="table tablezind">
                          <thead>
                            <tr>
                              <th className="sticky-header">
                                Description
                                <span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">Item Code</th>
                              <th className="sticky-header">HSN Code</th>
                              <th className="sticky-header">Unit</th>
                              <th className="sticky-header">Batch</th>
                              <th className="sticky-header">Exp Date</th>
                              <th className="sticky-header">
                                Quantity<span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">
                                Price <span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">MRP</th>
                              <th className="sticky-header">FOC</th>
                              <th className="sticky-header">Gross</th>
                              <th className="sticky-header">Discount %</th>
                              <th className="sticky-header">Discount Amount</th>
                              <th className="sticky-header">Tax %</th>
                              <th className="sticky-header">Tax Amount</th>
                              <th className="sticky-header">Net Amount</th>
                              <th className="sticky-header">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map((obj, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`items[${index}].product`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          classNamePrefix="react-select"
                                          options={products}
                                          value={products?.find(
                                            (option) => option.value === value
                                          )}
                                          onChange={(selectedOption) => {
                                            onChange(selectedOption.value);
                                            handleEachProductfetch(
                                              selectedOption,
                                              index
                                            );
                                          }}
                                          styles={{
                                            menu: (provided) => ({
                                              ...provided,
                                              zIndex: 9999,
                                              position: "relative",
                                            }),
                                          }}
                                          required
                                        />
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].code`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].hsnCode`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`items[${index}].unit`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          classNamePrefix="react-select"
                                          options={unitData}
                                          value={unitData?.find(
                                            (option) => option.value === value
                                          )}
                                          onChange={(selectedOption) => {
                                            onChange(selectedOption.value);
                                          }}
                                          isDisabled={true}
                                        />
                                      )}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].batch`)}
                                    />
                                  </td>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`items[${index}].expiry`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <DatePicker
                                          className="datetimepicker"
                                          placeholder={
                                            value ? dayjs(value) : dayjs()
                                          }
                                          value={value ? dayjs(value) : dayjs()}
                                          format="DD-MM-YYYY"
                                          onChange={(date) => onChange(date)}
                                        />
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].quantity`, {
                                        required: "Quantity is required",
                                        validate: (value) =>
                                          (!isNaN(value) && value > 0) ||
                                          "Quantity must be a positive number",
                                      })}
                                    />
                                    {errors.items &&
                                      errors.items[index]?.quantity && (
                                        <span className="text-danger small">
                                          {errors.items[index].quantity.message}
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].price`, {
                                        required: "Price is required",
                                        validate: (value) =>
                                          (!isNaN(value) && value > 0) ||
                                          "Price must be a positive number",
                                      })}
                                    />
                                    {errors.items &&
                                      errors.items[index]?.price && (
                                        <span className="text-danger small">
                                          {errors.items[index].price.message}
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].mrp`, {
                                        validate: (value) =>
                                          (!isNaN(value) && value >= 0) ||
                                          "MRP must be zero or more",
                                      })}
                                    />
                                    {errors.items &&
                                      errors.items[index]?.mrp && (
                                        <span className="text-danger small">
                                          {errors.items[index].mrp.message}
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].foc`, {
                                        validate: (value) =>
                                          (!isNaN(value) && value >= 0) ||
                                          "FOC must be zero or more",
                                      })}
                                    />
                                    {errors.items &&
                                      errors.items[index]?.foc && (
                                        <span className="text-danger small">
                                          {errors.items[index].foc.message}
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].gross`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`items[${index}].discountPercentage`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          classNamePrefix="react-select"
                                          options={discountData}
                                          value={discountData?.find(
                                            (option) => option.value === value
                                          )}
                                          onChange={(selectedOption) =>
                                            onChange(selectedOption.value)
                                          }
                                        />
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(
                                        `items[${index}].discountAmount`
                                      )}
                                      disabled
                                    />
                                  </td>

                                  <td>
                                    <Controller
                                      control={control}
                                      name={`items[${index}].taxPercentage`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          classNamePrefix="react-select"
                                          options={taxData}
                                          value={taxData?.find(
                                            (option) => option.value === value
                                          )}
                                          onChange={(selectedOption) => {
                                            onChange(selectedOption.value);
                                          }}
                                          styles={{
                                            menu: (provided) => ({
                                              ...provided,
                                              zIndex: 9999,
                                              position: "relative",
                                            }),
                                          }}
                                        />
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].taxAmount`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].netTotal`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <Link
                                      className="confirm-text p-2"
                                      onClick={() => remove(index)}
                                    >
                                      <i
                                        data-feather="trash-2"
                                        className="feather-trash-2"
                                      ></i>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-lg-2 col-sm-2 col-2 ps-0 ">
                        <button
                          className=" btn btn-dark add-icon tab  mt-3 "
                          type="button"
                          onClick={handleAddRow}
                        >
                          <PlusCircle className="feather-plus-circles" />
                        </button>
                      </div>
                      <div className="row mt-3">
                        <div className="col">
                          <div className="input-blocks">
                            <label className="form-label">Paid</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("paid")}
                              defaultValue={0}
                            />
                            {errors.paid && (
                              <span className="text-danger small mt-1">
                                {errors.paid.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col">
                          <div className="input-blocks">
                            <label className="form-label">Change</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("change")}
                              defaultValue={0}
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className=" col-4 ">
                          <div className="input-blocks">
                            <label>Addition</label>
                            <div className="input-groupicon select-code">
                              <input
                                type="text"
                                className="form-control p-2"
                                defaultValue={0}
                                {...register(`addition`)}
                              />
                              {errors.addition && (
                                <span className="text-danger small mt-1">
                                  {errors.addition.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className=" col-4 ">
                          <div className="input-blocks">
                            <label>Discount</label>
                            <div className="input-groupicon select-code">
                              <input
                                type="text"
                                className="form-control p-2"
                                defaultValue={0}
                                {...register(`subtraction`, {
                                  validate: (value) => {
                                    const discount = safeNumber(value);
                                    const totalNetAfterDiscount =
                                      totalNet - discount;
                                    if (discount < 0) {
                                      return "Discount value should not be negative.";
                                    } else if (totalNetAfterDiscount < 0) {
                                      return "Please enter a discount value that does not exceed the Net Total.";
                                    }
                                    return true;
                                  },
                                })}
                              />
                              {errors.subtraction && (
                                <span className="text-danger small mt-1">
                                  {errors.subtraction.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className=" col-4 ">
                          <div className="input-blocks">
                            <label>Round Off</label>
                            <div className="input-groupicon select-code">
                              <input
                                type="text"
                                className="form-control p-2"
                                defaultValue={0}
                                {...register(`round`, {
                                  validate: (value) => {
                                    const round = safeNumber(value);
                                    const totalNetAfterRound = totalNet - round;
                                    return (
                                      totalNetAfterRound >= 0 ||
                                      "Please enter a round-off value that does not exceed Net Total."
                                    );
                                  },
                                })}
                              />
                              {errors.round && (
                                <span className="text-danger small mt-1">
                                  {errors.round.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 ms-auto">
                          <div className="total-order w-100 max-widthauto m-auto mb-4">
                            <ul>
                              <li>
                                <h4>Gross</h4>
                                <h5> {totalGross?.toFixed(decimalpoints)}</h5>
                              </li>
                              <li>
                                <h4>Discount</h4>
                                <h5>{totalDiscount?.toFixed(decimalpoints)}</h5>
                              </li>
                              <li>
                                <h4>Tax Total</h4>
                                <h5> {totalTax?.toFixed(decimalpoints)}</h5>
                              </li>
                              <li>
                                <h4>Net Total</h4>
                                <h5>{totalNet?.toFixed(decimalpoints)}</h5>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-12 text-end">
                          <button
                            type="button"
                            className="btn btn-cancel add-cancel me-3"
                            onClick={() => resetmodal()}
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn-submit add-sale"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <CustomerModal
          mode="add"
          data={{}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {}}
        />
      )}
    </div>
  );
};
export default SalesInvoiceModal;
