import { DomainBase } from "./DomainBase";

export   class SectionSetting extends DomainBase {
    constructor() {
        super();
        this.Section = null;
        this.EnableKOTPrinting = false;
        this.PrintKOTInSalesBillFormat = false;
        this.PrintKOTInSalesBillPrinter = false;
        this.PrintBothSalesAndKOTPrint = false;
        this.ShowPopupToCaptureCustomerDetail = false;
        this.ShowPopupToSelectWaiter = false;
        this.ShowPopupToSelectTable = false;
        this.PrintFileName = '';
        this.NumberOfInvoicePrints = 0;
        this.BillStartNumber = 0;
        this.InvoicePrefix = '';
        this.Tax = null;
        this.PrintImageList = null;  
        this.PrintImagePath = '/PrintTemplates/Sale/'; 
        this.InvoicePrefix = ''; 
        this.TaxGuid = '';
        this.PrintTempFile = '';
        this.Department = null;
    }
}