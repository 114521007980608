import React, { useState } from "react";
import { Table } from "antd";

const DashboardTable = ({ columns, dataSource }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); 

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const paginatedData = dataSource?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <Table
      className="table datanew dataTable no-footer"
      columns={columns}
      dataSource={paginatedData}
      rowKey={(record) => record.id}
      pagination={{
        current: currentPage,
        pageSize: pageSize,
        total: dataSource?.length,
        showSizeChanger: true,
      }}
      onChange={handleTableChange}
    />
  );
};

export default DashboardTable;
