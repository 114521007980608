
import React, { useEffect, useState, CSSProperties, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductbyBranch } from '../../../../services/CoreApiServices';
import { Trash2 } from "feather-icons-react/build/IconComponents";
import toast from 'react-hot-toast';
import { branchTransfer } from '../../../../services/AdminApiServices';
import { BranchMapper } from '../../../../core/json/BranchMapper';
import { PulseLoader } from 'react-spinners';
import { fetchProduct } from '../../../../redux/userSetting';

const ProductBranchTransfer = () => {
  const dispatch = useDispatch()
  const [selectedData, setSelectedData] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [desEntity, setDesEntity] = useState([]);
  const [loadingDes, setLoadingDes] = useState(false);
  const [loadingSrc, setLoadingSrc] = useState(false);
  const [selectEntity, setSelectEntity] = useState([]);
  const [desBranch, setDesBranch] = useState('');
  const [sourceBranch, setSourceBranch] = useState('');

  const { branch } = useSelector((state) => state.branchlist);
  const { BranchByUser } = useSelector((state) => state.userBranch);
  const { userId } = useSelector((state) => state.userauth);

  const handleAddSelect = (id) => {
    if (selectedData.includes(id)) {
      setSelectedData(selectedData?.filter(item => item !== id));
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  const handleDestination = (e) => {
    setLoadingDes(true);
    setDesBranch(e.target.value);
    handleDesProduct(e.target.value);
  };

  const handleDesProduct = async (id) => {
    try {
      const response = await getProductbyBranch({ id });
      setDesEntity(response);
      setLoadingDes(false);
      setSelectedList([]);
    } catch (error) {
      console.log(error);
      setLoadingDes(false);
    }
  };

  const handleSourceProduct = async (id) => {
    setLoadingSrc(true);
    setSourceBranch(id);
    try {
      const response = await getProductbyBranch({ id });
      setLoadingSrc(false);
      setSelectEntity(response);
      setSelectedList([]);
    } catch (error) {
      console.log(error);
      setLoadingSrc(false);
    }
  };

  const handleAddSelected = () => {
    if (!desBranch) {
      toast.error("Please select a destination");
    } else {
      const newSelected = selectedData?.filter(e =>
        desEntity?.length > 0 ? !desEntity.some((obj) => obj.guid === e) : true
      );
      const newSelectedList = selectEntity?.filter(user => newSelected.includes(user.guid));
      setSelectedList(newSelectedList);
    }
  };

  const handleAddAll = () => {
    if (!desBranch) {
      toast.error("Please select a destination");
    } else {
      const newSelected = selectEntity?.filter(e =>
        desEntity?.length > 0 ? !desEntity.some((obj) => obj.guid === e.guid) : true
      );
      setSelectedList(newSelected);
    }
  };

  const handleDelete = (id) => {
    const updatedList = selectedList?.filter(e => e.guid !== id);
    setSelectedList(updatedList);
  };

  const override: CSSProperties = {
    display: "flex",
    margin: "0 auto",
    width: "100px",
  };

  const handleBranchTranfer = async () => {
    const destinationBranch = BranchByUser?.find(e => e.branch?.guid === desBranch).branch;
    const SourceBranch = BranchByUser?.find(e => e.branch?.guid === sourceBranch).branch;
    const selectedEntityGuids = selectedList?.map(e => e.guid);
    const mapperObj = new BranchMapper();
    mapperObj.EntityID = 5;
    mapperObj.EntityType = "Product";
    mapperObj.SelectedEntities = selectedEntityGuids;
    mapperObj.SourceBranch = SourceBranch;
    mapperObj.DestinationBranch = destinationBranch;
    mapperObj.updatedUser = `{${userId?.id}}`;
    mapperObj.createdUser = `{${userId?.id}}`;

    try {
      const response = await branchTransfer(mapperObj);
      if (response === true) {
        toast.success("Transfer successful");
        closeModal()
        dispatch(fetchProduct(branch))
      } else {
        toast.error("Transfer failed");
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const closeButtonRef = useRef();

  const closeModal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  return (
    <div className="modal fade" id="product-branch-trans">
      <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
        <div className="modal-content">
          <div className="page-wrapper-new p-0">
            <div className="content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>Product Branch Transfer</h4>
                </div>
                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" ref={closeButtonRef}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="col-12 align-items-center justify-content-between">
                <div className="modal-body custom-modal-body d-block">
                  <div className="row">
                    {/* Source Branch Selection */}
                    <div className='col-md-6 col-sm-12 col-lg-4'>
                      <label htmlFor="">Source Branch</label>
                      <select name="" id="" className='form-control' onChange={(e) => handleSourceProduct(e.target.value)} value={sourceBranch}>
                        <option value="" disabled>---Select Source Branch---</option>
                        {BranchByUser?.map((obj) => (
                          <option value={obj.branch.guid} key={obj.id}>{obj?.branch.name}</option>
                        ))}
                      </select>
                      <table className='table'>
                        <thead>
                          <th>Products</th>
                        </thead>
                        <div style={{ maxHeight: "200px", overflow: "scroll" }}>
                          {loadingSrc ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "50px", height: "110px" }}>
                              <PulseLoader color='#FF9F43' loading={loadingSrc} cssOverride={override} aria-label="Loading Spinner" size={15} data-testid="loader" />
                            </div>
                          ) : (
                            <tbody className='col-12'>
                              {selectEntity?.map((obj) => (
                                <tr key={obj?.guid}>
                                  <td className={selectedData.includes(obj?.guid) ? 'branch-transfer-active' : 'branch-transfer'} onClick={() => handleAddSelect(obj?.guid)}>
                                    {obj.name}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </div>
                      </table>
                    </div>

                    {/* Add Selected / Add All Buttons */}
                    <div className='col-md-6 col-sm-12 col-lg-4'>
                      <div>
                        <button className="btn btn-submit" onClick={handleAddSelected}>Add Selected</button>
                      </div>
                      <div className='mt-3'>
                        <button className="btn btn-submit" onClick={handleAddAll}>Add All</button>
                      </div>
                    </div>

                    {/* Destination Branch Selection */}
                    <div className='col-md-6 col-sm-12 col-lg-4'>
                      <label htmlFor="">Destination Branch</label>
                      <select name="" id="" className='form-control' onChange={handleDestination} value={desBranch}>
                        <option value="" disabled>---Select Destination Branch---</option>
                        {BranchByUser?.map((obj) => (
                          <option value={obj.branch.guid} key={obj.id}>{obj?.branch.name}</option>
                        ))}
                      </select>

                      <table className='table'>
                        <thead>
                          <th>Products</th>
                        </thead>
                        <div style={{ maxHeight: "200px", overflow: "scroll" }}>
                          {loadingDes ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "50px", height: "110px" }}>
                              <PulseLoader color='#FF9F43' loading={loadingDes} cssOverride={override} aria-label="Loading.." size={15} data-testid="loader" />
                            </div>
                          ) : (
                            <tbody className='col-12'>
                              {selectedList?.map((obj) => (
                                <tr key={obj?.guid} className='branch-transfer'>
                                  <td>{obj.name}</td>
                                  <td style={{ fontSize: "10px", color: "red" }} onClick={() => handleDelete(obj?.guid)}>
                                    <Trash2 style={{ fontSize: "inherit", color: "inherit" }} />
                                  </td>
                                </tr>
                              ))}
                              {desEntity?.map((obj) => (
                                <tr key={obj?.guid} className='branch-transfer'>
                                  <td>{obj.name}</td>
                                  <td></td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </div>
                      </table>
                    </div>
                  </div>
                </div>

                {/* Save Button */}
                <div className="col-lg-12">
                  <div className="modal-footer-btn mb-4">
                    {sourceBranch === desBranch && (
                      <div className="alert alert-warning mt-3 h1">
                        Source and destination branches cannot be the same.
                      </div>
                    )}
                    {(!selectedList?.length && sourceBranch !== desBranch) && (
                      <div className="alert alert-warning mt-3 h1">
                        Please select products to transfer.
                      </div>
                    )}
                    <button
                      type="submit"
                      className="btn btn-submit"
                      onClick={handleBranchTranfer}
                      disabled={sourceBranch === desBranch || !selectedList?.length}
                    >
                      Save
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductBranchTransfer;
