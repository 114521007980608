
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../../core/pagination/datatable";
import { getSalesByTransType } from "../../../services/SalesApiServices";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import dayjs from "dayjs";
import moment from "moment";

const SalesReport1Return = ({data,Reportcolumns}) => {
  const [searchText, setSearchText] = useState("");
  const [salesData, setsalesData] = useState([]);
  const [filteredSalesData, setfilteredSalesData] = useState([]);
  const { branch } = useSelector((state) => state.branchlist);

  const MySwal = withReactContent(Swal);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    handlesalesData();
  }, [branch]);


  const handlesalesData = async () => {
    try {
      const response = await getSalesByTransType(branch, 3);
      setsalesData(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Failed to fetch sales invoices", error?.response?.data?.Message || "something went wrong");
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch sales data.',
      });
    }
  };
  console.log(salesData,"daaaaata");
  
  // useEffect(() => {
  //   const from = fromDate ? dayjs(fromDate).format('YYYY-MM-DD') : null;
  //   const to = toDate ? dayjs(toDate).format('YYYY-MM-DD') : null;

  //   const filteredSalesData = salesData?.filter(item => {
  //     const customerName = item.customerName.toLowerCase();
  //     const itemDate = dayjs(item.invdate).format('YYYY-MM-DD');

  //     const isDateInRange = (!from || itemDate >= from) && (!to || itemDate <= to);

  //     return customerName.includes(searchText.toLowerCase()) && isDateInRange;
  //   });

  //   setfilteredSalesData(filteredSalesData);
  // }, [fromDate, toDate, salesData, searchText]);
 

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  
  return (
    <div>
     
      <Table columns={Reportcolumns} dataSource={data} />
    </div>
  );
};

export default SalesReport1Return;
