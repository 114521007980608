import { DomainBase } from "./DomainBase";

export class SupplierClass extends DomainBase {
    constructor() {
        super();
        this.code = "";
        this.name = "";
        this.fullName = "";
        this.ledger = "";
        this.address1 = "";
        this.address2 = "";
        this.address3 = "";
        this.mobile = "";
        this.phone = "";
        this.fax = "";
        this.email = "";
        this.webSite = "";
        this.creditDays = 0;
        this.tin = "";
        this.state = null;
        this.contactPerson1 = "";
        this.contactPerson2 = "";
        this.contactPerson3 = "";
        this.contactNumber1 = "";
        this.contactNumber2 = "";
        this.contactNumber3 = "";
        this.branch = null;
    }
}