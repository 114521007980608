import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCategoryList, getCounterList, getGroupEntryList, getSectionList } from "../services/MasterApiServices";


export const fetchAllSection = createAsyncThunk(
  "filters/fetchAllSection",
  async () => {
    const data = await getSectionList();
    console.log(data);

    if (Array.isArray(data)) {
      return data;
    } else {
      return null;
    }
  }
);

export const fetchAllGroup = createAsyncThunk(
  "filters/fetchAllGroup",
  async () => {
    const data = await getGroupEntryList();
    if (Array.isArray(data)) {
      return data;
    } else {
      return null;
    }
  }
);

export const fetchAllCategory = createAsyncThunk(
  "filters/fetchAllCategory",
  async () => {
    const data = await getCategoryList();
    if (Array.isArray(data)) {
      return data;
    } else {
      return null;
    }
  }
);
export const fetchAllCounter = createAsyncThunk(
  "filters/fetchAllCounter",
  async () => {
    const data = await getCounterList();
    if (Array.isArray(data)) {
      return data;
    } else {
      return null;
    }
  }
);

const getallMaster = createSlice({
  name: "getall",
  initialState: {
    sectiongetall:[],
    groupgetall:[],
    categorygetall:[],
    countergetall:[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSection.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllSection.fulfilled, (state, action) => {
        state.sectiongetall = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllSection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAllGroup.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllGroup.fulfilled, (state, action) => {
        state.groupgetall = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAllCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllCategory.fulfilled, (state, action) => {
        state.categorygetall = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAllCounter.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllCounter.fulfilled, (state, action) => {
        state.countergetall = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllCounter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default getallMaster.reducer;
