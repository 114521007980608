import { DomainBase } from "./DomainBase";

  export class ProductBOQ extends DomainBase {
    constructor() {
        super();
        this.Product = null;
        this.ProductBOQDetails = [];
        this.Branch = null;
        this.Totalqty = 0;
        this.AverageCost = false;
        this.LastNetCost = false;
        this.BoqDate = new Date();
    }
}