import { DomainBase } from "./DomainBase";

class User extends DomainBase {
    constructor() {
        super();
        this.Name = "";
        this.PrimaryContactNo = "";
        this.SecondaryContactNo = "";
        this.Address1 = "";
        this.Address2 = "";
        this.Address3 = "";
        this.UserRole = null; // UserRole Class
        this.UserName = "";
        this.Password = "";
        this.Email = "";
        this.FinancialYearID = 0;
        this.CompanyID = 0;
        this.ClientID = 0;
        this.CanAccessWeb = false;
        this.UserCode = "";
        this.AssignedBranch = []; // List<UserBranchDetail>
        this.Branch = null; // Branch Class
        this.IsActive = false;
    }
}

export default User;
