import React, { useEffect, useState } from 'react'
import{Link} from 'react-router-dom'
import Table from '../../../core/pagination/datatable'
import { RefreshCw } from 'feather-icons-react/build/IconComponents';
import { Pagination } from 'antd';
import { getSalesReport } from '../../../services/ReportApiService';
function WastageSummary() {
  const [wastageSummaryData, setWastageSummaryData] = useState([]);
    useEffect(() => {
      handleSaleReportList()
    }, [])
    
    const handleSaleReportList = async ()  =>{
      try {
        const response = await getSalesReport();
        setWastageSummaryData(response)
        console.log(response);
    } catch (error) {
        console.log(error);
    }
      }
   
   
    const columns = [
      {
        title: "Item Name",
        dataIndex: "itemName",
        sorter: (a, b) => a.Name?.length - b.Name?.length,
      },
      {
        title: "StockQty",
        dataIndex: "stockQty",
        sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
      },
      {
        title: "SalesQty",
        dataIndex: "salesQty",
        sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
      },
     
      {
      title: "WastageQty",
      dataIndex: "wastageQty",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
      },
      {
        title: "Sales Price",
        dataIndex: "salesPrice",
        sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
        },
        {
            title: "Total Sales Price",
            dataIndex: "totalSalesPrice",
            sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
            },
            {
                title: "Cost Price",
                dataIndex: "costPrice",
                sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
                },
                {
                  title: "Total Cost Price",
                  dataIndex: "totalcostPrice",
                  sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
                  },
     
    ];
  
   
  
  return (
    <div>
        <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Wastage summary</h4>
              </div>
            </div>
            <ul className="table-top-head">
              
             
             
              
            </ul>
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn">
               
              </div>
              <div className="page-btn import">
                <Link
                  to="#"
                  className="btn btn-added color"
                  data-bs-toggle="modal"
                  data-bs-target="#view-notes"
                >
                  <RefreshCw className="me-2" />
                  Refresh
                </Link>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
             
              {/* /Filter */}
              
              {/* /Filter */}
              <div className="table-responsive product-list">
                <Table columns={columns} dataSource={wastageSummaryData} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <Pagination />
    </div>
  )
}

export default WastageSummary