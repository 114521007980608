import { createSlice } from "@reduxjs/toolkit";
export const initialslice = createSlice({
    name: "user",
    initialState: {

        initial:false,
        pending: false,
        error: null,
        errorMessage: null,
    },
    reducers: {
        initialData: (state, action) => {
            state.initial = action.payload;
            state.pending = false;
            state.error = null;
            state.errorMessage = null;
        },

    },
});

export const {
    initialData,

} =
    initialslice.actions;
export default initialslice.reducer;
