import { DomainBase } from "./DomainBase";

export class GroupEntryClass extends DomainBase {
  constructor() {
    super();
    this.name = "";
    this.isActive = false;
    this.branch = null;
  }
}
