import React from 'react'
import expire from '../../core/img/expire.png'
import { useSelector } from 'react-redux';
import {AlertTriangle } from "feather-icons-react/build/IconComponents";

const ValidityComponent = () => {
    const {isBetweenAMCPeriod,isTodayOrAfterAMCToDate,remainingDaysToExtension} = useSelector((state) => state.amc);
  return (
    <div>
        {isBetweenAMCPeriod && isTodayOrAfterAMCToDate &&
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' ,paddingTop:"70px" , color:'#ff9f43',fontWeight:'bold', marginBottom:"-70px"}}>
      <AlertTriangle size={18}/> &nbsp; Your software subscription is about to EXPIRE in just {remainingDaysToExtension} days, Renew today !
  </div>
        }
    </div>
  )
}

export default ValidityComponent
