import axios from "axios";
import { Filters } from "../core/json/Filters";

const base_path = process.env.REACT_APP_API_URL;
export const getSectionWiseSale = async (branch,StartingDate, EndingDate) => {
    const sectionSale = new Filters()
    sectionSale.Branch = branch;   
    sectionSale.FromDate = StartingDate;
    sectionSale.ToDate = EndingDate;
    var criteriaJsonString = JSON.stringify(sectionSale);
    const response = await axios.get(
      `${base_path}/Api/Sale/Dashboard/GetSectionWiseSale?criteria=${criteriaJsonString}`,
    );
    return response.data;
  };
export const getSectionDateWiseSale = async (branch,StartingDate, EndingDate) => {
    const sectionSale = new Filters()
    sectionSale.Branch = branch;   
    sectionSale.FromDate = StartingDate;
    sectionSale.ToDate = EndingDate;
    var criteriaJsonString = JSON.stringify(sectionSale);
    const response = await axios.get(
      `${base_path}/Api/Sale/Dashboard/GetDateWiseSectionSale?criteria=${criteriaJsonString}`,
    );
    return response.data;
  };
  export const getProductWiseSale = async (branch, StartingDate, EndingDate) => {
    const sectionSale = new Filters()
    sectionSale.Branch = branch;   
    sectionSale.FromDate = StartingDate;
    sectionSale.ToDate = EndingDate;
    var criteriaJsonString = JSON.stringify(sectionSale);
    const response = await axios.get(
      `${base_path}/Api/Sale/Dashboard/GetProductWiseSale?criteria=${criteriaJsonString}`,
    );
    return response.data;
  };
  export const getWaiterWiseSale = async (branch, StartingDate, EndingDate) => {
    const sectionSale = new Filters()
    sectionSale.Branch = branch;   
    sectionSale.FromDate = StartingDate;
    sectionSale.ToDate = EndingDate;
    var criteriaJsonString = JSON.stringify(sectionSale);
    const response = await axios.get(
      `${base_path}/Api/Sale/Dashboard/GetWaiterWiseSale?criteria=${criteriaJsonString}`,
    );
    return response.data;
  };
  export const getDailySale = async (branch, StartingDate, EndingDate) => {
    const sectionSale = new Filters()
    sectionSale.Branch = branch;   
    sectionSale.FromDate = StartingDate;
    sectionSale.ToDate = EndingDate;
    var criteriaJsonString = JSON.stringify(sectionSale);
    const response = await axios.get(
      `${base_path}/Api/Sale/Dashboard/GetDailySale?criteria=${criteriaJsonString}`,
    );
    return response.data;
  };
export const GetTop5WaiterWiseSale = async (branch, StartingDate, EndingDate) => {
    const WaiterSale = new Filters()
    WaiterSale.Branch = branch;
    WaiterSale.FromDate = StartingDate;   
    WaiterSale.ToDate = EndingDate;   
    var criteriaJsonString = JSON.stringify(WaiterSale);
    const response = await axios.get(
      `${base_path}/Api/Sale/Dashboard/GetTop5WaiterWiseSale?criteria=${criteriaJsonString}`,
    );
    return response.data;
  };
export const GetLeast5WaiterWiseSale = async (branch, StartingDate, EndingDate) => {
    const WaiterSale = new Filters()
    WaiterSale.Branch = branch;
    WaiterSale.FromDate = StartingDate;
    WaiterSale.ToDate = EndingDate;   
    var criteriaJsonString = JSON.stringify(WaiterSale);
    const response = await axios.get(
      `${base_path}/Api/Sale/Dashboard/GetLeast5WaiterWiseSale?criteria=${criteriaJsonString}`,
    );
    return response.data;
  };
export const GetTop5ItemWiseSale = async (branch, StartingDate, EndingDate) => {
    const ItemSale = new Filters()
    ItemSale.Branch = branch; 
    ItemSale.FromDate = StartingDate;
    ItemSale.ToDate = EndingDate;  
    var criteriaJsonString = JSON.stringify(ItemSale);
    const response = await axios.get(
      `${base_path}/Api/Sale/Dashboard/GetTop5ItemWiseSale?criteria=${criteriaJsonString}`,
    );
    return response.data;
  };
export const GetLeast5ItemWiseSale = async (branch, StartingDate, EndingDate) => {
    const ItemSale = new Filters()
    ItemSale.Branch = branch; 
    ItemSale.FromDate = StartingDate;
    ItemSale.ToDate = EndingDate;  
    var criteriaJsonString = JSON.stringify(ItemSale);
    const response = await axios.get(
      `${base_path}/Api/Sale/Dashboard/GetLeast5ItemWiseSale?criteria=${criteriaJsonString}`,
    );
    return response.data;
  };
export const GetTop5CategoryWiseSale = async (branch, StartingDate, EndingDate) => {
    const CategorySale = new Filters()
    CategorySale.Branch = branch;  
    CategorySale.FromDate = StartingDate;
    CategorySale.ToDate = EndingDate; 
    var criteriaJsonString = JSON.stringify(CategorySale);
    const response = await axios.get(
      `${base_path}/Api/Sale/Dashboard/GetTop5CategoryWiseSale?criteria=${criteriaJsonString}`,
    );
    return response.data;
  };
export const GetLeast5CategoryWiseSale = async (branch, StartingDate, EndingDate) => {
    const CategorySale = new Filters()
    CategorySale.Branch = branch;  
    CategorySale.FromDate = StartingDate;
    CategorySale.ToDate = EndingDate; 
    var criteriaJsonString = JSON.stringify(CategorySale);
    const response = await axios.get(
      `${base_path}/Api/Sale/Dashboard/GetLeast5CategoryWiseSale?criteria=${criteriaJsonString}`,
    );
    return response.data;
  };
