import { DomainBase } from "./DomainBase";

export class CounterSectionAssignmentInfo extends DomainBase{
    constructor() {
        super();
        this.MasterID = '';
        this.SectionID = '';
        this.IsDefault = false;
        this.IsActive = false;
        this.Branch = null; //Branch Class
    }
}