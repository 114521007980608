import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { AuthenticatePassword, updatePassword } from '../../services/AdminApiServices';
import User from '../../core/json/User';

const ChangePassword = () => {
    const { register, handleSubmit, watch, getValues, reset, formState: { errors } } = useForm();
    const { userId } = useSelector((state) => state.userauth);
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [isPasswordnew, setPasswordnew] = useState(false);
    const [isPasswordconfm, setPasswordconfm] = useState(false);
    const [correctPswd, setcorrectPswd] = useState(false);

    const inputRef = useRef(null);
    const closeButtonRef = useRef();

    // Handle form submission to update password
    const handleFormSubmit = async (formData) => {
        const userobj = new User();
        userobj.guid = userId.id;
        userobj.Password = formData.newPassword;

        try {
            const response = await updatePassword(userobj);
            if (response === true) {
                toast.success("Password Changed Successfully");
                closemodal();
            } else {
                toast.error("Password change failed");
            }
        } catch (error) {
            toast.error(error?.response?.data?.Message);
        }
    };

    const newPassword = watch('newPassword');

    // Close modal and reset form
    const closemodal = () => {
        setcorrectPswd(false);
        reset();
        if (closeButtonRef.current) {
            closeButtonRef.current.click();
        }
    };

    // Authenticate old password
    const handleoldpassword = async () => {
        const userobj = new User();
        userobj.guid = userId.id;
        userobj.Password = getValues().oldPassword;

        try {
            const response = await AuthenticatePassword(userobj);
            if (response) {
                toast.success("Password Authentication successful");
                setcorrectPswd(true);
                inputRef.current?.focus(); // Set focus to the new password field
            } else {
                toast.error("Invalid old password");
            }
        } catch (error) {
            toast.error(error?.response?.data?.Message);
        }
    };

    return (
        <div className="modal fade" id="change-password">
            <div className="modal-dialog modal-dialog-centered custom-modal-two">
                <div className="modal-content">
                    <div className="page-wrapper-new p-0">
                        <div className="content">
                            <div className="modal-header border-0 custom-modal-header">
                                <div className="page-title">
                                    <h4>Change Password</h4>
                                </div>
                                <button
                                    type="button"
                                    className="close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    ref={closeButtonRef}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body custom-modal-body">
                                <form onSubmit={handleSubmit(handleFormSubmit)}>
                                    {/* Old Password */}
                                    <div className="form-login mb-3">
                                        <label className="form-label">Old Password</label>
                                        <div className="pass-group">
                                            <input
                                                type={isPasswordVisible ? "text" : "password"}
                                                className="pass-input form-control"
                                                {...register("oldPassword", { required: true })}
                                                disabled={correctPswd}
                                            />
                                            <span
                                                className={`fas toggle-password ${isPasswordVisible ? "fa-eye" : "fa-eye-slash"}`}
                                                onClick={() => setPasswordVisible(!isPasswordVisible)}
                                            ></span>
                                        </div>
                                        {errors.oldPassword?.type === "required" && (
                                            <span className="error text-danger">Old Password is required</span>
                                        )}
                                    </div>

                                    <button
                                        className='btn btn-success'
                                        type='button'
                                        onClick={handleoldpassword}
                                        disabled={correctPswd} // Disable button after success
                                    >
                                        Check
                                    </button>

                                    {/* New Password */}
                                    <div className="form-login mb-3">
                                        <label className="form-label">New Password</label>
                                        <div className="pass-group">
                                            <input
                                                ref={inputRef}
                                                type={isPasswordnew ? "text" : "password"}
                                                className="pass-input form-control"
                                                {...register("newPassword", { required: true })}
                                                disabled={!correctPswd}
                                            />
                                            <span
                                                className={`fas toggle-password ${isPasswordnew ? "fa-eye" : "fa-eye-slash"}`}
                                                onClick={() => setPasswordnew(!isPasswordnew)}
                                            ></span>
                                        </div>
                                        {errors.newPassword?.type === "required" && (
                                            <span className="error text-danger">New Password is required</span>
                                        )}
                                    </div>

                                    {/* Confirm Password */}
                                    <div className="form-login mb-3">
                                        <label className="form-label">Confirm Password</label>
                                        <div className="pass-group">
                                            <input
                                                type={isPasswordconfm ? "text" : "password"}
                                                className="pass-input form-control"
                                                {...register("confirmPassword", {
                                                    required: true,
                                                    validate: (value) =>
                                                        value === newPassword || "Passwords do not match",
                                                })}
                                                disabled={!correctPswd}
                                            />
                                            <span
                                                className={`fas toggle-password ${isPasswordconfm ? "fa-eye" : "fa-eye-slash"}`}
                                                onClick={() => setPasswordconfm(!isPasswordconfm)}
                                            ></span>
                                        </div>
                                        {errors.confirmPassword && (
                                            <span className="error text-danger">{errors.confirmPassword.message}</span>
                                        )}
                                    </div>

                                    {/* Submit Button */}
                                    <div className="col-lg-12">
                                        <div className="modal-footer-btn">
                                            <button type="submit" className="btn btn-submit" disabled={!correctPswd}>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
