import { Pagination } from 'antd';
import Table from '../../core/pagination/datatable'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { getSalesReport } from '../../services/ReportApiService';
import { RefreshCw } from 'feather-icons-react/build/IconComponents';

function ProductPriceReports() {
  const [ProductPriceReportData, setProductPriceReportData] = useState([]);
  useEffect(() => {
    handleSaleReportList()
  }, [])

  const handleSaleReportList = async () => {
    try {
      const response = await getSalesReport();
      setProductPriceReportData(response)
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }


  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.Name?.length - b.Name?.length,
    },
    {
      title: "BarCode",
      dataIndex: "barCode",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "Category",
      dataIndex: "category",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },

    {
      title: "Cost",
      dataIndex: "cost",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "BasePrice",
      dataIndex: "basePrice",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "ALBADI",
      dataIndex: "netTotal",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "DELIVEROO",
      dataIndex: "paymentType",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
    {
      title: "DELIVERY",
      dataIndex: "paymentType",
      sorter: (a, b) => a.Percentage?.length - b.Percentage?.length,
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Product Price Report</h4>
              </div>
            </div>
            <ul className="table-top-head">




            </ul>
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn">

              </div>
              <div className="page-btn import">
                <Link
                  to="#"
                  className="btn btn-added color"
                  data-bs-toggle="modal"
                  data-bs-target="#view-notes"
                >
                  <RefreshCw className="me-2" />
                  Refresh
                </Link>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">

              {/* /Filter */}

              {/* /Filter */}
              <div className="table-responsive product-list">
                <Table columns={columns} dataSource={ProductPriceReportData} />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <Pagination />
    </div>
  )
}

export default ProductPriceReports