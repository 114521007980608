import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { CategoryClass } from "../../../core/json/Category";
import { editCategory, addCategory } from "../../../services/MasterApiServices";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategory } from "../../../redux/userSetting";

const CategoryModal = ({ mode, data, handleClose, handleRefresh }) => {
  //get branch from redux
  const { branch } = useSelector((state) => state.branchlist);
  const dispatch = useDispatch();
  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  const { register, handleSubmit, setValue, reset } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  function resetmodal() {
    if (mode === "edit" && data) {
      setValue("name", data.name);
      setValue("shortName", data.shortName);
      setValue("course", Number(data.course));
    } else {
      reset();
    }
  }

  useEffect(() => {
    if (mode === "edit" && data) {
      resetmodal();
    }
  }, [data, mode, setValue]);

  // Handle form submission for adding or editing a category
  const handleFormSubmit = async (formData) => {
    const objCategory = new CategoryClass();
    objCategory.name = formData.name;
    objCategory.shortName = formData.shortName;
    objCategory.course = Number(formData.course);
    objCategory.updatedUser = `{${userId?.id}}`; // "{9b57619d-63c8-4e27-9a59-639f0c80e4a1}"; //userId
    objCategory.branch = branch;
    if (mode === "edit") {
      objCategory.guid = data.guid;
      objCategory.id = data.id;
    }

    try {
      const response =
        mode === "edit"
          ? await editCategory(objCategory)
          : await addCategory(objCategory);

      if (response.data == true) {
        toast.success("Success");
        handleRefresh(); // Refresh the list
        handleClose(); // Close the modal
        dispatch(fetchCategory(branch.guid));
      } else if (response == false) {
        toast.error("false");
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message); // ("Something went wrong");
      console.error("Error handling :", error);
      console.error("Error handling :", error?.response?.data);
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {mode === "edit" ? "Edit Category" : "Add Category"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Name"
                            {...register("name")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Short Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Short Name"
                            {...register("shortName")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Course</label>
                          <input
                            className="form-control"
                            type="number"
                            min={0}
                            placeholder="Course"
                            {...register("course")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={() => resetmodal()}
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-submit">
                          {mode === "edit" ? "Save Changes" : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryModal;
