import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  PlusCircle,
  ChevronUp,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Table from "../../core/pagination/datatable";
import {
  getGroupEntryList,
  deleteGroupEntry,
} from "../../services/MasterApiServices";
import GroupEntryModal from "../../components/master/modal/GroupEntryModal";
import { GroupEntryClass } from "../../core/json/GroupEntry";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";
import UserBranchTransfer from "../../components/adminsitration/UserBranchTransfer";
import GroupEntryBranchTransfer from "./MastersBranchTransfer/GroupEntryBranchTransfer";
import { fetchGroup } from "../../redux/userSetting";

const Group = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [groupData, setGroupData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [updateData, setUpdateData] = useState([]);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const { groupList } = useSelector((state) => state.usersetting);
  const {menuItems,specialpermission} = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  const [branchpermit, setbranchpermit] = useState();
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  // Get branch
  const { branch } = useSelector((state) => state.branchlist);

  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  // Fetch the group list when the component mounts
  useEffect(() => {
   
    dispatch(fetchGroup(branch.guid))
  }, [branch]);
  useEffect(() => {
    setpermit(menuItems?.menuPermissions?.find(e=> e.menu.name =="Group Entry"))
    setbranchpermit(specialpermission?.userSpecialPermissionList?.find(e=> e.specialPermission.name =="Group Entry Branch Transfer"))
  }, []);
  // Fetch the group list
 

  // Handle the search
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const modifiedList = Array.isArray(groupList)
    ? groupList?.filter((value) =>
        value.name?.toLowerCase().includes(searchText?.toLowerCase())
      )
    : [];

  // Handle the click event to edit a group
  const handleEditClick = async (guid) => {
    try {
      
      const SingleGroup = groupList?.find(e=> e.guid == guid)

      setUpdateData(SingleGroup); // Set the data for editing
      setModalMode("edit"); // Set the modal mode to 'edit'
      setModalOpen(true); // Open the modal
    } catch (error) {
      console.error(
        "Error fetching group by GUID:",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  // Initialize SweetAlert with ReactContent
  const MySwal = withReactContent(Swal);

  // Handle the click event to delete a group
  const handleDeleteClick = (obj) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objGroup = new GroupEntryClass();
          objGroup.id = obj.id;
          objGroup.guid = obj.guid;
          objGroup.branch = branch;
          objGroup.name = obj.name;
          objGroup.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}";
          const response = await deleteGroupEntry(objGroup);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The group has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              dispatch(fetchGroup(branch.guid)); // Refresh the list
              setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(
            "Error deleting group:",
            error?.response?.data?.Message || "something went wrong"
          );
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name?.length - b.name?.length,
    },
    ...(permit?.edit || permit?.deletion
      ? [
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
          {permit?.edit && 
            <Link
              className="me-2 p-2"
              to="#"
              onClick={() => handleEditClick(obj.guid)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>}
            {permit?.deletion && 
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => handleDeleteClick(obj)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>}
          </div>
        </div>
      ),
    },
  ]
  : [])
  ];

  // Filter the groups based on the search text
  const filteredData = groupList?.filter((entry) => {
      return Object.keys(entry).some((key) => {
        return String(entry[key])
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
    })
    .sort((a, b) => b.createdAt - a.createdAt); // Ensure new items appear on top

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Group</h4>
                <h6>Manage groups here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link onClick={() => exportPDF(groupList, "Group List")}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link onClick={() => exportExcel(groupList, "Group List")}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link onClick={() => HandlePrint(groupList, "Group List")}>
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={()=>dispatch(fetchGroup())}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            {branchpermit?.userRole.id !==0 &&
            <div className="page-btn">
                <Link
                  to="#"
                  className="btn btn-added"
                  data-bs-target="#group-branch-trans"
                  data-bs-toggle="modal"
                >
                 
                  Branch Transfer
                </Link>
              </div>}
              {permit?.add && 
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add"); // Set the modal mode to 'add'
                  setModalOpen(true); // Open the modal
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>}
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={modifiedList} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Group Modal */}
      <GroupEntryBranchTransfer />
      {modalOpen && (
        <GroupEntryModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() =>  // Refresh the list
            setSearchText("")}
        />
      )}
    </div>
  );
};

export default Group;
