import axios from "axios";
const base_path = process.env.REACT_APP_API_URL;

//get all reports
export const getSalesReport = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/SalesReport/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  return response;
};
//get all status reports
export const getSalesStatusReport = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/SalesStatusReport/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  return response;
};
export const getitemWiseSalesReport = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/ItemWiseSalesReport/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  return response;
};
export const getSalesAuditReport = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/SalesLogReport/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  console.log(response, "oooo");
  return response;
};

export const getConsolidatedReportSectionwise = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/SalesReportConsol/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  console.log(response, "response");
  return response;
};

//itemwise
export const getConsolidatedReportItemwise = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/ItemWiseConsolReport/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  console.log(response, "response");
  return response;
};
//itemwise summary sales
export const getSummReportItemwise = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/SalesItemSummaryReport/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  console.log(response, "response");
  return response;
};

//timebasedreport
export const getTimeBasedReport = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/SalesReportTimeBased/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  console.log(response, "response");
  return response;
};
