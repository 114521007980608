import React from 'react'

function EditOtherDescription({ register}) {
 

  return (
    <div>
      <div>
        <div className="modal-body custom-modal-body">
          <div className="branch-tabs col-12 align-items-center justify-content-between">

          </div>
          <form >
            <div className="row">

            <div className="col-md-6 col-sm-12">
              <div className="col-auto input-blocks">
                <label className="col-form-label">Arabic Description</label>
              </div>
              <div className="col input-blocks mb-3">
                <input type="text" id="" className="form-control"  {...register("otherDescription.arabicDescription")} />
              </div>
            </div>
            <div className=" col-md-6 col-sm-12">
              <div className="col-auto input-blocks ">
                <label className="col-form-label">HSN code</label>
              </div>
              <div className="col input-blocks mb-3">
                <input type="text" id="" className="form-control" {...register("otherDescription.hsnCode")} />
              </div>
            </div>
            </div>
            <div className="row">

            <div className="col-lg-3 col-sm-12">
            <input type="checkbox" className="" {...register("otherDescription.isRateVaryProduct")} /> Is product price variable?
            </div>
            <div className="col-lg-9 col-md-6 col-sm-12 general_decription">
            When this setting enabled, we can change the price of product while billing, a popup box will show in sales screen
                        </div>
            </div>
            <div className="row">

            <div className="col-lg-3 col-sm-12">
            <input type="checkbox" className="" {...register("otherDescription.isDailyStockItem")} />Daily Stock Item
            </div>
            <div className="col-lg-9 col-md-6 col-sm-12 general_decription">
            When this setting enabled, this product is considered as a stock product which will be shown in stock product
                        </div>
            </div>
            <div className="row">

            <div className="col-lg-3 col-sm-12">
            <input type="checkbox" className="" {...register("otherDescription.isMenuItem")} />Menu Item
            </div>
            <div className="col-lg-9 col-md-6 col-sm-12 general_decription">
            When this setting enabled, this product is considered as a Menu Item that shows in BOQ
                        </div>
            </div>
       
          </form>
        </div>

      </div>
    </div>
  )
}

export default EditOtherDescription

