import { DomainBase } from "./DomainBase";

export  class ProductBOQDetail extends DomainBase {
    constructor(){
        super();
    this.Type = null;
    this.Product = null;
    this.Unit = null;
    this.Quantity = 0;
    this.WastagePercentage = 0;
    this.ConversionFactor = 0;
    this.RoundToNextHigher = false;
    this.Cost = 0;
    this.TotalCost = 0;
    }
}