import axios from "axios";

const base_path = process.env.REACT_APP_API_URL;

export const getSettingsByBranch = async (id) => {
    const response = await axios.get(`${base_path}/Api/Setting/GetAllByBranch?branchID=${id}`);
    return response.data;
  };
export const getUserRoleList = async () => {
    const response = await axios.get(`${base_path}/Api/UserRole/GetAll`);
    return response.data;
  };
export const adduserRole = async (objRole) => {
  
    const response = await axios.post(`${base_path}/Api/UserRole/Save`,objRole);
    return response.data;
  };
export const updateuserRole = async (objRole) => {
    const response = await axios.post(`${base_path}/Api/UserRole/Update`,objRole);
    return response.data;
  };
export const getuserRolebyId = async ({id}) => {
  
    const response = await axios.get(`${base_path}/Api/UserRole/GetById?id=${id}`);
    return response.data;
  };
  export const deleteUserRole = async (id) => {
    const response = await axios.post(
      `${base_path}/Api/UserRole/Delete`,
      JSON.stringify(id)
    );
    return response;
  };
  export const deleteUser = async (userObj) => {
    const response = await axios.post(
      `${base_path}/Api/User/Delete`,
      JSON.stringify(userObj)
    );
    return response;
  };
export const savesettings = async ({setting,branch}) => { 
    const response = await axios.post(`${base_path}/Api/Setting/Save`,
      {setting,branch}
    );
    return response.data;
  };

  export const getAllEntityTypes = async () => {
    const response = await axios.get(`${base_path}/Api/Branch/GetAllEntityType`);
    return response.data;
  };
  export const branchTransfer = async (data) => { 
    const response = await axios.post(`${base_path}/Api/Branch/SaveBranchMapper`,
      data
    );
    return response.data;
  };
  export const getwebmenupermission = async (userRoleObj) => {
    var criteriaJsonString = JSON.stringify(userRoleObj);
    const response = await axios.get(`${base_path}/Api/UserRole/GetAllWebUserMenuPermission?criteria=${criteriaJsonString}`);
    return response.data;
  };
  export const savewebmenupermission = async (webObj) => {
    const response = await axios.post(`${base_path}/Api/UserRole/SaveWebUserMenuPermission`,webObj);
    return response.data;
  };
  export const getwebmenuspecialpermission = async (userRoleObj) => {
    console.log(userRoleObj);
    var criteriaJsonString = JSON.stringify(userRoleObj);
    const response = await axios.get(`${base_path}/Api/UserRole/UserWebSpecialPermissionGetAll?criteria=${criteriaJsonString}`);
    return response.data;
  };
  export const savewebspecialpermission = async (webObj) => {
    console.log(webObj);
    const response = await axios.post(`${base_path}/Api/UserRole/SaveUserWebSpecialPermission`,webObj);
    return response.data;
  };
  export const getwindowmenupermission = async (userRoleObj) => {
    var criteriaJsonString = JSON.stringify(userRoleObj);
    const response = await axios.get(`${base_path}/Api/UserRole/GetAllUserMenuPermission?criteria=${criteriaJsonString}`);
    return response.data;
  };
  export const savewindowmenupermission = async (webObj) => {
    const response = await axios.post(`${base_path}/Api/UserRole/SaveUserMenuPermission`,webObj);
    return response.data;
  };
  export const getwindowmenuspecialpermission = async (userRoleObj) => {
    console.log(userRoleObj);
    var criteriaJsonString = JSON.stringify(userRoleObj);
    const response = await axios.get(`${base_path}/Api/UserRole/UserSpecialPermissionGetAll?criteria=${criteriaJsonString}`);
    return response.data;
  };
  export const savewindowspecialpermission = async (webObj) => {
    console.log(webObj);
    const response = await axios.post(`${base_path}/Api/UserRole/SaveUserSpecialPermission`,webObj);
    return response.data;
  };
  export const addUser = async (userObj) => {
    const response = await axios.post(`${base_path}/Api/User/Save`,userObj);
    return response.data;
  };
  export const updateuser = async (userObj) => {
    const response = await axios.post(`${base_path}/Api/User/Update`,userObj);
    return response.data;
  };
  export const ProductImportSave = async (productobj) => {
    const response = await axios.post(`${base_path}/api/Product/ProductImport/Save`,productobj);
    return response.data;
  };
  export const updateCompany = async (company) => {
    console.log(company);
    
    const response = await axios.post(`${base_path}/Api/Company/Update`, company);
    return response.data;
  };
  export const deletecompany = async (id) => {
    const response = await axios.post(
      `${base_path}/Api/Company/Delete`,
      JSON.stringify(id)
    );
    return response;
  };
  export const setDefaultbranch = async (data) => {
    console.log(data,"daaaaaaaaaaaaa");
    
    const response = await axios.post(`${base_path}/Api/User/DefaultBranchSave`, data);

    
    return response.data;
    

  };
  export const updatePassword = async (data) => {

    const response = await axios.post(`${base_path}/Api/User/SavePassword`, data);
    console.log(response,"mmmmmmmm");
    
    return response.data;
    

  };
  export const AuthenticatePassword = async (data) => {
    var criteriaJsonString = JSON.stringify(data);
    const response = await axios.get(`${base_path}/Api/User/AuthenticatePassword?criteria=${criteriaJsonString}`);
    console.log(response,"mmmmmmmm");
    
    return response.data;
    

  };