

import React, { useEffect, useRef } from "react";
import {
  getwindowmenupermission,
  savewindowmenupermission,
} from "../../../services/AdminApiServices";
import { UserRole } from "../../../core/json/UserRole";
import { useForm } from "react-hook-form";
import { WebUserMenuPermission } from "../../../core/json/WebUserMenuPermission";
import toast from "react-hot-toast";
import { Checkbox } from "antd";

const WindowMenuPermission = ({ data }) => {
  const { register, setValue, getValues, watch } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  useEffect(() => {
    if (data) {
      handleGetPermissions();
    }
  }, [data]);

  const handleGetPermissions = async () => {

    try {
      const response = await getwindowmenupermission(data);

      const master = response.menuPermissions?.filter(
        (e) => e.menu.parentID == 1
      );
      const transaction = response.menuPermissions?.filter(
        (e) => e.menu.parentID == 3
      );
      const admin = response.menuPermissions?.filter(
        (e) => e.menu.parentID == 8
      );
      const reports = response.menuPermissions?.filter(
        (e) => e.menu.parentID == 13 && ![4051, 4052, 4053].includes(e.menu.id)
      );
      const quick = response.menuPermissions?.filter(
        (e) => e.menu.parentID == 4051
      );
      const summary = response.menuPermissions?.filter(
        (e) => e.menu.parentID == 4052
      );
      const detailed = response.menuPermissions?.filter(
        (e) => e.menu.parentID == 4053
      );
      const sales = response.menuPermissions?.filter(
        (e) => e.menu.parentID == 9
      );
      console.log(response);

      setValue("master", master);
      setValue("transaction", transaction);
      setValue("admin", admin);
      setValue("reports", reports);
      setValue("quick", quick);
      setValue("summary", summary);
      setValue("detailed", detailed);
      setValue("sales", sales);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  }
  const closeButtonRef = useRef();

  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };
  const handlePermission = async () => {

    const webObj = new WebUserMenuPermission()
    webObj.UserRole = data;
    webObj.MenuPermissions = [...watch().master, ...watch().transaction, ...watch().admin, ...watch().reports, ...watch().quick, ...watch().summary, ...watch().detailed,...watch().sales]

    try {
      const response = await savewindowmenupermission(webObj);
      if (response == true) {
        toast.success("success");
        closemodal()
        handleGetPermissions();
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  return (
    <div>
      <div className="modal fade" id="window-menu">
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Window Menu Permissions</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeButtonRef}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <div className="form_body">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#Masters"
                            aria-expanded="false"
                            aria-controls="Masters"
                          >
                            Masters
                          </div>
                        </h2>
                        <div
                          id="Masters"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {watch().master?.map((obj, index) => {
                              return (
                                <div className="row" key={obj?.menu?.id}>
                                  <div className="col-8">{obj?.menu?.name}</div>
                                  <div className="col-4">
                                    <Checkbox {...register(`master[${index}].view`)} checked={watch(`master[${index}].view`)} onChange={(e) => setValue(`master[${index}].view`, e.target.checked)} /><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`master[${index}].add`)} checked={watch(`master[${index}].add`)} onChange={(e) => setValue(`master[${index}].add`, e.target.checked)} /><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`master[${index}].edit`)} checked={watch(`master[${index}].edit`)} onChange={(e) => setValue(`master[${index}].edit`, e.target.checked)} /><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`master[${index}].deletion`)} checked={watch(`master[${index}].deletion`)} onChange={(e) => setValue(`master[${index}].deletion`, e.target.checked)} /><label className="m-1">Delete</label>


                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#Transactions"
                            aria-expanded="false"
                            aria-controls="Transactions"
                          >
                            Transactions
                          </div>
                        </h2>
                        <div
                          id="Transactions"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {watch().transaction?.map((obj, index) => {
                              return (
                                <div className="row" key={obj?.menu?.id}>
                                  <div className="col-8">{obj?.menu?.name}</div>
                                  <div className="col-4">
                                    <Checkbox {...register(`transaction[${index}].view`)} checked={watch(`transaction[${index}].view`)} onChange={(e) => setValue(`transaction[${index}].view`, e.target.checked)} /><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`transaction[${index}].add`)} checked={watch(`transaction[${index}].add`)} onChange={(e) => setValue(`transaction[${index}].add`, e.target.checked)} /><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`transaction[${index}].edit`)} checked={watch(`transaction[${index}].edit`)} onChange={(e) => setValue(`transaction[${index}].edit`, e.target.checked)} /><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`transaction[${index}].deletion`)} checked={watch(`transaction[${index}].deletion`)} onChange={(e) => setValue(`transaction[${index}].deletion`, e.target.checked)} /><label className="m-1">Delete</label>

                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      {/* <div className="accordion-item">
                          <h2 className="accordion-header" id="headingsalestrans">
                            <div className="accordion-button collapsed"  data-bs-toggle="collapse" data-bs-target="#salestrans" aria-expanded="false" aria-controls="salestrans">
                             Sales
                            </div>
                          </h2>
                          <div id="salestrans" className="accordion-collapse collapse" aria-labelledby="headingsalestrans" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              {watch().profit?.map((obj, index) => {
                                return (

                                  <div className="row" key={obj.menu.id}>
                                    <div className="col-8">
                                      {obj.menu.name}
                                    </div>
                                    <div className="col-4">
                                    <Checkbox {...register(`sales[${index}].view`)}checked={watch(`sales[${index}].view`)}onChange={(e) => setValue(`sales[${index}].view`, e.target.checked)}/><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`sales[${index}].add`)}checked={watch(`sales[${index}].add`)}onChange={(e) => setValue(`sales[${index}].add`, e.target.checked)}/><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`sales[${index}].edit`)}checked={watch(`sales[${index}].edit`)}onChange={(e) => setValue(`sales[${index}].edit`, e.target.checked)}/><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`sales[${index}].deletion`)}checked={watch(`sales[${index}].deletion`)}onChange={(e) => setValue(`sales[${index}].deletion`, e.target.checked)}/><label className="m-1">Delete</label>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div> */}
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="headingAdministration"
                        >
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#Administration"
                            aria-expanded="false"
                            aria-controls="Administration"
                          >
                            Administration
                          </div>
                        </h2>
                        <div
                          id="Administration"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingAdministration"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {watch().admin?.map((obj, index) => {
                              return (
                                <div className="row" key={obj?.menu?.id}>
                                  <div className="col-8">{obj?.menu?.name}</div>
                                  <div className="col-4">
                                    <Checkbox {...register(`admin[${index}].view`)} checked={watch(`admin[${index}].view`)} onChange={(e) => setValue(`admin[${index}].view`, e.target.checked)} /><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`admin[${index}].add`)} checked={watch(`admin[${index}].add`)} onChange={(e) => setValue(`admin[${index}].add`, e.target.checked)} /><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`admin[${index}].edit`)} checked={watch(`admin[${index}].edit`)} onChange={(e) => setValue(`admin[${index}].edit`, e.target.checked)} /><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`admin[${index}].deletion`)} checked={watch(`admin[${index}].deletion`)} onChange={(e) => setValue(`admin[${index}].deletion`, e.target.checked)} /><label className="m-1">Delete</label>

                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingReports">
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#Reports"
                            aria-expanded="false"
                            aria-controls="Reports"
                          >
                            Reports
                          </div>
                        </h2>
                        <div
                          id="Reports"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingReports"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {watch().reports?.map((obj, index) => {
                              return (
                                <div className="row" key={obj?.menu?.id}>
                                  <div className="col-8">{obj?.menu?.name}</div>
                                  <div className="col-4">
                                    <Checkbox {...register(`reports[${index}].view`)} checked={watch(`reports[${index}].view`)} onChange={(e) => setValue(`reports[${index}].view`, e.target.checked)} /><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`reports[${index}].add`)} checked={watch(`reports[${index}].add`)} onChange={(e) => setValue(`reports[${index}].add`, e.target.checked)} /><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`reports[${index}].edit`)} checked={watch(`reports[${index}].edit`)} onChange={(e) => setValue(`reports[${index}].edit`, e.target.checked)} /><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`reports[${index}].deletion`)} checked={watch(`reports[${index}].deletion`)} onChange={(e) => setValue(`reports[${index}].deletion`, e.target.checked)} /><label className="m-1">Delete</label>

                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingquick">
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#Quick"
                            aria-expanded="false"
                            aria-controls="Quick"
                          >
                            Quick Reports
                          </div>
                        </h2>
                        <div
                          id="Quick"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingquick"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {watch().quick?.map((obj, index) => {
                              return (
                                <div className="row" key={obj?.menu?.id}>
                                  <div className="col-8">{obj?.menu?.name}</div>
                                  <div className="col-4">
                                    <Checkbox {...register(`quick[${index}].view`)} checked={watch(`quick[${index}].view`)} onChange={(e) => setValue(`quick[${index}].view`, e.target.checked)} /><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`quick[${index}].add`)} checked={watch(`quick[${index}].add`)} onChange={(e) => setValue(`quick[${index}].add`, e.target.checked)} /><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`quick[${index}].edit`)} checked={watch(`quick[${index}].edit`)} onChange={(e) => setValue(`quick[${index}].edit`, e.target.checked)} /><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`quick[${index}].deletion`)} checked={watch(`quick[${index}].deletion`)} onChange={(e) => setValue(`quick[${index}].deletion`, e.target.checked)} /><label className="m-1">Delete</label>

                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingsummary">
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#Summary"
                            aria-expanded="false"
                            aria-controls="Summary"
                          >
                            Summary Reports
                          </div>
                        </h2>
                        <div
                          id="Summary"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingsummary"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {watch().summary?.map((obj, index) => {
                              return (
                                <div className="row" key={obj?.menu?.id}>
                                  <div className="col-8">{obj?.menu?.name}</div>
                                  <div className="col-4">
                                    <Checkbox {...register(`summary[${index}].view`)} checked={watch(`summary[${index}].view`)} onChange={(e) => setValue(`summary[${index}].view`, e.target.checked)} /><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`summary[${index}].add`)} checked={watch(`summary[${index}].add`)} onChange={(e) => setValue(`summary[${index}].add`, e.target.checked)} /><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`summary[${index}].edit`)} checked={watch(`summary[${index}].edit`)} onChange={(e) => setValue(`summary[${index}].edit`, e.target.checked)} /><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`summary[${index}].deletion`)} checked={watch(`summary[${index}].deletion`)} onChange={(e) => setValue(`summary[${index}].deletion`, e.target.checked)} /><label className="m-1">Delete</label>

                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingdetailed">
                          <div
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#detail"
                            aria-expanded="false"
                            aria-controls="detail"
                          >
                            Detailed Reports
                          </div>
                        </h2>
                        <div
                          id="detail"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingdetailed"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {watch().detailed?.map((obj, index) => {
                              return (
                                <div className="row" key={obj?.menu?.id}>
                                  <div className="col-8">{obj?.menu?.name}</div>
                                  <div className="col-4">
                                    <Checkbox {...register(`detailed[${index}].view`)} checked={watch(`detailed[${index}].view`)} onChange={(e) => setValue(`detailed[${index}].view`, e.target.checked)} /><label className="m-1">View</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`detailed[${index}].add`)} checked={watch(`detailed[${index}].add`)} onChange={(e) => setValue(`detailed[${index}].add`, e.target.checked)} /><label className="m-1">Add</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`detailed[${index}].edit`)} checked={watch(`detailed[${index}].edit`)} onChange={(e) => setValue(`detailed[${index}].edit`, e.target.checked)} /><label className="m-1">Edit</label>&nbsp; &nbsp;
                                    <Checkbox {...register(`detailed[${index}].deletion`)} checked={watch(`detailed[${index}].deletion`)} onChange={(e) => setValue(`detailed[${index}].deletion`, e.target.checked)} /><label className="m-1">Delete</label>

                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-submit"
                        onClick={handlePermission}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WindowMenuPermission;
