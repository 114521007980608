import { DomainBase } from "./DomainBase";

export class InvStockTransfer extends DomainBase {
  constructor() {
    super();
    this.date = new Date();
    this.invoiceNo = "";
    this.branch = null;
    this.remarks = "";
    this.ToBranch = null;
    this.invStockTransferDetails = [];
  }
}
